import React, { Component, ReactElement } from 'react';
import Select from '@/Modules/App/Components/Library/Select/Select';
import { ClientOptionsStyles } from '@/Modules/Client/Components/ClientOptions/ClientOptions.styles';
import { ClientTypeEnum } from '@/Modules/Client/Enum/ClientTypeEnum';
import { BilledToTypeEnum } from '@/Modules/Client/Enum/BilledToTypeEnum';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import PaymentMethodEnum from '@/Modules/Payment/Enum/PaymentMethodEnum';
import { ClientDiscountPreferenceEnum } from '@/Modules/Client/Enum/ClientDiscountPreferenceEnum';
import LineSeparator from '@/Modules/App/Components/Atom/Separator/LineSeparator';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import Textarea from '@/Modules/App/Components/Atom/Form/Textarea';
import { ClientOptionsService } from '@/Modules/Client/Components/ClientOptions/ClientOptions.service';
import {
  ClientOptionsProps,
  ClientOptionsState
} from '@/Modules/Client/Components/ClientOptions/ClientOptions.interface';
import { OptionFilterInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { ClipLoader } from 'react-spinners';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { handleChangeInput } from '@/Utils/InputFormUtils';
import LegalNoticeSendToFormComponent from '@/Modules/LegalNotice/Components/Form/LegalNoticeSendToFormComponent';

class ClientOptions extends Component<ClientOptionsProps, ClientOptionsState>
{
  private clientOptionsService = new ClientOptionsService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.clientOptionsService.setProps(this.props);
    this.clientOptionsService.subscribeState(this);

    // State
    this.state = this.clientOptionsService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.clientOptionsService.init();
  }

  componentWillUnmount(): void
  {
    this.clientOptionsService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    if (this.state.isLoading) {
      return <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ClipLoader
          color={ colors.gray400 }
          loading={ true }
          size={ 24 }
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>;
    }

    return (
      <>
        <div style={ ClientOptionsStyles.container }>
          <div style={ ClientOptionsStyles.form }>
            <Select
              listOptions={ ClientTypeEnum.options }
              label={ 'Type de client' }
              text={ 'Selectionner une option' }
              value={ this.state.formData.type }
              textHelp={ 'Choisissez le type de client afin de mieux les catégoriser dans vos rapports.' }
              onSelect={ (value) => this.clientOptionsService.handleSelectState('type', value) }
              isRequired={ false }
            />

            <Select
              listOptions={ BilledToTypeEnum.options }
              label={ 'Facturé a ...' }
              text={ 'Selectionner une option' }
              value={ this.state.formData.billedTo }
              textHelp={ 'Choix de la facturation au donneur d\'ordre ou au client' }
              onSelect={ (value) => this.clientOptionsService.handleSelectState('billedTo', value) }
              isRequired={ false }
            />

            <Select
              listOptions={ NewspaperTypeEnum.options }
              label={ 'Type de publication' }
              text={ 'Selectionner une option' }
              textHelp={ 'Attribuez une préférence du type des publications' }
              value={ this.state.formData.newspaperType }
              onSelect={ (value) => this.clientOptionsService.handleSelectState('newspaperType', value) }
              isRequired={ false }
            />

            {
              this.state.isLoading ? (
                <ClipLoader
                  color={ colors.gray400 }
                  loading={ true }
                  size={ 24 }
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <Select
                  listOptions={
                    this.state.departments.map((department: DepartmentInterface) => ({
                      label: `${ department.name } (${ department.code })`,
                      tag: department.code,
                      id: department.id
                    })) as OptionFilterInterface[]
                  }
                  label={ 'Département' }
                  text={ 'Selectionner une option' }
                  textHelp={ 'Choisissez le département de publication de préférence' }
                  value={ this.state.formData.department?.name }
                  onSelect={ (department: DepartmentInterface) =>
                    this.clientOptionsService.onSelectDepartment(department, this.props.client.company.id)
                  }
                  isSearchNeeded={ true }
                  isRequired={ false }
                />
              )
            }

            <Select
              key={ Array.isArray(this.state.newspapers) ? this.state.newspapers.length : 0 }
              listOptions={
                Array.isArray(this.state.newspapers)
                  ? this.state.newspapers.map((newspaper: NewspaperInterface) => ({
                    label: (
                      <>
                        { newspaper.name }
                        <span style={{ color: '#888', fontSize: '11px', marginLeft: '5px' }}>
                          ({ newspaper.fromSource })
                        </span>
                      </>
                    ),
                    tag: newspaper.fromSource,
                    id: newspaper.id,
                  })) as OptionFilterInterface[]
                  : []
              }
              label={ 'Journal' }
              text={ 'Selectionner une option' }
              textHelp={ 'Choisissez le journal de préférence' }
              value={ this.state.formData.newspaper?.value }
              onSelect={ (value) =>
                this.clientOptionsService.onSelectNewspaper(value)
              }
              isDisabled={ this.state.formData.newspaper.isDisabled }
              isRequired={ false }
            />

            <Select
              listOptions={ PaymentMethodEnum.options }
              label={ 'Méthode de paiement' }
              text={ 'Selectionner une option' }
              textHelp={ 'Ajuster la méthode de paiement de prédilection' }
              value={ this.state.formData.paymentMethod }
              onSelect={ (value) => this.clientOptionsService.handleSelectState('paymentMethod', value) }
              isRequired={ false }
            />

            <Select
              listOptions={ ClientDiscountPreferenceEnum.options }
              label={ 'Préférence de remise' }
              text={ 'Selectionner une option' }
              textHelp={ 'Choisissez la préférence des remises de ce client' }
              value={ this.state.formData.discountPreference }
              onSelect={ (value) => this.clientOptionsService.handleSelectState('discountPreference', value) }
              isRequired={ false }
            />

            <Input
              type={ 'text' }
              name={ 'discountGranted' }
              label={ 'Remise accordée' }
              textHelp={ 'Vous avez la possibilité d\'accorder une remise à ce client (%)' }
              allowedCharacters={ 'digits' }
              value={ this.state.formData.discountGranted }
              onChange={ (event: any) => handleChangeInput(event, this.setState.bind(this)) }
            />

            <Input
              type={ 'text' }
              name={ 'numberOfCopies' }
              label={ 'Nombre de journaux (Papier)' }
              textHelp={ 'Pour les journaux (papier), vous avez la possibilité de choisir un nombre de journaux par défault' }
              allowedCharacters={ 'digits' }
              value={ this.state.formData.numberOfCopies }
              onChange={ (event: any) => handleChangeInput(event, this.setState.bind(this)) }
            />

            <div style={ { marginTop: '20px' } }>
              <LineSeparator style={ { marginTop: '20px' } }/>
              <div style={ { ...FontStyle.h4(), marginTop: 10 } }>Note</div>
              <Textarea
                name={ 'commentary' }
                value={ this.state.formData.commentary || '' }
                onChange={ (event: any) => handleChangeInput(event, this.setState.bind(this)) }
              />
            </div>

            <div style={ { marginTop: '20px' } }>
              <LineSeparator />
              <div style={ { ...FontStyle.h4(), marginTop: 10 } }>Emails</div>
              <LegalNoticeSendToFormComponent
                selectedClient={ this.props.client }
                sendTo={
                  this.props.client.options
                  && this.props.client.options.sendTo
                    ? this.props.client.options.sendTo
                    : {}
                }
                onSelections={ (selections: any) => { this.clientOptionsService.onUpdateSendTo(selections);  } }
              />
            </div>

            <div style={ ClientOptionsStyles.actions }>
              <Button
                variant={ 'primary' }
                iconName={ 'LuRepeat' }
                label={ 'Mettre a jour' }
                onClick={ () => { this.clientOptionsService.onUpdateOptions(this.state); } }
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientOptions;
