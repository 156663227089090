import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { CreateUserFormSchema } from '@/Modules/User/Components/Form/Create/CreateUserForm.interface';

export class ApiPublicService extends BaseService
{
	publicUrl: string = `${ BaseService.API_URL }/public`;

	async checkAccountExist(email: string): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.publicUrl }/account-user/${ email }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPublicHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	/**
	 * Get Client on particular side
	 * @param companyId
	 */
	async getClient(companyId?: number): Promise<any>
	{
		try {
			const response = await fetch(
				(companyId) ? `${ this.publicUrl }/clients/${ companyId }` : `${ this.publicUrl }/clients`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPublicHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async checkAccountExistFromPasswordToken(token: string): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.publicUrl }/account-user-token/${ token }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPublicHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async askForgotPassword(forgotPasswordData: any): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.publicUrl }/ask-forgot-password`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildPublicHeaders(),
					body: JSON.stringify(forgotPasswordData),
				}
			);

			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async forgotPassword(forgotPasswordData: any, token: string): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.publicUrl }/forgot-password/${ token }`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildPublicHeaders(),
					body: JSON.stringify(forgotPasswordData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async create(formData: CreateUserFormSchema): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${ this.publicUrl }/register`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildPublicHeaders(),
					body: JSON.stringify(formData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async newspaperByDepartmentAndNewspaperType(companyId: number, departmentId: number, newspaperType: string): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.publicUrl }/newspapers/list/${ companyId }/${ departmentId }/${ newspaperType }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPublicHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async getNewspaper(newspaperId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.publicUrl }/newspapers/show/${ newspaperId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPublicHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async paymentDetail(paymentCode: string): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.publicUrl }/payment/${ paymentCode }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPublicHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async generatePaymentLink(legalNoticeId: number, companyId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.publicUrl }/payment/generate/link/${ legalNoticeId }/${ companyId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPublicHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async getLegalNotice(legalNoticeId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.publicUrl }/legal-notice/${ legalNoticeId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPublicHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async createLegalNotice(legalNoticeData: any): Promise<any>
	{
		try {
			const response: Response = await fetch(
				this.publicUrl + '/legal-notice',
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(legalNoticeData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async getLegalNoticeFile(legalNoticeId: number, type: string): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.publicUrl }/legal-notice/file/${ legalNoticeId }/${ type }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPublicHeaders()
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async getFile(legalNoticeId: number, type: string): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.publicUrl }/legal-notice/${ legalNoticeId }/${ type }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPublicHeaders(),
				}
			);

			// Response data
			return await response.blob();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async pricingLineSearch(searchLabel: string): Promise<any>
	{
		try {
			const response = await fetch(
				this.publicUrl + '/pricing/line/' + searchLabel,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPublicHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	// PARTICULAR

	/**
	 * @param isFirstCompany
	 */
	async getClientParticular(isFirstCompany: boolean = true): Promise<any>
	{
		try {
			const url: string = this.publicUrl + `/particular/client/${ isFirstCompany }`;

			const response: Response = await fetch(
				url,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async createLegalNoticeParticular(legalNoticeData: any): Promise<any>
	{
		try {
			const response: Response = await fetch(
				this.publicUrl + '/particular/legal-notices',
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(legalNoticeData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async generatePaymentCode(legalNoticeId: number, companyId: number): Promise<any>
	{
		try {
			const response = await fetch(
				this.publicUrl + '/particular/payment-code/' + legalNoticeId + '/' + companyId,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async particularDocuments(legalNoticeId: number, companyId: number): Promise<any>
	{
		try {
			const response = await fetch(
				this.publicUrl + '/particular/documents/' + legalNoticeId + '/' + companyId,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

}