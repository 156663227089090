import React, { Component, createRef, ReactElement } from 'react';
import { ClientAdminProps, ClientAdminState } from './ClientAdmin.interface';
import { ClientAdminService } from './ClientAdmin.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';
import Content from '@/Modules/App/Components/Content/Content';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import ClientDetails from '@/Modules/Client/Components/Blocks/ClientDetails/ClientDetails';
import SellsyCompany from '@/Modules/App/Components/Sellsy/SellsyCompany/SellsyCompany';
import CollaboratorsAdmin from '@/Modules/Collaborator/Admin/List/CollaboratorsAdmin';
import ClientsLogos from '@/Modules/Client/Components/ClientLogos/List/ClientsLogos';
import LegalNoticeTableList from '@/Modules/LegalNotice/Components/LegalNoticeTableList/LegalNoticeTableList';
import ClientOptions from '@/Modules/Client/Components/ClientOptions/ClientOptions';
import { ModalContextType } from '@/Provider/ModalProvider';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { ContentStyles } from '@/Modules/App/Components/Content/Content.styles';
import { formatDateUtils } from '@/Utils/DateUtils';
import ClientLegalNoticeStats from '@/Modules/Client/Components/ClientLegalNoticeStats/ClientLegalNoticeStats';
import { BilledToTypeEnum } from '@/Modules/Client/Enum/BilledToTypeEnum';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import PaymentMethodEnum from '@/Modules/Payment/Enum/PaymentMethodEnum';
import { ClientDiscountPreferenceEnum } from '@/Modules/Client/Enum/ClientDiscountPreferenceEnum';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { LegalNoticeModalStyle } from '@/Modules/LegalNotice/Style/LegalNoticeModalStyle';
import { ClientDetailsState } from '@/Modules/Client/Components/Blocks/ClientDetails/ClientDetails.interface';

class ClientAdmin extends Component<ClientAdminProps, ClientAdminState>
{
	private clientAdminService: ClientAdminService = new ClientAdminService();
	private clientOptionsModal: ModalContextType;
	private sidebarWidthRef = createRef<HTMLDivElement>();

	constructor(props: ClientAdminProps)
	{
		super(props);

		// Config service
		this.clientAdminService.setProps(this.props);
		this.clientAdminService.subscribeState(this);

		// State
		this.state = this.clientAdminService.getState();
		this.clientOptionsModal = this.props.modalContext!;

		// Bind
		this.clientOptionsModalRender = this.clientOptionsModalRender.bind(this);
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.clientAdminService.init();

		this.updateSidebarWidth(); // Calcul initial
		window.addEventListener('resize', this.updateSidebarWidth);
	}

	componentWillUnmount(): void
	{
		this.clientAdminService.unsubscribe();
		window.removeEventListener('resize', this.updateSidebarWidth);
	}

	componentDidUpdate(prevProps: ClientAdminProps, prevState: ClientAdminState): void {
		if (!this.state.css.contentWidth && this.sidebarWidthRef.current) {
			const sidebarWidth = this.sidebarWidthRef.current.offsetWidth;
			this.setState({
				css: {
					contentWidth: sidebarWidth,
				},
			});
		}
	}

	private updateSidebarWidth = (): void => {
		if (this.sidebarWidthRef.current) {
			const sidebarWidth = this.sidebarWidthRef.current.offsetWidth;
			if (this.state.css.contentWidth !== sidebarWidth) {
				this.setState({
					css: {
						contentWidth: sidebarWidth,
					},
				});
			}
		}
	};

	//</editor-fold>

	render(): ReactElement
	{
		// Extracting "from" parameter from the URL
		const searchParams = new URLSearchParams(window.location.search);
		const fromUrl = searchParams.get('from');

		const { isLoading, client } = this.state;

		if (isLoading) {
			return <LoaderFullPage />;
		}

		return (
			<>
				<HeroSection
					title={ client.name }
					icon={ null }
					button={ {
						label: 'Retour',
						variant: 'secondary',
						iconName: 'LuArrowUpLeft',
						onClick: () => {
							if (fromUrl) {
								this.props.navigation(fromUrl);
							} else {
								this.props.navigation('/admin/clients');
							}
						},
					} }
				/>

				<div style={ ContentStyles.sidebar } ref={ this.sidebarWidthRef }>
					<div style={ {
						display: 'flex',
						flexDirection: 'column',
						gap: 50,
						paddingBottom: 100,
						width: `calc(${ this.state.css.contentWidth }px - 360px)`
					} }>
						<SectionCollapsed title={ 'Information client' } highlightColor={ colors.blueRibbon50 }>
							<ClientDetails
								client={ client }
								getComponentState={ (state: ClientDetailsState) => this.clientAdminService.handleEditClient(state)}
							/>
						</SectionCollapsed>

						<SectionCollapsed title={ 'Annonces légales' } highlightColor={ colors.blueRibbon50 }>
							<LegalNoticeTableList client={ client }/>
						</SectionCollapsed>

						<SectionCollapsed title={ ' Compte Sellsy ' } highlightColor={ colors.blueRibbon50 }>
							<SellsyCompany client={ client }/>
						</SectionCollapsed>

						<SectionCollapsed title={ 'Collaborateurs' } highlightColor={ colors.blueRibbon50 }>
							<CollaboratorsAdmin client={ client }/>
						</SectionCollapsed>

						<SectionCollapsed title={ 'Logos' } highlightColor={ colors.blueRibbon50 }>
							<ClientsLogos client={ client }/>
						</SectionCollapsed>
					</div>

					<div>
						<div>
							<div style={ ContentStyles.sidebar_block_title }>Détails</div>
							<Content.Sidebar.Block
								key={ 'client.siren' }
								title={ 'Siren' }
								data={ client.siren }
								isClickable={ false }
							/>
							<Content.Sidebar.Block
								key={ 'client.siret' }
								title={ 'Siret' }
								data={ (client.siret) ? client.siret : '-' }
								isClickable={ false }
							/>
							<Content.Sidebar.Block
								key={ 'client.createdAt' }
								title={ 'Client(e) depuis' }
								data={ formatDateUtils(client.createdAt) }
								isClickable={ false }
							/>
						</div>

						<div>
							<div style={ { ...ContentStyles.sidebar_block_title, marginTop: 20 } }>
								<div style={ { marginBottom: 0, marginTop: 0 } }>Préférences</div>
								<Button
									variant={ 'inline' }
									label={ 'Modifier' }
									onClick={ this.clientOptionsModalRender }
								/>
							</div>

							<Content.Sidebar.Block
								key={ 'client.options.clientType' }
								title={ 'Type de client' }
								data={ (client?.options?.type) ? client?.options?.type.toString() : '—' }
								enumName={ 'ClientTypeEnum' }
								isClickable={ false }
							/>

							<Content.Sidebar.Block
								key={ 'client.options.billedTo' }
								title={ 'Facturé à' }
								data={ client?.options?.billedTo
									? BilledToTypeEnum.findByValue(client.options.billedTo.toString())?.label
									: '—'
								}
								isClickable={ false }
							/>

							<Content.Sidebar.Block
								key={ 'client.options.newspaperType' }
								title={ 'Type de publication' }
								data={ client?.options?.newspaperType
									? NewspaperTypeEnum.findByValue(client.options.newspaperType.toString())?.label
									: '—'
								}
								isClickable={ false }
							/>

							<Content.Sidebar.Block
								key={ 'client.options.department' }
								title={ 'Département' }
								data={ client?.options?.department
									? `${ client.options.department.name } (${ client.options.department.code })`
									: '—'
								}
								isClickable={ false }
							/>

							<Content.Sidebar.Block
								key={ 'client.options.newspaper' }
								title={ 'Journal' }
								data={ client?.options?.newspaper?.name }
								isClickable={ false }
							/>

							<Content.Sidebar.Block
								key={ 'client.options.paymentMethod' }
								title={ 'Méthode de paiement' }
								data={ client?.options?.paymentMethod
									? PaymentMethodEnum.findByValue(client.options.paymentMethod.toString())?.label
									: '—'
								}
								isClickable={ false }
							/>

							<Content.Sidebar.Block
								key={ 'client.options.discountPreference' }
								title={ 'Préférence de remise' }
								data={ client?.options?.discountPreference
									? ClientDiscountPreferenceEnum.findByValue(client.options.discountPreference.toString())?.label
									: '—'
								}
								isClickable={ false }
							/>

							<Content.Sidebar.Block
								key={ 'client.options.discountGranted' }
								title={ 'Remise accordée' }
								data={ client?.options?.discountGranted }
								isClickable={ false }
							/>

							<Content.Sidebar.Block
								key={ 'client.options.numberOfCopies' }
								title={ 'Nombre de journaux (Papier)' }
								data={ client?.options?.numberOfCopies }
								isClickable={ false }
							/>

							<Content.Sidebar.Block
								key={ 'client.options.commentary' }
								title={ 'Note' }
								data={ client?.options?.commentary }
								isClickable={ false }
							/>

							{/*
							<hr/>
							<div style={{ width: '100%' }}>
								<div style={ { ...FontStyles.h3, marginBottom: 15 } }>Envoi des Justificatifs</div>
								{ this.lineDetailsSendTo('Facture / Devis : ', client?.options?.sendTo?.invoice) }
								{ this.lineDetailsSendTo('Attestation : ', client?.options?.sendTo?.certificate) }
								{ this.lineDetailsSendTo('Justificatif : ', client?.options?.sendTo?.receipt) }
								{ this.lineDetailsSendTo('Avoir : ', client?.options?.sendTo?.credit) }
							</div>
							*/}
						</div>

						<ClientLegalNoticeStats client={ client } />
					</div>
				</div>
			</>
		);
	}

	private clientOptionsModalRender(event: any): void
	{
		event.stopPropagation();

		this.clientOptionsModal.isOpen(true);
		this.clientOptionsModal.content(
			'Options',
			<div style={ { width: '800px' } }>
				<ClientOptions
					client={ this.state.client! }
					onUpdateOptions={ async () => { this.clientAdminService.handleClientOptionsUpdate(); }}
				/>
			</div>
		);
	}

	private lineDetailsSendTo(label: string, emails: string[] | null): ReactElement
	{
		return (
			<React.Fragment key={ label }>
				<div style={ { display: 'grid', gridTemplateColumns: '90px auto', gap: 5 } }>
					<div style={ { fontSize: 12, fontWeight: 500, color: CssVariableEnum['--color-grey-400'], minWidth: 90 } }>
						{ label }
					</div>
					<div style={ LegalNoticeModalStyle.sendToLineStyle() }>
						{ emails && emails.map((email: string, index: number) =>
							{
								return <span
									style={ LegalNoticeModalStyle.sendToTagStyle() }
									key={ index }
								>
									{ email }
								</span>;
							}
						) }
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withGlobalContext(ClientAdmin);
