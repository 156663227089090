import React, { ReactElement } from 'react';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import Swal from 'sweetalert2';
import Select from '@/Modules/App/Components/Library/Select/Select';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';

interface ComponentProps
{
	isDisplayBlock: boolean,
	FormBuilderCategoryList: FormBuilderCategoryInterface[],
	selectedPrimaryCategory: FormBuilderCategoryInterface | null,
	onSelectedPrimaryCategory: (category: FormBuilderCategoryInterface) => void,
	selectedSecondaryCategory: FormBuilderCategoryInterface | null
	onSelectedSecondaryCategory: (category: FormBuilderCategoryInterface) => void,
	onReset: () => void,
}

interface ComponentState
{
	secondaryList: FormBuilderCategoryInterface[],
	isChangeAfterAllSelected: boolean,
}

export default class BlockCategoryComponent extends React.Component<ComponentProps, ComponentState>
{
	constructor(props: any)
	{
		super(props);

		// State
		this.state = {
			secondaryList: [],
			isChangeAfterAllSelected: false,
		};

		// Bind
		this.buildSecondaryCategoryList = this.buildSecondaryCategoryList.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				{ this.props.isDisplayBlock &&
          <>
            <SectionCollapsed title="Choisir le type d’annonce" isCollapsed={ false } highlightColor={ colors.gray100 }>

              <>
                <div style={ {
									display: 'grid',
									gridTemplateColumns: 'repeat(2, 1fr)',
									columnGap: '20px',
									width: '100%'
								} }>
                  <Select
                    label={ 'Type de l’annonce' }
                    listOptions={ this.props.FormBuilderCategoryList }
                    displayKey={ 'label' }
                    value={ this.props.selectedPrimaryCategory?.label }
                    onSelect={ this.onProcessSelectedPrimaryCategory.bind(this) }
                    isRequired={ true }
                  />

                  <Select
                    key={ this.props.selectedPrimaryCategory?.id.toString() }
                    label={ 'Catégorie de l\'annonce' }
                    listOptions={ this.state.secondaryList }
                    displayKey={ 'label' }
                    value={ this.props.selectedSecondaryCategory?.label }
                    onSelect={ this.props.onSelectedSecondaryCategory.bind(this) }
                    isRequired={ true }
                    isDisabled={ (!this.props.selectedPrimaryCategory) }
                  />
                </div>
              </>

            </SectionCollapsed>
          </>
				}
			</>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	componentDidMount(): void
	{
		if (this.props.selectedPrimaryCategory) {
			this.buildSecondaryCategoryList(this.props.selectedPrimaryCategory);
		}
	}

	private async onProcessSelectedPrimaryCategory(category: FormBuilderCategoryInterface): Promise<void>
	{
		if (this.props.selectedPrimaryCategory && this.props.selectedSecondaryCategory) {
			const result = await Swal.fire({
				title: 'Voulez-vous changer de catégorie ?',
				text: 'Si vous changez de catégorie, le formulaire qui suit va se réinitialiser',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Oui, changez-la !',
				cancelButtonText: 'Annuler'
			});

			if (!result.isConfirmed) {
				return;
			} else {
				this.props.onReset();
			}
		}

		// Props
		this.props.onSelectedPrimaryCategory(category);

		// Build secondaryList
		this.buildSecondaryCategoryList(category);
	}

	private buildSecondaryCategoryList(category: FormBuilderCategoryInterface): void
	{
		// Build secondaryList
		const secondaryList: FormBuilderCategoryInterface[] = [];
		category.childCategories.map((childCategory: FormBuilderCategoryInterface) =>
		{
			return secondaryList.push(childCategory);
		});

		this.setState({ secondaryList });
	}

	//</editor-fold>
}