import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { TableControlsProps, TableControlsState } from './TableControls.interface';
import FilterDateEnum from '@/Enum/FilterDateEnum';
import { getDisplayValue } from '@/Utils/FindEnumLabelMapUtils';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const initState: TableControlsState = {
	selectedFilters: {},
	search: '',
	hasSelectedFilters: false,
};

export class TableControlsService extends BaseComponentService<TableControlsProps, TableControlsState>
{
	private debounceTimeoutId: NodeJS.Timeout | null = null;

	constructor()
	{
		super({} as TableControlsProps, initState);

		// Bind
		this.onClearFilters = this.onClearFilters.bind(this);
	}

	/**
	 * Initialize the service by loading filters from localStorage.
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		try {
			// Charger les filtres depuis le localStorage
			const savedFilters = localStorage.getItem(this.getLocalStorageKey());
			const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {};

			const hasSelectedFilters =
				Boolean((parsedFilters.filters && parsedFilters.filters.length > 0) ||
				(parsedFilters.search && parsedFilters.search.trim() !== '') ||
				(parsedFilters.orderBy && parsedFilters.sort));

			this.setState({
				hasSelectedFilters
			});

			const transformedFilters = this.transformFilters(parsedFilters.filters || []);
			const updatedSearch = parsedFilters.search || '';

			// Mettre à jour l'état avec les filtres et la recherche
			this.setState({
				selectedFilters: transformedFilters,
				search: updatedSearch,
			});
		} catch (error) {
			console.error('Failed to load filters from localStorage:', error);
		}
	}

	/**
	 * Handles search term change with debounce.
	 * @param term - The search term.
	 */
	async onChangeTerm(term: string): Promise<void>
	{
		this.setState((prevState) => ({
			...prevState,
			search: term,
			hasSelectedFilters: true,
		}));

		if (this.debounceTimeoutId) {
			clearTimeout(this.debounceTimeoutId);
		}

		this.debounceTimeoutId = setTimeout(() =>
		{
			const updatedFilters = {
				...this.state.selectedFilters,
			};
			this.updateFilters(updatedFilters);

			this.props.onChangeTerm(term);
		}, 300);
	}

	/**
	 * Clears the search field.
	 */
	clearSearch(): void
	{
		const updatedFilters = {
			...this.state.selectedFilters,
			search: null,
		};
		this.updateFilters(updatedFilters);
		this.props.onClearFilters();
	}

	/**
	 * Filters using a search term.
	 * @param filterName - The name of the filter.
	 * @param term - The search term.
	 */
	async filterSearch(filterName: string, term: string): Promise<void>
	{
		const updatedFilters = {
			...this.state.selectedFilters,
			[filterName]: { value: term, displayValue: term },
		};
		this.updateFilters(updatedFilters);
		this.props.onApplyFilter(filterName, term);
	}

	/**
	 * Handles selection of a filter option.
	 * @param filterName - The name of the filter.
	 * @param item - The selected item.
	 */
	onSelectFilterOption(filterName: string, item: any): void
	{
		const updatedFilters = {
			...this.state.selectedFilters,
			[filterName]: { value: item.tag, displayValue: item.label },
		};
		this.updateFilters(updatedFilters);
		this.props.onApplyFilter(filterName, item.tag);
	}

	/**
	 * Applies a date filter.
	 * @param filterName - The name of the filter.
	 */
	onApplyDateFilter(filterName: string): void
	{
		const filter = this.state.selectedFilters[filterName];
		if (!filter || !filter.dates) return;

		const { dates } = filter;
		const dateFilter = {
			type: filter.value,
			...(filter.value === FilterDateEnum.BETWEEN.value
				? { start: dates.startDate, end: dates.endDate }
				: { value: dates.singleDate }),
		};

		const updatedFilters = {
			...this.state.selectedFilters,
			[filterName]: {
				...filter,
				appliedFilter: dateFilter,
			},
		};
		this.updateFilters(updatedFilters);

		this.props.onApplyFilter(filterName, dateFilter);
	}

	/**
	 * Selects a date type for filtering.
	 * @param filterName - The name of the filter.
	 * @param item - The selected item.
	 */
	onSelectedFilterDate(filterName: string, item: any): void
	{
		const updatedFilters = {
			...this.state.selectedFilters,
			[filterName]: {
				value: item.value,
				displayValue: item.label,
				dates: {},
			},
		};
		this.updateFilters(updatedFilters);
	}

	/**
	 * Handles input for a date filter.
	 * @param filterName - The name of the filter.
	 * @param dateKey - The key for the date field.
	 * @param dateValue - The value of the date.
	 */
	onFilterDateInput(filterName: string, dateKey: string, dateValue: string): void
	{
		const filter = this.state.selectedFilters[filterName];
		if (!filter) return;

		const updatedFilter = {
			...filter,
			dates: {
				...filter.dates,
				[dateKey]: dateValue,
			},
		};

		const updatedFilters = {
			...this.state.selectedFilters,
			[filterName]: updatedFilter,
		};
		this.updateFilters(updatedFilters);
	}

	/**
	 * Clears all filters.
	 */
	onClearFilters(): void
	{
		this.setState({
			hasSelectedFilters: false,
		}, () => {
			this.updateFilters({});
			this.props.onClearFilters();
		});
	}

	/**
	 * Checks if the date inputs are valid.
	 * @param filter - The filter to check.
	 */
	isDateInputDateNotEmpty(filter: any): boolean
	{
		const selectedFilter = this.state.selectedFilters[filter.tag];
		return (
			!selectedFilter?.dates ||
			(selectedFilter?.value === FilterDateEnum.BETWEEN.value &&
				(!selectedFilter.dates?.startDate || !selectedFilter.dates?.endDate)) ||
			(selectedFilter?.value !== FilterDateEnum.BETWEEN.value &&
				!selectedFilter.dates?.singleDate)
		);
	}

	handleStorageChange = (): void => {
		const savedFilters = localStorage.getItem(this.getLocalStorageKey());
		const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {};

		const hasSelectedFilters = Object.keys(this.state.selectedFilters).length > 0 ||
			(parsedFilters.orderBy || parsedFilters.sort);

		this.setState({
			hasSelectedFilters,
			selectedFilters: this.transformFilters(parsedFilters.filters || []),
			search: parsedFilters.search || '',
		});
	};

	/**
	 * Update selected filters and sync with localStorage.
	 * @param updatedFilters Updated filters object.
	 */
	private updateFilters(updatedFilters: Record<string, any>): void
	{
		this.setState({ selectedFilters: updatedFilters, hasSelectedFilters: true },
			() => {
			localStorage.setItem(this.getLocalStorageKey(), JSON.stringify(updatedFilters));
		});
	}

	/**
	 * Generate a unique localStorage key for the current tag.
	 * @returns {string} The key for localStorage.
	 */
	private getLocalStorageKey(): string
	{
		return `filters_${ this.props.tag }`;
	}

	/**
	 * Transforme un tableau de filtres en un objet `selectedFilters`.
	 * @param filters - Tableau des filtres provenant du localStorage.
	 * @returns {Record<string, { value: string; displayValue: string } | null>}
	 */
	private transformFilters(filters: Array<Record<string, any>>): Record<
		string,
		{
			value: string;
			displayValue: string;
		} | null
	>
	{
		const transformed: Record<string, { value: string; displayValue: string } | null> = {};

		filters.forEach((filter) =>
		{
			const key: string = Object.keys(filter)[0];
			const value = filter[key];
			let displayValue: string = '';

			// Handle State
			if (key === 'publishDate' && value.type === 'ON' && value.value) {
				displayValue = format(new Date(value.value), 'd MMMM yyyy', { locale: fr });
			} else {
				displayValue = getDisplayValue(key, value);
			}

			transformed[key] = {
				value: value,
				displayValue: displayValue,
			};
		});

		return transformed;
	}

}
