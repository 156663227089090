import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { PaginationProps, PaginationState } from './Pagination.interface';

const initState: PaginationState = {
	elementPerPage: 10,
	isLoading: false,
};

export class PaginationService extends BaseComponentService<PaginationProps, PaginationState>
{
	constructor()
	{
		super({} as PaginationProps, initState);

		// Bind
		this.selectedElementPerPage = this.selectedElementPerPage.bind(this);
	}

	/**
	 * Initialize the service by restoring `itemsPerPage` from the shared filters in localStorage.
	 */
	async init(): Promise<void> {
		try {
			// Charger les filtres globaux depuis localStorage
			const savedFilters = localStorage.getItem(this.getLocalStorageKey());
			const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {};

			// Récupérer `itemsPerPage` depuis les filtres globaux
			const elementsPerPage = parsedFilters.itemsPerPage || initState.elementPerPage;

			// Mettre à jour l'état local
			this.setState({ elementPerPage: elementsPerPage }, () => {
				// Informer le parent du nombre d'éléments par page restauré
				this.props.onItemsPerPageChange?.(elementsPerPage);
			});
		} catch (error) {
			console.error('Error restoring elementsPerPage from localStorage:', error);
			this.setState({ elementPerPage: initState.elementPerPage });
		}
	}

	/**
	 * Generates the list of options for pagination, specifying the number of elements per page.
	 * @returns {Array<{ elementPerPage: number; label: string }>} List of pagination options.
	 */
	getPaginationOptions(): { elementPerPage: number; label: string }[] {
		return [
			{ elementPerPage: 10, label: '10 éléments' },
			{ elementPerPage: 25, label: '25 éléments' },
			{ elementPerPage: 50, label: '50 éléments' },
			{ elementPerPage: 100, label: '100 éléments' },
			{ elementPerPage: 250, label: '250 éléments' },
		];
	}

	/**
	 * Handles the selected number of elements per page.
	 * Updates the state, syncs with the shared filters in localStorage,
	 * and notifies the parent component.
	 * @param numberOfElement - Object containing the selected number of elements per page.
	 */
	selectedElementPerPage(numberOfElement: { elementPerPage: number }): void {
		if (!this.isValidElementsPerPage(numberOfElement.elementPerPage)) {
			console.warn('Invalid number of elements per page:', numberOfElement.elementPerPage);
			return;
		}

		this.setState(
			{ elementPerPage: numberOfElement.elementPerPage },
			() => {
				// Charger les filtres globaux depuis localStorage
				const savedFilters = localStorage.getItem(this.getLocalStorageKey());
				const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {};

				// Mettre à jour `itemsPerPage` dans les filtres globaux
				const updatedFilters = {
					...parsedFilters,
					itemsPerPage: numberOfElement.elementPerPage,
				};

				// Sauvegarder les filtres globaux dans localStorage
				localStorage.setItem(this.getLocalStorageKey(), JSON.stringify(updatedFilters));

				// Informer le parent
				this.props.onItemsPerPageChange?.(numberOfElement.elementPerPage);
			}
		);
	}

	/**
	 * Generate a unique localStorage key for the current tag.
	 * This key is shared with other services (e.g., filters).
	 * @returns {string} The key for localStorage.
	 */
	private getLocalStorageKey(): string {
		return `filters_${this.props.tag}`;
	}

	/**
	 * Validates the number of elements per page.
	 * @param value - The number to validate.
	 * @returns {boolean} True if the value is valid, false otherwise.
	 */
	private isValidElementsPerPage(value: number): boolean {
		return Number.isInteger(value) && value > 0;
	}
}
