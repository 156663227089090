import React, { CSSProperties, ReactElement } from 'react';
import { EnumMap } from '@/Enum/EnumMap.interface';

interface TagProps
{
  children?: React.ReactNode;
  value: string;
  enumName: string;
  style?: CSSProperties;
}

export default class Tag extends React.Component<TagProps, any>
{
  render(): ReactElement
  {
    const tagInfo = this.generateTagFromEnum(this.props.enumName, this.props.value);
    if (!tagInfo) {
      return <div>__</div>;
    }

    const { label, backgroundColor, color, icon, border } = tagInfo;

    return (
      <div
        style={ {
          height: 'auto',
          maxHeight: 20,
          fontSize: 12,
          padding: '3px 6px',
          backgroundColor,
          border,
          color,
          borderRadius: 5,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...this.props.style,
        } }
      >
        { icon && (
          <div style={ { display: 'flex', alignItems: 'center', marginRight: '5px' } }>
            { icon }
          </div>
        ) }
        <span>{ label }</span>
        { this.props.children && <span>{ this.props.children }</span> }
      </div>
    );
  }

  private generateTagFromEnum(enumName: string, value: string)
  {
    const enumClass = EnumMap[enumName];
    if (!enumClass) return null;
    const enumInstance = enumClass.findByValue(value);
    if (!enumInstance) {
      return null;
    }

    return {
      label: enumInstance.label,
      enum: enumInstance,
      backgroundColor: enumInstance.css.backgroundColor,
      color: enumInstance.css.color,
      border: enumInstance.css.border,
      icon: enumInstance.css.icon,
    };
  }
}
