import React, { Component, ReactElement } from 'react';
import { PaginationProps, PaginationState } from './Pagination.interface';
import { PaginationService } from './Pagination.service';
import { PaginationStyles } from '@/Modules/App/Components/Library/Pagination/Pagination.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import Select from '@/Modules/App/Components/Library/Select/Select';
import Subtitle from '@/Modules/App/Components/Atom/Title/Subtitle';

class Pagination extends Component<PaginationProps, PaginationState>
{
	private paginationService = new PaginationService();

	constructor(props: PaginationProps)
	{
		super(props);

		// Config service
		this.paginationService.setProps(this.props);
		this.paginationService.subscribeState(this);

		// State
		this.state = this.paginationService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.paginationService.init();
	}

	componentWillUnmount(): void
	{
		this.paginationService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { elementPerPage } = this.state;
		const { currentPage, list, onPageChange, blockElementsPerPage } = this.props;
		const totalPages = list?.totalPages || 1;

		return (
			<div style={ PaginationStyles.container }>
				{ (!blockElementsPerPage && Boolean(this.props.onItemsPerPageChange)) && (
					<div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
						<Select
							styles={{ gap: 0 }}
							listOptions={ this.paginationService.getPaginationOptions() }
							text={ elementPerPage ? `${ elementPerPage } éléments` : 'Elements par page' }
							onSelect={ (value) =>
							{
								this.paginationService.selectedElementPerPage(value);
							} }
							isRequired={ false }
						/>

						<Subtitle>Total : { this.props.list.totalCount }</Subtitle>
					</div>
				)}
				<div style={ PaginationStyles.pageButtons }>{ this.generatePaginationButtons() }</div>
				<div style={ PaginationStyles.buttons }>
					<Button
						variant={ 'small' }
						label={ 'Précédent' }
						onClick={ () => onPageChange(currentPage - 1) }
						disabled={ currentPage === 1 }
					/>
					<Button
						variant={ 'small' }
						label={ 'Suivant' }
						onClick={ () => onPageChange(currentPage + 1) }
						disabled={ currentPage >= totalPages }
					/>
				</div>
			</div>
		);
	}

	private generatePaginationButtons(): ReactElement[]
	{
		const { currentPage, list, onPageChange } = this.props;
		const totalPages = list?.totalPages || 1;
		const paginationButtons: ReactElement[] = [];

		if (totalPages <= 5) {
			for (let i = 1; i <= totalPages; i++) {
				paginationButtons.push(
					<Button
						key={ i }
						variant={ i === currentPage ? 'smallDark' : 'small' }
						label={ i.toString() }
						onClick={ () => onPageChange(i) }
					/>
				);
			}
		} else {
			paginationButtons.push(
				<Button
					key={ 1 }
					variant={ 1 === currentPage ? 'smallDark' : 'small' }
					label={ '1' }
					onClick={ () => onPageChange(1) }
				/>
			);

			if (currentPage > 4) {
				paginationButtons.push(
					<span key="start-ellipsis" style={ { color: 'rgb(152, 162, 179)' } }>
            ...
          </span>
				);
			}

			for (let i = Math.max(2, currentPage - 2); i <= Math.min(totalPages - 1, currentPage + 2); i++) {
				paginationButtons.push(
					<Button
						key={ i }
						variant={ i === currentPage ? 'smallDark' : 'small' }
						label={ i.toString() }
						onClick={ () => onPageChange(i) }
					/>
				);
			}

			if (currentPage < totalPages - 3) {
				paginationButtons.push(
					<span key="end-ellipsis" style={ { color: 'rgb(152, 162, 179)' } }>
            ...
          </span>
				);
			}

			paginationButtons.push(
				<Button
					key={ totalPages }
					variant={ totalPages === currentPage ? 'smallDark' : 'small' }
					label={ totalPages.toString() }
					onClick={ () => onPageChange(totalPages) }
				/>
			);
		}

		return paginationButtons;
	}
}

export default Pagination;
