import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';

export class UserService {
  static isRoleOwner(user: UserInterface, clientId: number): boolean
  {
    const collaborator = user.collaborators.find(collaborator =>
      collaborator.client.id === clientId
    );

    if (!collaborator) {
      return false;
    }

    return (collaborator.role.toString() === CollaboratorRoleEnum.ROLE_OWNER.value);
  }

  static getCollaboratorFromUser(user: UserInterface, clientId: number): CollaboratorInterface|null
  {
    const collaborator = user.collaborators.find(collaborator =>
      collaborator.client.id === clientId
    );

    if (!collaborator) {
      return null;
    }

    return collaborator;
  }


}