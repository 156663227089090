import React, { Component, ReactElement } from 'react';
import {
  ClientLegalNoticeStatsFormProps, ClientLegalNoticeStatsFormState
} from '@/Modules/Client/Components/ClientLegalNoticeStats/Form/ClientLegalNoticeStatsForm.interface';
import { handleChangeInput } from '@/Utils/InputFormUtils';
import Input from '@/Modules/App/Components/Library/Input/Input';
import {
  ClientLegalNoticeStatsFormService
} from '@/Modules/Client/Components/ClientLegalNoticeStats/Form/ClientLegalNoticeStatsForm.service';
import {
  ClientLegalNoticeStatsFormStyles
} from '@/Modules/Client/Components/ClientLegalNoticeStats/Form/ClientLegalNoticeStatsForm.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';

class ClientLegalNoticeStatsForm extends Component<ClientLegalNoticeStatsFormProps, ClientLegalNoticeStatsFormState>
{
  private clientLegalNoticeStatsFormService = new ClientLegalNoticeStatsFormService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.clientLegalNoticeStatsFormService.setProps(this.props);
    this.clientLegalNoticeStatsFormService.subscribeState(this);

    // State
    this.state = this.clientLegalNoticeStatsFormService.getState();
  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.clientLegalNoticeStatsFormService.init();
  }

  componentWillUnmount(): void
  {
    this.clientLegalNoticeStatsFormService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    return (
      <>
        <div style={ ClientLegalNoticeStatsFormStyles.container }>

          <div style={{ width: '100%' }}>
            <Input
              width={ '200px' }
              type={ 'number' }
              name={ 'year' }
              label={ 'L\'année' }
              textHelp={ 'Merci de renseigner le l\'année' }
              allowedCharacters={ 'digits' }
              value={ this.state.formData.year || '' }
              onChange={ (event: any) => handleChangeInput(event, this.setState.bind(this)) }
            />
          </div>

          <div style={{ display: 'flex', gap: 20, width: '100%' }}>
            <div style={{ width: '50%' }}>
              <Input
                type={ 'number' }
                name={ 'intern' }
                label={ 'Interne' }
                textHelp={ 'Merci de renseigner le nombre d\'annonces légales interne' }
                allowedCharacters={ 'digits' }
                value={ this.state.formData.intern || '' }
                onChange={ (event: any) => handleChangeInput(event, this.setState.bind(this)) }
              />
            </div>

            <div style={{ width: '50%' }}>
              <Input
                type={ 'number' }
                name={ 'extern' }
                label={ 'Externe' }
                textHelp={ 'Merci de renseigner le nombre d\'annonces légales externe' }
                allowedCharacters={ 'digits' }
                value={ this.state.formData.extern || '' }
                onChange={ (event: any) => handleChangeInput(event, this.setState.bind(this)) }
              />
            </div>
          </div>

          <div style={ { display: 'flex', justifyContent: 'end', margin: 0 } }>
            <Button
              variant={ 'primary' }
              label={ (!this.props.clientLegalNoticeStat) ? 'Enregistrer' : 'Modifier' }
              onClick={ () => {
                (!this.props.clientLegalNoticeStat)
                  ? this.clientLegalNoticeStatsFormService.onCreate(this.state)
                  : this.clientLegalNoticeStatsFormService.onEdit(this.props.clientLegalNoticeStat, this.state)
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ClientLegalNoticeStatsForm;