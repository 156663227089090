import React, { ReactElement } from 'react';
import { stringAvatar } from '@/Utils/AvatarUtils';
import Skeleton from 'react-loading-skeleton';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { AvatarProps, AvatarState } from '@/Modules/App/Components/Avatar/Avatar.interface';
import { AvatarStyles } from '@/Modules/App/Components/Avatar/Avatar.styles';
import ToggableMenu from '@/Modules/App/Components/Menu/ToggableMenu/TogglableMenu';
import { TogglableMenuInterface } from '@/Modules/App/Components/Menu/ToggableMenu/TogglableMenu.interface';

class Avatar extends React.Component <AvatarProps, AvatarState>
{
	render(): ReactElement
	{
		//<editor-fold desc="Const " defaultstate="collapsed">

		const { authContext } = this.props;
		if (!authContext || !authContext.user) {
			return <LoaderComponent/>;
		}
		const isUserLoading: boolean = !authContext.user;
		const avatarContent: string = stringAvatar(authContext?.user.firstname, authContext?.user.lastname).avatar;

		//</editor-fold>

		return (
			<>
				{ isUserLoading ? (
					<Skeleton width={ 30 } height={ 30 } circle={ true }/>
				) : (
					<ToggableMenu menuList={ this.menuList() }>
						<div style={ AvatarStyles.container(authContext) }>
							{ avatarContent }
						</div>
					</ToggableMenu>
				) }
			</>
		);
	}

	private menuList(): TogglableMenuInterface[]
	{
		return [
			{
				label: 'Déconnexion',
				onClick: () =>
				{
					this.props.authContext?.logout();
					this.props.navigation('/auth');
				}
			}
		];
	}
}

export default withGlobalContext(Avatar);