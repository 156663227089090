import React, { ReactElement } from 'react';
import '../../../Assets/sass/Components/_dashboard-developer.scss';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';
import Button from '@/Modules/App/Components/Library/Button/Button.';

export default class DashboardDeveloperView extends React.Component
  <any, { responseCallTemp: any|null }>
{
  apiLegalNoticeFileService: ApiAdminLegalNoticeFileService;

  constructor(props: any)
  {
    super(props);

    this.state = {
      responseCallTemp: null
    };

    // Service
    this.apiLegalNoticeFileService = new ApiAdminLegalNoticeFileService();

    // Document title
    document.title = 'Publisur - DEVELOPER';
  }

  render(): ReactElement
  {
    return (
      <>
        <div className="dashboard-container">
          <h1 className="h1">
            <div className="pacman">
              <div className="pacman-body">
                <div className="pacman-mouth"></div>
              </div>
            </div>
            <div className="dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            👾 WELCOME DEVELOPER 😍
          </h1>
        </div>

        <hr/>
      </>
    );
  }
}
