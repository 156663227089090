import React, { Component, ReactElement } from 'react';
import { BlockFreeWritingProps, BlockFreeWritingState } from './BlockFreeWriting.interface';
import { BlockFreeWritingService } from './BlockFreeWriting.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { Wysiwyg } from '@/Modules/App/Components/Atom/Form/Wysiwyg';

class BlockFreeWriting extends Component<BlockFreeWritingProps, BlockFreeWritingState>
{
	private blockFreeWritingService: BlockFreeWritingService = new BlockFreeWritingService();

	constructor(props: BlockFreeWritingProps)
	{
		super(props);

		// Config service
		this.blockFreeWritingService.setProps(this.props);
		this.blockFreeWritingService.subscribeState(this);

		// State
		this.state = this.blockFreeWritingService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.blockFreeWritingService.init();
	}

	componentWillUnmount(): void
	{
		this.blockFreeWritingService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				<SectionCollapsed title={'Rédaction Libre'} isCollapsed={ false } highlightColor={ colors.gray100 }>
					<Wysiwyg
						initialContent={ '' }
						onContentChange={ (content) => this.blockFreeWritingService.onChangeLegalNoticeContent(content, this.state) }
					/>
				</SectionCollapsed>
			</>
		);
	}
}

export default withGlobalContext(BlockFreeWriting);