import { CSSProperties } from 'react';
import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';

export const ErrorBoundaryStyles = {

  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f8f9fa",
  } as CSSProperties,
  card: {
    textAlign: "center" as const,
    padding: "20px",
    border: "1px solid #dee2e6",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  } as CSSProperties,
  title: {
    fontSize: "2rem",
    color: "#dc3545",
    marginBottom: "10px",
  } as CSSProperties,
  message: {
    fontSize: "1.2rem",
    color: "#6c757d",
    marginBottom: "20px",
  } as CSSProperties,
  errorDetails: {
    fontSize: "0.9rem",
    color: "#6c757d",
    marginBottom: "20px",
  } as CSSProperties,
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#ffffff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  } as CSSProperties,
  counter: {
    marginTop: "20px",
    fontSize: "1rem",
    color: "#6c757d",
  } as CSSProperties
} as const;