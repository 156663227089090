import React, { ReactElement } from 'react';
import { CreateLegalNoticeStyle } from '@/Modules/LegalNotice/Style/CreateLegalNoticeStyle';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import HeaderOptionContentComponent from '@/Modules/LegalNotice/Components/Form/Content/HeaderOptionContentComponent';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { Wysiwyg } from '@/Modules/App/Components/Atom/Form/Wysiwyg';
import Input from '@/Modules/App/Components/Library/Input/Input';
import LegalNoticeRender from '@/Modules/LegalNotice/Components/Render/LegalNoticeRender';
import OffCanvasComponent from '@/Modules/App/Components/Atom/OffCanvas/OffCanvasComponent';
import { ViewLegalNoticeState } from '@/Modules/LegalNotice/Admin/Create/CreateLegalNoticeView';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { ModalContextType } from '@/Provider/ModalProvider';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import LegalNoticePriceFormComponent from '@/Modules/LegalNotice/Components/Form/LegalNoticePriceFormComponent';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';

interface ComponentProps
{
	modalContext: ModalContextType,
	flashMessageContext: FlashMessageContextType,
	selectedClient: ClientInterface | null;
	legalNotice: ViewLegalNoticeState | null;
	consumer: ConsumerDataInterface | null;
	isConsumerFormFilled: boolean,
	isFormComplete: boolean,
	isHeader: boolean;
	isLogo: boolean;
	onSelectedLogo: (event: any) => void,
	onCheckIsHeader: (event: React.ChangeEvent<HTMLInputElement>) => void,
	onCheckIsLogo: (isChecked: boolean) => void,
	title: string | null,
	content: string,
	signature: string,
	onCreate: (event: any) => void,
	onChangeLegalNoticeTitle: (event: string) => void,
	onChangeLegalNoticeContent: (event: string) => void,
	onChangeLegalNoticeSignature: (event: string) => void,
	onHeaderCharacterCount: (count: number) => void,
	legalNoticePriceInit: {
		selectedDepartment: DepartmentInterface | null,
		selectedNewspaper: NewspaperInterface | null,
		selectedCategory: FormBuilderCategoryInterface | null,
		validateLegalNotice: (event: any) => void,
		isHeaderCharacterCount: number | undefined,

	}
}

interface ComponentState
{
	isRenderOpen: boolean
}

export default class BlockLegalNoticeContent extends React.Component<ComponentProps, ComponentState>
{
	constructor(props: any)
	{
		super(props);

		// State
		this.state = {
			isRenderOpen: false,
		};
	}

	render(): ReactElement
	{
		const { legalNoticePriceInit, legalNotice } = this.props;

		return (
			<>
				<div style={ { ...CreateLegalNoticeStyle.displayFlexBetween(), gap: 10 } }>
					<div style={ { flexGrow: 1 } }>
						{ legalNoticePriceInit.selectedDepartment && legalNoticePriceInit.selectedCategory && legalNoticePriceInit.selectedNewspaper &&
              <LegalNoticePriceFormComponent
                key={ `${ legalNotice?.content! }-${ legalNotice?.numberOfCopies! }-${ legalNoticePriceInit.selectedCategory?.id }-${ legalNoticePriceInit.selectedNewspaper?.name }-${legalNotice?.option?.publishType!.toString()}` }
                legalNotice={ legalNotice! }
                selectedDepartment={ legalNoticePriceInit.selectedDepartment }
                selectedNewspaper={ legalNoticePriceInit.selectedNewspaper }
                selectedCategory={ legalNoticePriceInit.selectedCategory }
                validateLegalNotice={ legalNoticePriceInit.validateLegalNotice }
                isFormComplete={ this.props.isFormComplete }
                isHeaderCharacterCount={ legalNoticePriceInit.isHeaderCharacterCount }
                isCompactRender={ true }
                isAdmin={ true }
              />
						}
					</div>
					<Button
						label={ 'Aperçu' }
						variant={ 'secondary' }
						iconName={ 'LuEye' }
						onClick={ () => this.setState({ isRenderOpen: true }) }
						disabled={ !(this.props.content && this.props.content !== '<p></p>') }
					/>
				</div>

				<div style={ {
					backgroundColor: colors.white,
					borderRadius: 8,
					maxHeight: '100%',
					overflow: 'hidden',
					display: 'flex',
					flexDirection: 'column',
				} }>
					<div style={ CreateLegalNoticeStyle.contentContainerStyle() }>
						<HeaderOptionContentComponent
							modalContext={ this.props.modalContext }
							flashMessageContext={ this.props.flashMessageContext }
							isHeaderOptionDisplay={ true }
							isLogoOptionDisplay={ true }
							isConsumerFormFilled={ this.props.isConsumerFormFilled }
							selectedClient={ this.props.selectedClient }
							onSelectedLogo={ this.props.onSelectedLogo }
							isHeader={ this.props.isHeader }
							isLogo={ this.props.isLogo }
							onCheckIsHeader={ this.props.onCheckIsHeader }
							onCheckIsLogo={ this.props.onCheckIsLogo }
						/>
					</div>

					{/* TITLE */ }
					<div style={ CreateLegalNoticeStyle.contentContainerStyle() }>
						<Input
							type={ 'text' }
							label="Titre de l'annonce"
							width={ '100%' }
							name="legalNoticeData.title"
							value={ this.props.title || '' }
							onChange={ (event: any) => this.props.onChangeLegalNoticeTitle(event.target.value) }
						/>
					</div>

					{/* CONTENT */ }
					<div style={ { ...CreateLegalNoticeStyle.contentContainerStyle(), maxHeight: '100%', height: 400 } }>
						<Wysiwyg
							initialContent={ this.formatTextWithLineBreaks(this.props.content) }
							onContentChange={ (content) => this.props.onChangeLegalNoticeContent(content) }
						/>
					</div>

					{/* SIGNATURE */ }
					<div style={ CreateLegalNoticeStyle.contentContainerStyle() }>
						<Input
							type="text"
							style={ { display: 'flex' } }
							label="Signature"
							name="legalNoticeData.signature"
							value={ this.props.signature || '' }
							onChange={ (event) => this.props.onChangeLegalNoticeSignature(event.target.value) }
						/>
					</div>
				</div>
				<div style={ { display: 'flex', justifyContent: 'flex-end', marginTop: 10 } }>
					<ButtonOld
						type={ 'default-blue' }
						disabled={ !this.props.isFormComplete }
						onClick={ this.props.onCreate }
					>
						Valider l'annonce
					</ButtonOld>
				</div>

				{/* OFF CANVAS */ }
				<OffCanvasComponent
					show={ this.state.isRenderOpen }
					onHide={ () => this.setState({ isRenderOpen: false }) }
					isNavigateArrow={ false }
					isTabNeeded={ false }
					width={ 620 }
				>
					<div style={ { ...FontStyle.h2(), textAlign: 'center', marginBottom: 30 } }>Aperçu de votre annonce légale
					</div>
					<LegalNoticeRender
						legalNotice={ this.props.legalNotice }
						consumer={ this.props.consumer }
						isShow={ false }
					/>
				</OffCanvasComponent>
			</>
		);
	}

	private formatTextWithLineBreaks(text: string): string
	{
		let formattedText = text.replace(/\n\n/g, '</p><p>');
		formattedText = formattedText.replace(/\n/g, '<br/>');
		return formattedText;
	}
}
