import { CSSProperties } from 'react';
import { _inputStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { InputProps } from '@/Modules/App/Components/Library/Input/Input.interface';

export const InputStyles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
	} as CSSProperties,

	input: (isFocused: boolean, props: InputProps) => ({
		position: 'relative',
		..._inputStyles.main,
		...(props.errorMessage) && { borderColor: colors.error300 },
		...(props.width) && { width: props.width },
		...(props.disabled) && { backgroundColor: colors.gray200, cursor: 'not-allowed' },
		...(props.css?.border) && { border: props.css?.border },
		display: 'flex',
		alignItems: 'center',
		boxShadow: (isFocused) ? `0px 0px 1px 2px ${ colors.blueRibbon300 }` : 'none',
	}) as CSSProperties,

	inputSearch: {
		position: 'relative',
		..._inputStyles.main,
		backgroundColor: colors.gray100,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		border: 'none'
	} as CSSProperties,

	inputInner: (isDisabled: boolean) => ({
		opacity: (isDisabled) ? 0.4 : 1,
		fontSize: 'inherit',
		lineHeight: 'inherit',
		border: 'none',
		backgroundColor: 'inherit',
		...(isDisabled) && { cursor: 'not-allowed' },
		width: '100%',
		display: 'block',
		padding: 0,
		outline: 0
	}) as CSSProperties,

	icon: {
		position: 'absolute',
		left: 10,
		color: colors.gray600,
		fontSize: '14px',
	} as CSSProperties,

	pressEnter: {
		position: 'absolute',
		padding: '2px',
		height: 16,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 4,
		fontSize: 12,
		right: 0,
		marginRight: 5,
		backgroundColor: colors.gray400,
		color: 'white',
	} as CSSProperties,

	focus: {} as CSSProperties,

	errors: {
		fontSize: 12,
		fontWeight: 500,
		color: colors.error500,
	} as CSSProperties,

	skeleton: (props: InputProps) => ({
		width: (props.width) ? props.width : '100%'
	}) as CSSProperties,

} as const;