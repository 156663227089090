import React, { ReactElement } from 'react';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

interface ComponentProps
{
	isDisplayBlock: boolean,
	isBodacc: boolean,
	onSelectedIsBodacc: (event: any) => void
}

export default class BlockBodaccComponent extends React.Component<ComponentProps, any>
{
	render(): ReactElement
	{
		return (
			<>
				{ this.props.isDisplayBlock &&
          <SectionCollapsed isCollapsed={ false } title={ ' Bodacc' } highlightColor={ colors.gray100 }>
            <Checkbox
              label="Voulez-vous ajouter le Bodacc ?"
              name="isBodacc"
              isChecked={ this.props.isBodacc }
              onCheckedChange={ (event: any) => this.props.onSelectedIsBodacc(event) }
              style={ { display: 'flex' } }
            />
          </SectionCollapsed>
				}
			</>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">
	//</editor-fold>
}