import React, { Component, ReactElement } from 'react';
import { LegalNoticeTableListProps, LegalNoticeTableListState } from './LegalNoticeTableList.interface';
import { LegalNoticeTableListService } from './LegalNoticeTableList.service';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import ListTableManager from '@/Modules/App/Components/ListTableManager/ListTableManager';
import { LegalNoticesAdminService } from '@/Modules/LegalNotice/Admin/List/Regular/LegalNoticesAdmin.service';

class LegalNoticeTableList extends Component<LegalNoticeTableListProps, LegalNoticeTableListState>
{
	private legalNoticeTableListService = new LegalNoticeTableListService();
	private legalNoticesAdminService: LegalNoticesAdminService = new LegalNoticesAdminService();

	constructor(props: LegalNoticeTableListProps)
	{
		super(props);

		// Config service
		this.legalNoticeTableListService.setProps(this.props);
		this.legalNoticeTableListService.subscribeState(this);

		// State
		this.state = this.legalNoticeTableListService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeTableListService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeTableListService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<>
				<ListTableManager
					isTableControls={ true }
					tag={ 'legalNotice' }
					refreshList={ this.state.list }
					tableColumns={ this.legalNoticeTableListService.buildColumnHeader() }
					filters={ [] }
					blockElementsPerPage={ 10 }
					serviceList={ (params) => this.legalNoticesAdminService.list({ type: 'regular', filterParams: params }) }
					onRowClick={ (event: any, item: any) => this.props.navigation('/admin/legal-notices/' + item.id) }
				/>
			</>
		);
	}
}

export default withGlobalContext(LegalNoticeTableList);