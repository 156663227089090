import React, { ReactElement } from 'react';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';

interface ComponentProps
{
	formData: AddressInterface | null,
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
	dataKey?: string,
	isPhoneNeeded?: boolean,
	onBlur: () => void,
	isPhysicalPerson?: boolean,
}

export default class AddressFormComponent extends React.Component<ComponentProps, any>
{
	render(): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					gap: '20px',
					marginTop: '10px',
					marginBottom: '15px',
					width: '100%',
					flexGrow: 1
				} }>
					<Input
						type="text"
						style={ { display: 'flex', flexGrow: 1 } }
						label={ (!this.props.isPhysicalPerson) ? "Dénomination" : 'Nom et Prénom'}
						name="name"
						placeholder={ 'Dénomination' }
						value={ this.props.formData?.name || '' }
						onChange={ this.props.onChange }
						onBlur={ this.props.onBlur }
					/>
					{ this.props.isPhoneNeeded &&
            <Input
              type="text"
              label="Téléphone"
              name="phone"
              placeholder="Ex: 04 67 65 45 34"
              value={ this.props.formData?.phone || '' }
              onChange={ this.props.onChange }
              onBlur={ this.props.onBlur }
            />
					}
				</div>
				<div style={ { display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' } }>
					<div style={ { flex: '0 1 10%' } }>
						<Input
							type="text"
							style={ { display: 'flex' } }
							label="Numéro"
							name="number"
							placeholder="Ex: 26"
							value={ this.props.formData?.number || '' }
							onChange={ this.props.onChange }
							onBlur={ this.props.onBlur }
						/>
					</div>
					<div style={ { flex: '1 0 50%' } }>
						<Input
							type="text"
							style={ { display: 'flex' } }
							label="Rue"
							name="street"
							placeholder="Ex: rue du Prado"
							value={ this.props.formData?.street || '' }
							onChange={ this.props.onChange }
							onBlur={ this.props.onBlur }
						/>
					</div>
				</div>
				<div style={ { display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' } }>
					<Input
						type="text"
						style={ { display: 'flex', flexGrow: 1 } }
						label="Complément d'adresse"
						name="additionalData"
						placeholder="Ex: Résidence Cap Concorde"
						value={ this.props.formData?.additionalData || '' }
						onChange={ this.props.onChange }
						onBlur={ this.props.onBlur() }
					/>
				</div>
				<div style={ {
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)',
					gap: '20px',
					marginBottom: '15px',
					width: '100%'
				} }>

					<Input
						type="text"
						label="Code postal"
						name="zipCode"
						placeholder="Ex : 34170"
						value={ this.props.formData?.zipCode || '' }
						onChange={ this.props.onChange }
						onBlur={ this.props.onBlur }
					/>

					<Input
						type="text"
						label="Ville"
						name="city"
						placeholder="Castelnau-le-lez"
						value={ this.props.formData?.city || '' }
						onChange={ this.props.onChange }
						onBlur={ this.props.onBlur }
					/>
				</div>
			</>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">
	//</editor-fold>
}