import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ClientAdminProps, ClientAdminState } from './ClientsAdmin.interface';
import { FiltersInterface, TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { CreateClientFormState } from '@/Modules/Client/Components/CreateClientForm/CreateClientForm.interface';
import { ModalContextType } from '@/Provider/ModalProvider';
import { ClientTypeEnum } from '@/Modules/Client/Enum/ClientTypeEnum';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';

const initState: ClientAdminState = {
	refreshKey: 0,
	clientList: {} as PaginateInterface,
};

export class ClientsAdminService extends BaseComponentService<ClientAdminProps, ClientAdminState>
{
	constructor()
	{
		super({} as ClientAdminProps, initState);

		// Bind
		this.handleCreateClientForm = this.handleCreateClientForm.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}

	handleCreateClientForm(createClientFormState: CreateClientFormState, modalContext: ModalContextType): void
	{
		if (createClientFormState) {
			modalContext?.isLoadingOverlay(createClientFormState.isLoading);

			if (!createClientFormState.isLoading && !createClientFormState.errorMessage) {
				modalContext.isOpen(false);
				this.setState(prevState => ({ refreshKey: prevState.refreshKey + 1 }));
			}
		}
	}

	buildColumnHeader(): TableHeadColumnsInterface[]
	{
		return [
			{
				title: 'Dénomination',
				tag: 'name',
				columnWidth: 300,
				textAlign: 'left',
				fontWeight: 600,
				keyType: 'string',
				keyTag: 'name'
			},
			{
				title: 'Siren',
				tag: 'siren',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: 'siren'
			},
			{
				title: 'Siret',
				tag: 'siret',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: 'siret'
			},
			{
				title: 'Ville',
				tag: 'city',
				columnWidth: 200,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: 'address.city'
			},
			{
				title: 'Code Postal',
				tag: 'zipCode',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: 'address.zipCode'
			},
			{
				title: 'Type',
				tag: 'type',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'tag',
				enumClass: 'ClientTypeEnum',
				keyTag: 'options.type'
			},
			{
				title: 'Crée le...',
				tag: 'createdAt',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'date',
				keyTag: 'createdAt'
			},
			{
				title: 'Statut',
				tag: 'status',
				columnWidth: 80,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'tag',
				enumClass: 'ActiveStatusEnum',
				keyTag: 'status'
			}
		];
	}

	buildFilters(): FiltersInterface[]
	{
		return [
			{
				title: 'Statut',
				tag: 'status',
				type: 'selector',
				options: ActiveStatusEnum.optionsFilter
			},
			{
				title: 'Type',
				tag: 'clientType',
				type: 'selector',
				options: ClientTypeEnum.optionsFilter
			},
			{
				title: 'Ville',
				tag: 'city',
				type: 'search'
			}
		];
	}
}