import {
	AssociateSellsyCompanyProps,
	AssociateSellsyCompanyState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.interface';
import { CompanySellsyInterface } from '@/Modules/Client/Interface/CompanySellsyInterface';
import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import { ClientModel } from '@/Modules/Client/Model/ClientModel';

const initState: AssociateSellsyCompanyState = {
	formData: {} as ClientModel,
	companySellsySearchResults: null,
	selectedCompanySellsy: {} as CompanySellsyInterface,
	isLoadingSellsySearch: false,
	isOpen: false,
};

export class AssociateSellsyCompanyService extends BaseComponentService<AssociateSellsyCompanyProps, AssociateSellsyCompanyState>
{
	private apiClientSellsyService: ApiAdminSellsyService;

	constructor()
	{
		super({} as AssociateSellsyCompanyProps, initState);

		// Service
		this.apiClientSellsyService = new ApiAdminSellsyService();

		// Bind
		this.searchCompanySellsy = this.searchCompanySellsy.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleSelectedCompanySellsy = this.handleSelectedCompanySellsy.bind(this);
		this.handleCancelSelectedCompanySellsy = this.handleCancelSelectedCompanySellsy.bind(this);
		this.handleCreateNewCompanySellsy = this.handleCreateNewCompanySellsy.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({ formData: new ClientModel() });
	}

	/**
	 * Handle Click Outside Element
	 * @param event
	 * @param containerRef
	 */
	handleClickOutside(event: MouseEvent, containerRef: React.RefObject<HTMLDivElement>): void
	{
		if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
			this.setState({ isOpen: false });
		}
	}

	/**
	 *
	 * @param event
	 * @param searchTerm
	 */
	async searchCompanySellsy(event: any, searchTerm: string | null): Promise<void> {
		this.setState({ isLoadingSellsySearch: true });

		event.preventDefault();
		event.stopPropagation();
		try {
			const companyId: number = this.props.authCompanyContext?.authCompany?.id! || this.props.authContext?.user?.company?.id!;

			if (this.props.authCompanyContext && searchTerm && companyId) {
				const companySearchResult = await this.apiClientSellsyService.searchCompanyByTerm(
					companyId,
					searchTerm
				);

				this.setState({
					companySellsySearchResults: companySearchResult,
					isOpen: true,
				});
			}

		} catch (error) {
			console.error('Erreur lors de la recherche de la société sur Sellsy:', error);
		} finally {
			this.setState({ isLoadingSellsySearch: false });
		}
	}

	/**
	 *
	 * @param event
	 * @param companySellsy
	 */
	handleSelectedCompanySellsy(event: any, companySellsy: CompanySellsyInterface): void
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				extSellsyId: companySellsy.object.id
			},
			selectedCompanySellsy: companySellsy,
			isOpen: false
		}), () => this.props.getComponentState(this.state));
	}

	/**
	 * Prepare to create new company Sellsy
	 * @param event
	 */
	handleCreateNewCompanySellsy(event: any) : void
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				isSellsyCreated: true,
				extSellsyId: null
			}
		}), () =>
		{
			this.props.getComponentState(this.state);
			this.props.flashMessageContext?.flashMessage(
				'Contact Créé',
				'Ce donneur d’ordre a bien été créé dans Sellsy',
				'success'
			);
		});
	}

	/**
	 * Canceled / Reset selected company
	 * @param event
	 */
	handleCancelSelectedCompanySellsy(event: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState({
			selectedCompanySellsy: null,
			formData: {
				...this.state.formData,
				isSellsyCreated: false,
			}
		});
	}

}