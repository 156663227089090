import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
	InputProps,
	InputState,
} from '@/Modules/App/Components/Library/Input/Input.interface';
import Label from '@/Modules/App/Components/Library/Label/Label';
import { InputStyles } from '@/Modules/App/Components/Library/Input/Input.styles';
import { InputService } from '@/Modules/App/Components/Library/Input/Input.service';
import { LuSearch, LuCornerDownLeft } from 'react-icons/lu';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { ClipLoader } from 'react-spinners';

export default class Input extends React.Component<InputProps, InputState>
{
	inputService: InputService = new InputService();

	constructor(props: InputProps)
	{
		super(props);

		// Config service
		this.inputService.setProps(this.props);
		this.inputService.subscribeState(this);

		// State
		this.state = this.inputService.getState();
	}

	render(): ReactElement
	{
		const isSearch = this.props.type === 'search';

		const inputStyle =
			(this.props.type !== 'search')
				? InputStyles.input(this.state.isFocused, this.props)
				: InputStyles.inputSearch
		;

		const mergedStyle = {
			...InputStyles.container,
			...(this.props.style || {}),
			...(this.props.disabled && { opacity: 0.4 })
		};

		return (
			<>
				<div style={ mergedStyle }>
					{ (this.props.label) && <Label label={ this.props.label } isRequired={ Boolean(this.props.required) }/> }
					{ this.props.textHelp && <div style={ {
						...FontStyles.textHelp, width: this.props.width, marginBottom: 5
					} }>
						{ this.props.textHelp }
          </div> }
					{ (this.props.isLoading) ? (
						<div style={ InputStyles.skeleton(this.props) }><Skeleton height={ 28 }/></div>
					) : (
						<div style={ inputStyle }>
							{ this.handleSearchIconRender(isSearch) }
							<input
								ref={ this.props.inputRef }
								type={ this.props.type }
								value={ this.props.value }
								onChange={ this.props.onChange || this.inputService.handleChangeAllowedCharacters }
								onBlur={ (this.props.onBlur) ? this.props.onBlur : this.inputService.handleBlur }
								onFocus={ (this.props.onFocus) ? this.props.onFocus : this.inputService.handleFocus }
								onKeyDown={ this.inputService.handleKeyDown }
								autoComplete={ this.props.autocomplete }
								style={ InputStyles.inputInner(this.props.disabled!) }
								name={ this.props.name }
								placeholder={ this.props.placeholder }
								disabled={ this.props.disabled }
								onMouseDown={ this.props.onMouseDown }
								onClick={ this.props.onClick }
								checked={ this.props.checked }
							/>
							{ isSearch && this.state.isFocused && <div style={ InputStyles.pressEnter }><LuCornerDownLeft/></div> }
						</div>
					) }
					{ this.props.errorMessage &&
            <div style={ InputStyles.errors }>
							{ this.props.errorMessage }
            </div>
					}
				</div>
			</>
		);
	}

	private handleSearchIconRender(isSearch: boolean): ReactElement | null
	{
		if (isSearch) {
			if (this.props.isLoadingSearch) {
				return (
					<div style={ InputStyles.icon }>
						<ClipLoader
							color={ colors.gray400 }
							loading
							size={ 14 }
							speedMultiplier={ 1 }
						/>
					</div>
				);
			}
			return (
				<>
					<LuSearch style={ InputStyles.icon }/>
				</>
			);
		}

		return null;
	}
}