import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { ReactElement } from 'react';
import { OptionFilterInterface } from '@/Modules/App/Components/Library/Table/Table.interface';

export type UserRoleEnumValue = typeof UserRoleEnum.options[number]['value'];

export class UserRoleEnum {
	value: string;
	label: string;
	css: { backgroundColor: string; color: string; border?: string, icon?: ReactElement | null };

	constructor(value: string, label: string, css: { backgroundColor: string; color: string; border?: string, icon?: ReactElement | null }) {
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static ROLE_DEVELOPER = new UserRoleEnum(
		'ROLE_DEVELOPER',
		'Développeur',
		{
			backgroundColor: colors.error100,
			color: colors.error700,
			border: `1px solid ${ colors.error200 }`,
			icon: null,
		}
	);

	static ROLE_SUPER_ADMIN = new UserRoleEnum(
		'ROLE_SUPER_ADMIN',
		'Super Admin',
		{
			backgroundColor: colors.orange100,
			color: colors.orange700,
			border: `1px solid ${ colors.orange200 }`,
			icon: null,
		}
	);

	static ROLE_ADMIN = new UserRoleEnum(
		'ROLE_ADMIN',
		'Admin',
		{
			backgroundColor: colors.warning100,
			color: colors.warning700,
			border: `1px solid ${ colors.warning200 }`,
			icon: null,
		}
	);

	static ROLE_USER = new UserRoleEnum(
		'ROLE_USER',
		'Utilisateur',
		{
			backgroundColor: colors.blueRibbon100,
			color: colors.blueRibbon700,
			border: `1px solid ${ colors.blueRibbon200 }`,
			icon: null,
		}
	);

	static  ROLE_USER_PARTICULAR = new UserRoleEnum(
		' ROLE_USER_PARTICULAR',
		'Particulier',
		{
			backgroundColor: colors.blueRibbon100,
			color: colors.blueRibbon700,
			border: `1px solid ${ colors.blueRibbon200 }`,
			icon: null,
		}
	);

	static options: UserRoleEnum[] = [
		UserRoleEnum.ROLE_DEVELOPER,
		UserRoleEnum.ROLE_SUPER_ADMIN,
		UserRoleEnum.ROLE_ADMIN,
		UserRoleEnum.ROLE_USER,
		UserRoleEnum. ROLE_USER_PARTICULAR,
	];

	static optionsFilter: OptionFilterInterface[] = [
		{ label: UserRoleEnum.ROLE_DEVELOPER.label, tag: UserRoleEnum.ROLE_DEVELOPER.value },
		{ label: UserRoleEnum.ROLE_SUPER_ADMIN.label, tag: UserRoleEnum.ROLE_SUPER_ADMIN.value },
		{ label: UserRoleEnum.ROLE_ADMIN.label, tag: UserRoleEnum.ROLE_ADMIN.value },
		{ label: UserRoleEnum.ROLE_USER.label, tag: UserRoleEnum.ROLE_USER.value },
	];

	static superAdminGroup: UserRoleEnum[] = [
		UserRoleEnum.ROLE_DEVELOPER,
		UserRoleEnum.ROLE_SUPER_ADMIN,
	];

	static fullGroup: UserRoleEnum[] = [
		UserRoleEnum.ROLE_DEVELOPER,
		UserRoleEnum.ROLE_SUPER_ADMIN,
		UserRoleEnum.ROLE_ADMIN,
		UserRoleEnum.ROLE_USER,
	];

	static adminGroup: UserRoleEnum[] = [
		UserRoleEnum.ROLE_DEVELOPER,
		UserRoleEnum.ROLE_SUPER_ADMIN,
		UserRoleEnum.ROLE_ADMIN,
	];

	static userGroup: UserRoleEnum[] = [
		UserRoleEnum.ROLE_USER
	];

	static filteredOptions: UserRoleEnum[] = [
		UserRoleEnum.ROLE_ADMIN,
		UserRoleEnum.ROLE_USER,
	];

	static findByValue(selectedOption: UserRoleEnum | string): any {
		return UserRoleEnum.options.find(option => option.value === selectedOption);
	}
}
