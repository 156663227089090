import React, { Component, ReactElement } from 'react';
import { DataTableManagerProps, DataTableManagerState } from './ListTableManager.interface';
import { ListTableManagerService } from './ListTableManager.service';
import TableControls from '@/Modules/App/Components/Library/TableControls/TableControls';
import Separator from '@/Modules/App/Components/Atom/Separator/Separator';
import IsLoading from '@/Modules/App/Components/Loader/IsLoading/IsLoading';
import Table from '@/Modules/App/Components/Library/Table/Table';
import Pagination from '@/Modules/App/Components/Library/Pagination/Pagination';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { FiltersInterface } from '@/Modules/App/Components/Library/Table/Table.interface';

class ListTableManager extends Component<DataTableManagerProps, DataTableManagerState>
{
	private dataTableManagerService = new ListTableManagerService();

	constructor(props: DataTableManagerProps)
	{
		super(props);

		// Config service
		this.dataTableManagerService.setProps(this.props);
		this.dataTableManagerService.subscribeState(this);

		// State
		this.state = this.dataTableManagerService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.dataTableManagerService.init();
	}

	componentDidUpdate(prevPros: DataTableManagerProps): void
	{
		if (prevPros.refreshList.items !== this.props.refreshList.items) {
			this.dataTableManagerService.fetchData();
		}
	}

	componentWillUnmount(): void
	{
		this.dataTableManagerService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoadingFullPage) {
			return (<LoaderComponent/>);
		}

		const { refreshKey, isLoading, list, filterParams } = this.state;

		return (
			<>
				{ this.props.isTableControls &&
          <>
            <TableControls
              key={ filterParams?.orderBy?.toString()! + filterParams?.sort?.toString() + filterParams?.filters?.toString()! }
              filters={ this.props.filters as FiltersInterface[] }
              tag={ this.props.tag }
              onFilterChange={ this.dataTableManagerService.handleFilterChange }
              onApplyFilter={ (filterKey: string, value: string) => this.dataTableManagerService.handleApplyFilter(filterKey, value) }
              onChangeTerm={ this.dataTableManagerService.handleChangeTerm }
              onClearFilters={ this.dataTableManagerService.handleClearFilters }
            />
            <Separator visibility={ 'visible' } height={ 1 }/>
          </>
				}
				<div
					style={ {
						maxWidth: '100%',
						maxHeight: 'calc(100% - 140px)',
						height: '100%',
						position: 'relative',
						overflow: 'auto',
					} }
				>
					<IsLoading isLoading={ isLoading } refreshKey={ refreshKey }>
						<Table
							key={ refreshKey }
							columns={ this.props.tableColumns }
							list={ list }
							filterParams={ this.state.filterParams }
							onOrderBy={ this.dataTableManagerService.handleOrderBy }
							onRowClick={ (item: any, event: any) => this.props.onRowClick(event, item) }
						/>

						<Pagination
							tag={ this.props.tag }
							currentPage={ filterParams.page }
							itemsPerPage={ filterParams.itemsPerPage }
							blockElementsPerPage={ this.props.blockElementsPerPage }
							list={ list }
							onPageChange={ this.dataTableManagerService.handlePageChange }
							onItemsPerPageChange={ this.dataTableManagerService.handleItemsPerPageChange }
						/>
					</IsLoading>
				</div>
			</>
		);
	}
}

export default ListTableManager;