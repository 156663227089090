import PublishStateEnum from '@/Enum/PublishStateEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';

type EnumType = typeof PublishStateEnum | typeof PaymentStateEnum;

function findEnumLabel(value: string, enumType: EnumType): string {
	const foundEnum = enumType.findByValue(value);
	return foundEnum ? foundEnum.label : value;
}

export function getDisplayValue(key: string, value: string): string {
	let enumType;
	if (key === 'status') {
		enumType = PublishStateEnum;
	} else if (key === 'paymentStatus') {
		enumType = PaymentStateEnum;
	} else {
		return value;
	}

	return findEnumLabel(value, enumType);
}