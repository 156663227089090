import React, { Component, ReactElement } from 'react';
import { ClientAdminProps, ClientAdminState } from './ClientsAdmin.interface';
import { ClientsAdminService } from './ClientsAdmin.service';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import ListTableManager from '@/Modules/App/Components/ListTableManager/ListTableManager';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import CreateClientForm from '@/Modules/Client/Components/CreateClientForm/CreateClientForm';
import { ModalContextType } from '@/Provider/ModalProvider';

class ClientsAdmin extends Component<ClientAdminProps, ClientAdminState>
{
	private clientAdminService: ClientsAdminService = new ClientsAdminService();
	private apiClientAdminService: ApiAdminClientService = new ApiAdminClientService();
	private createClientModal: ModalContextType;

	constructor(props: ClientAdminProps)
	{
		super(props);

		// Config service
		this.clientAdminService.setProps(this.props);
		this.clientAdminService.subscribeState(this);

		// State & Props
		this.state = this.clientAdminService.getState();
		this.createClientModal = this.props.modalContext!;

		// Bind
		this.createClientModalRender = this.createClientModalRender.bind(this);

		// Document title
		document.title = 'Publisur - ADMIN - Clients';
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.clientAdminService.init();
	}

	componentWillUnmount(): void
	{
		this.clientAdminService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				<HeroSection
					title="Donneur d'ordre"
					icon={ null }
					button={ {
						label: 'Ajouter un donneur d\'ordre',
						variant: 'primary',
						iconName: 'LuPlus',
						onClick: this.createClientModalRender
					} }
				/>

				<ListTableManager
					isTableControls={ true }
					key={ this.state.refreshKey }
					tag={ 'client' }
					refreshList={ this.state.clientList }
					tableColumns={ this.clientAdminService.buildColumnHeader() }
					filters={ this.clientAdminService.buildFilters() }
					serviceList={ this.apiClientAdminService.list.bind(this.apiClientAdminService) }
					onRowClick={ (event: any, item: any) => this.props.navigation('/admin/clients/' + item.id) }
				/>
			</>
		);
	}

	private createClientModalRender(event: any): void
	{
		event.stopPropagation();

		this.createClientModal.content(
			'Créer un donneur d\'ordre',
			<CreateClientForm
				getComponentState={
				(createClientFormState) =>
					this.clientAdminService.handleCreateClientForm(createClientFormState, this.createClientModal)
			}
			/>
		);
	}
}

export default withGlobalContext(ClientsAdmin);