import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	NewspapersClosureManagementProps,
	NewspapersClosureManagementState
} from './NewspapersClosureManagements.interface';
import { TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';

const initState: NewspapersClosureManagementState = {
	newspaperManagementList: {} as PaginateInterface,
};

export class NewspapersClosureManagementsService extends BaseComponentService<NewspapersClosureManagementProps, NewspapersClosureManagementState>
{
	constructor()
	{
		super({} as NewspapersClosureManagementProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}

	buildColumnHeader(): TableHeadColumnsInterface[]
	{
		return [
			{
				title: 'Support',
				tag: 'clientName',
				columnWidth: 80,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: 'newspaper.type'
			},
			{
				title: 'Numéro d’édition',
				tag: 'clientName',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 600,
				keyType: 'string',
				keyTag: 'editionNumber'
			},
			{
				title: 'Nom du journal',
				tag: 'clientName',
				columnWidth: 250,
				textAlign: 'left',
				fontWeight: 600,
				keyType: 'string',
				keyTag: 'newspaper.name'
			},
			{
				title: 'Département',
				tag: 'department',
				columnWidth: 200,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'string',
				keyTag: ['department.name']
			},
			{
				title: 'Statut',
				tag: 'status',
				columnWidth: 80,
				textAlign: 'left',
				fontWeight: 600,
				keyType: 'tag',
				enumClass: 'PublishStateEnum',
				keyTag: 'status'
			},
			{
				title: 'Date de bouclage',
				tag: 'clientName',
				columnWidth: 150,
				textAlign: 'left',
				fontWeight: 400,
				keyType: 'date',
				keyTag: 'publishDate'
			},
			{
				title: 'Nombre d’annonce',
				tag: 'clientName',
				columnWidth: 150,
				textAlign: 'center',
				fontWeight: 400,
				keyType: 'string',
				keyTag: 'count'
			},
		];
	}
}