import React, { Component, createRef, ReactElement } from 'react';
import {
	ToggleOpenClosedProps,
	ToggleOpenClosedState
} from '@/Modules/App/Components/Library/ToggleOpenClosed/ToggleOpenClosed.interface';
import { ToggleOpenClosedService } from '@/Modules/App/Components/Library/ToggleOpenClosed/ToggleOpenClosed.service';

class ToggleOpenClosed extends Component<ToggleOpenClosedProps, ToggleOpenClosedState>
{
	private toggleOpenClosedService = new ToggleOpenClosedService();
	private containerRef = createRef<HTMLDivElement>();
	private menuRef = createRef<HTMLDivElement>();

	constructor(props: ToggleOpenClosedProps)
	{
		super(props);

		// config Service
		this.toggleOpenClosedService.setProps(this.props);
		this.toggleOpenClosedService.subscribeState(this);

		// State
		this.state = this.toggleOpenClosedService.getState();
	}

	componentDidMount(): void
	{
		if (this.props.forceIsOpen !== undefined) {
			this.toggleOpenClosedService.setIsOpen(this.props.forceIsOpen);
		}

		if (this.props.isClickOutside) {
			this.toggleOpenClosedService.setIsClickOutside(this.props.isClickOutside);
			document.addEventListener(
				'mousedown',
				(event: any) => this.toggleOpenClosedService.handleClickOutside(event, this.containerRef)
			);
		}
	}

	/**
	 * Calls the service to calculate the best position for the menu and passes it down
	 */
	componentDidUpdate(_: ToggleOpenClosedProps, prevState: ToggleOpenClosedState) : void
	{
		// When the menu opens, calculate the best position
		if (!prevState.isOpen && this.state.isOpen) {
			const menuPosition = this.toggleOpenClosedService.calculateMenuPosition(this.menuRef, this.containerRef);
			if (menuPosition) {
				this.setState({ menuPosition }); // Store the position in the state
			}
		}

		// When forceIsOpen changes, update the isOpen state
		if (this.props.forceIsOpen !== _.forceIsOpen) {
			this.toggleOpenClosedService.setIsOpen(this.props.forceIsOpen!);
			this.setState({ isOpen: this.props.forceIsOpen! });
		}
	}

	componentWillUnmount(): void
	{
		if (this.props.isClickOutside) {
			document.removeEventListener(
				'mousedown',
				(event: any) => this.toggleOpenClosedService.handleClickOutside(event, this.containerRef)
			);
		}
	}

	render(): ReactElement | null
	{
		const { children } = this.props;
		const { isOpen, menuPosition } = this.state;
		return (
			<div ref={ this.containerRef }>
				{ typeof children === 'function'
					? children({
						toggle: this.toggleOpenClosedService.toggle.bind(this.toggleOpenClosedService),
						close: this.toggleOpenClosedService.close.bind(this.toggleOpenClosedService),
						open: this.toggleOpenClosedService.open.bind(this.toggleOpenClosedService),
						setIsOpen: this.toggleOpenClosedService.setIsOpen.bind(this.toggleOpenClosedService),
						isOpen,
						menuPosition: menuPosition || { top: 0, left: 0 },
						menuRef: this.menuRef,
					})
					: null }
			</div>
		);
	}
}

export default ToggleOpenClosed;
