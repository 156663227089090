export class NewspaperTypeEnum
{
	value: string;
	label: string;

	constructor(value: string, label: string)
	{
		this.value = value;
		this.label = label;
	}

	static PAPER = new NewspaperTypeEnum('PAPER', 'Papier');
	static WEB = new NewspaperTypeEnum('WEB', 'Web / Numérique');

	static options: NewspaperTypeEnum[] = [
		NewspaperTypeEnum.PAPER,
		NewspaperTypeEnum.WEB
	];

	static findByValue(selectedOption: NewspaperTypeEnum | string): any
	{
		return NewspaperTypeEnum.options.find(
			option => option.value === selectedOption
		)
	}
}