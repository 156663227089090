import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	LegalNoticeSendToAdditionalProps, LegalNoticeSendToAdditionalState,
} from './LegalNoticeSendToAdditional.interface';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';

const initState: LegalNoticeSendToAdditionalState = {
	isLoading: false,
	formData: {
		billingAddress: {} as AddressInterface,
		numberOfCopies: null
	}
};

export class LegalNoticeSendToAdditionalService extends BaseComponentService<LegalNoticeSendToAdditionalProps, LegalNoticeSendToAdditionalState>
{
	private apiAdminLegalNoticeService: ApiAdminLegalNoticeService = new ApiAdminLegalNoticeService();

	constructor()
	{
		super({} as LegalNoticeSendToAdditionalProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({ isLoading: true });
		await this.apiAdminLegalNoticeService.show(this.props.legalNotice.id);
		this.setState({ isLoading: false });
		await this.prepareForEdit();
	}

	onChangeBillingAddress (event: React.ChangeEvent<HTMLInputElement>): void
	{
		const { name, value } = event.target;

		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				billingAddress: {
					...prevState.formData.billingAddress,
					[name]: value,
				},
			},
		}));
	}

	onChangeNumberOfCopies(event: any)
	{
		this.setState((prevState: { formData: any }) => ({
			formData: {
				...prevState.formData,
				numberOfCopies: parseInt(event.target.value)
			}
		}));
	}

	async onCreate(): Promise<any>
	{
		this.setState({ isLoading: true });
		const response= await this.apiAdminLegalNoticeService.sendToAdditional(this.props.legalNotice.id, this.state.formData);

		if (!response.errorMessage) {
			this.props.flashMessageContext?.flashMessage("L'envoie supplémentaire a été enregistré avec succès");
			this.setState({ isLoading: false });
		}

		this.setState({ isLoading: false });
	}

	prepareForEdit(): void
	{
		this.setState({
			formData: {
				billingAddress: this.props.legalNotice?.sendToAdditional?.billingAddress,
				numberOfCopies: this.props.legalNotice?.sendToAdditional?.numberOfCopies
			}
		})
	}
}