import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ClientLogoProps, ClientLogoState } from './ClientLogo.interface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import { UserService } from '@/Modules/App/Services/User/User.service';

const initState: ClientLogoState = {};

export class ClientLogoService extends BaseComponentService<ClientLogoProps, ClientLogoState>
{
	apiClientService: ApiAdminClientService | ApiClientService = (UserService.isAdmin() || UserService.isSuperAdmin())
		? new ApiAdminClientService()
		: new ApiClientService()
	;

	constructor()
	{
		super({} as ClientLogoProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}

	/**
	 * Download Logo Image
	 * @param logo
	 */
	downloadImage(logo: any): void
	{
		const link = document.createElement('a');
		link.href = logo.logoUrl;

		link.download = logo.name;

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	/**
	 * Handle Delete logo
	 */
	async delete(): Promise<void>
	{
		try {
			this.props.modalContext?.isLoadingOverlay(true);
			const response = await this.apiClientService.deleteLogo(this.props.clientId, this.props.logo.id);

			if(response.error) {
				this.props.modalContext?.isLoadingOverlay(false);
				this.props.modalContext?.isOpen(true);
				// FlashMessage
				this.props.flashMessageContext?.flashMessage(
					'Erreur',
					'Une erreur est survenue sur la suppression du logo',
					'error'
				);
			}

			this.props.modalContext?.isLoadingOverlay(false);
			this.props.modalContext?.isOpen(false);

			this.props.getComponentState();

			// FlashMessage
			this.props.flashMessageContext?.flashMessage(
				'Supprimé',
				'Logo supprimé avec succès',
				'success'
			);

		} catch (e: any) {
			console.error(` Error occurred on ${ this.constructor.name} - delete Logo`, e);
		}
	}

}