import React, { Component, ReactElement } from 'react';
import { LegalNoticeControlsProps, LegalNoticeControlsState } from './LegalNoticeControls.interface';
import { LegalNoticeControlsService } from './LegalNoticeControls.service';
import {
	LegalNoticeControlsStyles
} from '@/Modules/LegalNotice/Components/LegalNoticeControls/LegalNoticeControls.styles';
import { LuCheckCircle, LuInfo } from 'react-icons/lu';
import { _AlertsStyles } from '@/Modules/App/Style/Components/_Alerts.styles';
import { colors, dynamicColors } from '@/Modules/App/Style/Variables/Colors.styles';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { dateFormat } from '@/Utils/DateUtils';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { Alert } from 'react-bootstrap';
import { LegalNoticeModalStyle } from '@/Modules/LegalNotice/Style/LegalNoticeModalStyle';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { UserService } from '@/Modules/App/Services/User/User.service';
import LegalNoticeHandleEstimate
	from '@/Modules/LegalNotice/Components/LegalNoticeHandleEstimate/LegalNoticeHandleEstimate';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

class LegalNoticeControls extends Component<LegalNoticeControlsProps, LegalNoticeControlsState>
{
	private legalNoticeControlsService = new LegalNoticeControlsService();

	constructor(props: LegalNoticeControlsProps)
	{
		super(props);

		// Config service
		this.legalNoticeControlsService.setProps(this.props);
		this.legalNoticeControlsService.subscribeState(this);

		// State
		this.state = this.legalNoticeControlsService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeControlsService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeControlsService.unsubscribe();
	}

	componentDidUpdate(prevProps: LegalNoticeControlsProps): void
	{
		if (prevProps.legalNotice !== this.props.legalNotice) {
			this.setState({ legalNotice: this.props.legalNotice });
		}
	}

	//</editor-fold>

	render(): ReactElement
	{
		// State
		const { flags } = this.state;

		return (
			<div style={ LegalNoticeControlsStyles.container }>
				{ flags.isSupplier && this.supplierRender() }
				{ flags.isPaid && this.paidRender() }
				{ flags.isPublicationDatePast && !flags.isDraft && !flags.isCancelled && !flags.isQuote && this.publicationPastRender() }
				{ flags.isCancelled && this.cancelledRender() }
				{ flags.canEdit && this.canEditRender() }
			</div>
		);
	}

	private supplierRender(): ReactElement
	{
		return (
			<>
				{ !this.state.flags.isDraft &&
          <div style={ { ..._AlertsStyles.container(dynamicColors.gray), marginBottom: 10 } }>
            <LuInfo/>
            <span> Cette Annonce est une annonce extérieur, pour la modifier, il faut d'abord annuler, puis la dupliquer.</span>
          </div>
				}
			</>
		);
	}

	private publicationPastRender(): ReactElement
	{
		return (
			<div style={ _AlertsStyles.container(dynamicColors.success) }>
				<LuCheckCircle/>
				<span> Annonce légale publiée le : &nbsp; <span
					style={ FontStyle.underline() }> { dateFormat(this.props.legalNotice.publishDate) }</span></span>
			</div>
		);
	}

	private paidRender(): ReactElement
	{
		return (
			<div style={ { ..._AlertsStyles.container(dynamicColors.pink), marginBottom: 10 } }>
				<div>
					<div>
						⚠️ Attention, cette annonce est déjà payée. Vous devez effectuer un remboursement via Sellsy si vous
						l'annulez.
					</div>
					<div style={ { marginTop: 10 } }>
						<Button
							label="Annuler l'annonce"
							variant="error"
							onClick={ () => this.legalNoticeControlsService.onDeleted(this.props.legalNotice) }
						/>
					</div>
				</div>
			</div>
		);
	}

	private cancelledRender(): ReactElement
	{
		return (
			<div style={ _AlertsStyles.container(dynamicColors.error) }>
				<div style={ { width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
					<div>Annonce légale annulée</div>
					<Button
						label={ 'Dupliquer' }
						variant="secondary"
						onClick={ () => this.legalNoticeControlsService.onDuplicate() }
					/>
				</div>

			</div>
		);
	}

	/**
	 * Handle Can edit Render
	 * @private
	 */
	private canEditRender(): ReactElement
	{
		// State
		const { flags } = this.state;

		return (
			<div style={ LegalNoticeControlsStyles.canEditContainer }>
				<div>
					Date de publication : &nbsp;
					<span style={ FontStyle.underline() }>
							{ dateFormat(this.props.legalNotice.publishDate) }
						</span>
				</div>

				<div style={ LegalNoticeControlsStyles.canEditActions }>
					{ !flags.isQuote && !flags.isPaid && (UserService.isAdmin() || UserService.isSuperAdmin()) &&
            <Button
              label={ 'Annuler l\'annonce' }
              variant={ 'error' }
              onClick={ () => this.legalNoticeControlsService.onDeleted(this.props.legalNotice) }
            />
					}
					{ (UserService.isAdmin() || UserService.isSuperAdmin()) && (flags.isQuote && flags.isQuotePending) &&
            <Button
              label={ 'Gérer le devis' }
              variant={ 'secondary' }
              onClick={ (event: any) =>
							{
								event.stopPropagation();

								this.props.modalContext?.content(
									'Valider le devis',
									<LegalNoticeHandleEstimate
										legalNotice={ this.props.legalNotice }
										modalContext={ this.props.modalContext! }
										getComponentState={ (childState) =>
											this.legalNoticeControlsService.handleRespondQuote(childState)
										}
									/>,
								);
							} } // this.legalNoticeControlsService.onRespondQuote()
            />
					}

					<div style={ { display: 'flex', gap: 5 } }>
						{ (flags.isQuote && flags.isQuoteAnswerAccepted) &&
              <div style={ {
								display: 'inline-flex',
								padding: '2px 5px',
								alignItems: 'center',
								...borderStyles,
								borderColor: colors.success200,
								borderRadius: 6,
								gap: 5,
								height: 28,
								backgroundColor: colors.success100,
								color: colors.success600
							} }>
                <LuCheckCircle/>
                <div style={ { fontWeight: 'bold' } }>
                  Devis Accepté
                </div>
              </div>
						}

						{ (flags.isQuote && flags.isQuoteAnswerRefused) &&
              <Alert
                variant={ 'danger' }
                style={ LegalNoticeModalStyle.alertContainerStyle(true) }
              >
                <div className="d-flex align-items-center">
                  <LuInfo className="me-2"/>
                  <div style={ { fontWeight: 'bold', marginRight: 10 } }>
                    Devis refusé, votre annonce légale est bloquée.
                  </div>

                  <Button
                    label={ 'Dupliquer' }
                    variant="secondary"
                    onClick={ () => this.legalNoticeControlsService.onDuplicate() }
                  />
                </div>
              </Alert>
						}

						{ flags.isDraft ? (
							<Button
								label="Modifier l'annonce"
								variant="primary"
								onClick={ () =>
								{
									this.props.navigation(`/admin/legal-notices/create?legalNoticeId=${ this.props.legalNotice.id }`);
								} }
							/>
						) : (
							(!flags.isSupplier && !flags.isQuoteAnswerRefused && !flags.isPaid) && (
								<Button
									label="Modifier l'annonce"
									variant="primary"
									onClick={ () =>
									{
										this.props.navigation(`/admin/legal-notices/create?legalNoticeId=${ this.props.legalNotice.id }`);
									} }
								/>
							)
						) }
					</div>
				</div>
			</div>
		);
	}
}

export default withGlobalContext(LegalNoticeControls);