import React, { Component, ReactElement } from 'react';
import {
	CollaboratorAdminFormCreateProps,
	CollaboratorAdminFormCreateState
} from './CollaboratorAdminFormCreate.interface';
import { CollaboratorAdminFormCreateService } from './CollaboratorAdminFormCreate.service';
import AssociateUser from '@/Modules/User/Components/AssociateUser/AssociateUser';
import { AssociateUserState } from '@/Modules/User/Components/AssociateUser/AssociateUser.interface';
import {
	CollaboratorAdminFormCreateStyles
} from '@/Modules/Collaborator/Components/Form/Create/CollaboratorAdminFormCreate.styles';
import Radio from '@/Modules/App/Components/Atom/Form/Radio';
import Input from '@/Modules/App/Components/Library/Input/Input';
import Select from '@/Modules/App/Components/Library/Select/Select';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import AssociateSellsyContact from '@/Modules/App/Components/Sellsy/AssociateSellsyContact/AssociateSellsyContact';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

class CollaboratorAdminFormCreate extends Component<CollaboratorAdminFormCreateProps, CollaboratorAdminFormCreateState>
{
	private collaboratorAdminFormCreateService = new CollaboratorAdminFormCreateService();

	constructor(props: CollaboratorAdminFormCreateProps)
	{
		super(props);

		// Config service
		this.collaboratorAdminFormCreateService.setProps(this.props);
		this.collaboratorAdminFormCreateService.subscribeState(this);

		// State
		this.state = this.collaboratorAdminFormCreateService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.collaboratorAdminFormCreateService.init();
	}

	componentWillUnmount(): void
	{
		this.collaboratorAdminFormCreateService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { user, isUserEmail, role, collaboratorMail } = this.state;

		const generateTextHelp = (email: string) => (
			<>
				Souhaitez-vous utiliser l'email utilisateur{ ' ' }
				<span style={ { fontWeight: 500, textDecoration: 'underline' } }>
      { email }
    </span>{ ' ' }
				par défaut ? Auquel cas, vous devrez associer un email différent pour ce collaborateur.
			</>
		);

		return (
			<>
				<div style={ CollaboratorAdminFormCreateStyles.modalContainer }>
					<div style={ CollaboratorAdminFormCreateStyles.form }>
						<AssociateUser getComponentState={
							(associateUserState: AssociateUserState) =>
								this.collaboratorAdminFormCreateService.handleSelectedUser(associateUserState)
						}/>
						{ user &&
              <>
                <div>
                  <Radio
                    label={ 'Choix de l\'email' }
                    textHelp={ generateTextHelp(user.email) }
                    options={ [{ label: 'Oui', value: 'true' }, { label: 'Non', value: 'false' }] }
                    name="is-email-collaborator-owner"
                    selectedValue={ isUserEmail.toString() }
                    onSelectedOption={ (event: any) => this.collaboratorAdminFormCreateService.handleEmailUse(event) }
                    isRequired={ true }
                  />
                </div>
								{ !isUserEmail &&
                  <Input
                    type={ 'text' }
                    name={ 'collaboratorEmail' }
                    label={ 'Email collaborateur ' }
                    textHelp={ `Ajouter un email pour ${ user?.firstname }  ${ user?.lastname }. 
							Tous les éléments relatifs à ${ this.props.client.name }(email, documents, etc.) seront envoyé à cette adresse.` }
                    onChange={ (event: any) => this.collaboratorAdminFormCreateService.handleChangeCollaboratorMail(event) }
                  />
								}

                <Select
                  label={ 'Rôle du collaborateur' }
                  listOptions={ CollaboratorRoleEnum.roleOptions.filter(option => option.value !== 'ROLE_OWNER') }
                  onSelect={ (role: CollaboratorRoleEnum) => this.collaboratorAdminFormCreateService.handleCollaboratorRole(role.value) }
                  isRequired={ true }
                />
								{ (role) &&
                  <GlobalContextProvider>
                    <AssociateSellsyContact
                      client={ this.props.client }
                      formData={ {
												user,
												collaboratorMail,
												role,
											} }
                      getComponentState={ this.collaboratorAdminFormCreateService.handleExtSellsyId.bind(this) }
                    />
                  </GlobalContextProvider>
								}
              </>
						}
					</div>
				</div>
				<div style={ ModalStyles.bottomActions }>
					<Button
						variant={ 'primary' }
						label={ 'Créer' }
						onClick={ async (event) =>
						{
							await this.collaboratorAdminFormCreateService.handleCreate(event, this.state,
								() => this.props.getComponentState(this.state));
						} }
					/>
				</div>
			</>
		);
	}
}

export default withGlobalContext(CollaboratorAdminFormCreate);