import React, { Component, ReactElement } from 'react';
import { LegalNoticeDocumentsProps, LegalNoticeDocumentsState } from './LegalNoticeDocuments.interface';
import { LegalNoticeDocumentsService } from './LegalNoticeDocuments.service';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { UserService } from '@/Modules/App/Services/User/User.service';
import {
	LegalNoticeDocumentsStyles
} from '@/Modules/LegalNotice/Components/LegalNoticeDocuments/LegalNoticeDocuments.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { ModalContext } from '@/Provider/ModalProvider';
import BlockSendToComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockSendToComponent';
import CreatePaymentFormComponent from '@/Modules/Payment/Components/CreatePaymentFormComponent';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import { ApiPaymentService } from '@/Service/Api/ApiPaymentService';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Skeleton from 'react-loading-skeleton';

class LegalNoticeDocuments extends Component<LegalNoticeDocumentsProps, LegalNoticeDocumentsState>
{
	private apiLegalNoticeService = new ApiAdminLegalNoticeService();
	private legalNoticeDocumentsService = new LegalNoticeDocumentsService();
	private apiPaymentService: ApiPaymentService = new ApiPaymentService();

	constructor(props: LegalNoticeDocumentsProps)
	{
		super(props);

		// Config service
		this.legalNoticeDocumentsService.setProps(this.props);
		this.legalNoticeDocumentsService.subscribeState(this);

		// State
		this.state = this.legalNoticeDocumentsService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeDocumentsService.init(this.props);
	}

	componentWillUnmount(): void
	{
		this.legalNoticeDocumentsService.unsubscribe();
	}

	async componentDidUpdate(prevProps: LegalNoticeDocumentsProps): Promise<void>
	{
		if (prevProps.legalNotice !== this.props.legalNotice) {
			await this.legalNoticeDocumentsService.init(this.props);
		}
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}
		// Props | Styles
		const { legalNoticeFiles } = this.state;
		const { legalNotice } = this.props;
		const styles = LegalNoticeDocumentsStyles;

		// Const
		const certificateFiles = this.state.legalNoticeFiles
			.filter((file: any) => file.type !== 'QUOTE' && file.type !== 'INVOICE' && file.type !== 'CREDIT_NOTE')
		;

		const quotes = legalNoticeFiles.filter((file: any) => file.type === 'QUOTE');
		const billings = legalNoticeFiles.filter((file: any) => file.type === 'INVOICE');
		const creditNotes = legalNoticeFiles.filter((file: any) => file.type === 'CREDIT_NOTE');
		const certificate = certificateFiles.filter((file: any) => file.type === 'CERTIFICATE');
		const webCertificate = certificateFiles.filter((file: any) => file.type === 'WEB_CERTIFICATE');
		const hasCertificate = certificateFiles.some((file: any) => file.type === 'CERTIFICATE');
		const hasDigitalCertificate = certificateFiles.some((file: any) => file.type === 'WEB_CERTIFICATE');
		const isPlanned = legalNotice.status.toString() === PublishStateEnum.PLANNED.value;
		const isPublish = legalNotice.status.toString() === PublishStateEnum.PUBLISH.value;
		const isPaid = legalNotice.paymentStatus.toString() === PaymentStateEnum.PAID.value;
		const isSuperAdminOrAdmin = UserService.isAdmin() || UserService.isSuperAdmin();

		return (
			<div style={ styles.container }>
				{ hasCertificate && this.blockDocumentRender('Attestation', certificate, {
					label: 'Attestation',
					value: 'certificate'
				}, this.state.isWaitingDocuments.CERTIFICATE) }
				{ hasDigitalCertificate && this.blockDocumentRender(
					'Certificat Numérique',
					webCertificate,
					{ label: 'Certificat Numérique', value: 'certificateWeb' },
					this.state.isWaitingDocuments.CERTIFICATE,
				) }
				{ this.blockDocumentRender(
					'Factures',
					billings,
					{ label: 'Facture', value: 'invoice' },
					this.state.isWaitingDocuments.INVOICE,
				) }
				{ !this.state.isWaitingDocuments.INVOICE && (isPlanned || isPublish) && (!isPaid) && (isSuperAdminOrAdmin) && (this.blockToPaidInvoice(billings[0])) }
				{ !this.state.isWaitingDocuments.INVOICE && (isPlanned || isPublish) && (!isPaid) && (!isSuperAdminOrAdmin) && this.payedInvoiceUserSide() }
				{ this.blockDocumentRender('Devis', quotes, {
					label: 'Devis',
					value: 'estimate'
				}, this.state.isWaitingDocuments.QUOTE) }
				{ this.blockDocumentRender('Avoir', creditNotes, {
					label: 'Avoir',
					value: 'credit'
				}, this.state.isWaitingDocuments.CREDIT_NOTE) }
			</div>
		);
	}

	private blockDocumentRender(title: string, documents: any[], data: {
		label: string,
		value: string
	}, isLoadingDocument: boolean = false): ReactElement
	{
		const fieldsMap: { [key: string]: string[] } = {
			all: ['invoice', 'certificate', 'certificate-web', 'receipt', 'credit'],
			estimate: ['invoice', 'quote'],
			certificate: ['certificate'],
			certificateWeb: ['certificate-web'],
			invoice: ['invoice'],
			credit: ['credit']
		};

		const fieldsToShow = fieldsMap[data.value] || ['estimate', 'invoice', 'certificate', 'certificate-web', 'receipt', 'credit'];

		return (
			<>
				<ModalContext.Consumer>
					{ (modalContext) => (
						<div>
							<div style={ LegalNoticeDocumentsStyles.title }>
								{ title }
							</div>
							{ isLoadingDocument ? (
								<Skeleton height={ 15 } width={ 150 }/>
							) : (
								documents.length > 0 ? (
									documents.map((file: any) => (
										<div
											key={ file.name }
											style={ {
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												marginBottom: '10px'
											} }
										>
											<div style={ FontStyles.textHelp }>
												{ file.name }
											</div>
											<div style={ LegalNoticeDocumentsStyles.actions }>
												<Button
													variant={ 'document' }
													iconName={ 'LuFileOutput' }
													onClick={ () => this.legalNoticeDocumentsService.getFile(file) }
												/>
												{ (UserService.isSuperAdmin() || UserService.isAdmin()) &&
                          <Button
                            variant={ 'secondary' }
                            iconName={ 'LuSend' }
                            onClick={ (event: any) =>
														{
															event.stopPropagation();
															modalContext?.content(
																`Renvoi des documents (${ title })`,
																<>
																	<div style={ { padding: '0 15px', marginBottom: 10, width: 800 } }>
																		<GlobalContextProvider>
																			<BlockSendToComponent
																				isDisplayBlock={ true }
																				selectedClient={ this.props.legalNotice.client }
																				sendTo={ this.props.legalNotice.sendTo }
																				onSelectionMail={ this.legalNoticeDocumentsService.onSelectionMail.bind(this) }
																				fieldsToShow={ fieldsToShow }
																			/>
																		</GlobalContextProvider>
																	</div>

																	<div style={ {
																		display: 'flex',
																		marginTop: 20,
																		justifyContent: (this.state.isSendMailLoading) ? 'space-between' : 'end',
																		marginBottom: 10,
																		marginRight: 15
																	} }>
																		<Button
																			variant={ 'primary' }
																			label={ 'Renvoyer les documents' }
																			onClick={ (event: any) => this.legalNoticeDocumentsService.sendBackEmailApi(event, data, modalContext, this.state.sendBackSendTo) }
																			disabled={ false }
																		/>
																	</div>
																</>
															);
														} }
                          />
												}
											</div>
										</div>
									))
								) : (
									<div
										style={ {
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											marginBottom: '10px'
										} }
									>
										<div style={ FontStyles.textHelp }>Aucun document</div>
										<Button
											variant={ 'document' }
											iconName={ 'LuFileOutput' }
											onClick={ () => null }
											disabled={ true }
										/>
									</div>
								)
							) }
						</div>
					) }
				</ModalContext.Consumer>
			</>
		);
	}

	private async forcePaymentStatusToPublish(): Promise<void>
	{
		this.setState({ isLoading: true });
		try {
			const userConfirmed = window.confirm('Voulez-vous vraiment forcer le statut en "Payé" ?');

			if (!userConfirmed) {
				this.setState({ isLoading: false });
				return;
			}

			if (this.props.legalNotice.paymentStatus === PaymentStateEnum.PENDING.value) {
				await this.apiLegalNoticeService.forceLegalNoticePaymentStatus(
					this.props.legalNotice.id,
					PaymentStateEnum.PAID.value
				);
			}

		} catch (e) {
			console.error(`Error occurred on forcePaymentStatusToPublish()`, e);
		} finally {
			this.setState({ isLoading: false },
				() => this.props.getComponentState!(this.state)
			);
		}
	}


	private payedInvoiceUserSide(): ReactElement
	{
		return (
			<div style={ { display: 'inline-flex', width: '100%', justifyContent: 'flex-end' } }>
				<Button
					label={ 'Payer par CB' }
					variant={ 'success' }
					onClick={ () => this.onPaymentLink() }
				/>
			</div>
		);
	}

	private async onPaymentLink(): Promise<void>
	{
		try {
			const paymentLink = await this.apiPaymentService.generatePaymentLink(this.props.legalNotice.id, this.props.authContext?.user?.company.id!);

			console.log(paymentLink);
			if (paymentLink) {
				window.open(paymentLink, '_blank');
			}
		} catch (e) {
			console.error(`Error occurred on Generate Payment link ${ this.constructor.name }`);
		}
	}

	private blockToPaidInvoice(billing: any): ReactElement
	{
		return (
			<div style={ { display: 'flex', gap: 10, justifyContent: 'flex-end' } }>
				<ModalContext.Consumer>
					{ (modalContext) => (
						<div style={ { display: 'inline-flex', justifyContent: 'flex-end' } }>
							<Button
								variant={ 'small' }
								label={ 'Régler la facture' }
								onClick={ (event) =>
								{
									event.stopPropagation();
									modalContext?.content(
										`Régler la facture: ${ billing.name }`,
										<CreatePaymentFormComponent
											legalNotice={ this.props.legalNotice }
											onCreate={ this.legalNoticeDocumentsService.handleCreatePayment.bind(this) }
											modalContext={ modalContext }
											billing={ billing }
										/>
									);
								} }
							/>
						</div>
					) }
				</ModalContext.Consumer>

				<Button
					label={ 'Acquitter' }
					variant={ 'smallSuccess' }
					onClick={ () => this.forcePaymentStatusToPublish() }
				/>
			</div>
		);
	}
}

export default withGlobalContext(LegalNoticeDocuments);