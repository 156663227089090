import React, { Component, ReactElement } from 'react';
import { SellsyCompanyProps, SellsyCompanyState } from './SellsyCompany.interface';
import { SellsyCompanyService } from './SellsyCompany.service';
import LineDetailsRender from '@/Modules/App/Components/Library/LineDetailsRender/LineDetailsRender';
import { SellsyCompanyStyles } from '@/Modules/App/Components/Sellsy/SellsyCompany/SellsyCompany.styles';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { ModalContext } from '@/Provider/ModalProvider';
import AssociateSellsyCompany from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany';
import {
	AssociateSellsyCompanyState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.interface';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

class SellsyCompany extends Component<SellsyCompanyProps, SellsyCompanyState>
{
	private sellsyCompanyService = new SellsyCompanyService();

	constructor(props: SellsyCompanyProps)
	{
		super(props);

		// Config service
		this.sellsyCompanyService.setProps(this.props);
		this.sellsyCompanyService.subscribeState(this);

		// State
		this.state = this.sellsyCompanyService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.sellsyCompanyService.init();
	}

	componentWillUnmount(): void
	{
		this.sellsyCompanyService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { sellsyDetails, isLoading } = this.state;
		if (isLoading) {
			return <LoaderComponent/>;
		}

		const colorLine = { colorInfo: colors.purple400, colorData: colors.purple600 };
		return (
			<ModalContext.Consumer>
				{ (modalContext) => (
					<div style={ SellsyCompanyStyles.container }>
						{ sellsyDetails.id ? (
							<>
								<LineDetailsRender
									info={ 'Id Sellsy' }
									formData={ sellsyDetails.id }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Nom' }
									formData={ sellsyDetails.name }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Code Auxiliaire' }
									formData={ sellsyDetails.auxiliary_code }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Email' }
									formData={ sellsyDetails.email }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Numéro de téléphone' }
									formData={ sellsyDetails.phone_number }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Adresse de facturation et de livraison' }
									formData={ sellsyDetails.delivery_address?.address_line_1! }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Ville' }
									formData={ sellsyDetails.delivery_address?.city! }
									textColor={ colorLine }
								/>
								<LineDetailsRender
									info={ 'Code postal' }
									formData={ sellsyDetails.delivery_address?.postal_code! }
									textColor={ colorLine }
								/>
								<div style={ { width: '100%', display: 'flex', justifyContent: 'flex-end' } }>
									<Button
										label={ 'Modifier' }
										variant={ 'sellsy' }
										onClick={ (event: any) =>
											{
												event.stopPropagation();
												modalContext?.content(
													'Associé un compte Sellsy',
													<GlobalContextProvider>
														<div style={ SellsyCompanyStyles.modal }>
															<AssociateSellsyCompany
																getComponentState={
																	(associateState: AssociateSellsyCompanyState) =>
																		this.sellsyCompanyService.handleAssociateSellsy(associateState, modalContext)
																}
															/>
														</div>
													</GlobalContextProvider>
												);
											}
										}
									/>
								</div>
							</>
						) : (
							<div style={ SellsyCompanyStyles.notFound }>
								<div style={ SellsyCompanyStyles.notFound_title }>
									Ce client n'est associé à aucun compte Sellsy.
								</div>
								<div style={ SellsyCompanyStyles.notFound_actions }>
									<Button
										label={ 'Associer' }
										variant={ 'sellsy' }
										onClick={ (event: any) =>
										{
											event.stopPropagation();
											modalContext?.content(
												'Associé un compte Sellsy',
												<GlobalContextProvider>
													<div style={ SellsyCompanyStyles.modal }>
														<AssociateSellsyCompany
															getComponentState={
																(associateState: AssociateSellsyCompanyState) =>
																	this.sellsyCompanyService.handleAssociateSellsy(associateState, modalContext)
															}
														/>
													</div>
												</GlobalContextProvider>
											);
										}
										}
									/>
								</div>
							</div>
						) }
					</div>
				) }
			</ModalContext.Consumer>
		);
	}
}

export default withGlobalContext(SellsyCompany);