import React, { Component, ReactElement } from 'react';
import { UsersAdminService } from './UsersAdmin.service';
import { UsersAdminProps, UsersAdminState } from './UsersAdmin.interface';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import CreateUserForm from '@/Modules/User/Components/Form/Create/CreateUserForm';
import ListTableManager from '@/Modules/App/Components/ListTableManager/ListTableManager';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';
import { ModalContextType } from '@/Provider/ModalProvider';
import { OffcanvaContext } from '@/Provider/OffcanvaProvider';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import UserAdmin from '@/Modules/User/Components/Show/UserAdmin';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';

class UsersAdmin extends Component<UsersAdminProps, UsersAdminState>
{
	apiUserAdminService = new ApiAdminUserService();
	private usersAdminService: UsersAdminService = new UsersAdminService();
	private createUserModal: ModalContextType;

	constructor(props: UsersAdminProps)
	{
		super(props);

		// Config service
		this.usersAdminService.setProps(this.props);
		this.usersAdminService.subscribeState(this);

		// State & props
		this.state = this.usersAdminService.getState();
		this.createUserModal = this.props.modalContext!;

		// Bind
		this.createUserModalRender = this.createUserModalRender.bind(this);

		// Document title
		document.title = 'Publisur - ADMIN - Users';
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	componentWillUnmount(): void
	{
		this.usersAdminService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				<HeroSection
					title="Utilisateurs"
					icon={ null }
					button={{
						label: 'Ajouter un utilisateur',
						variant: 'primary',
						iconName: 'LuPlus',
						onClick: this.createUserModalRender
					}}
				/>

				<OffcanvaContext.Consumer>
					{ (offcanvaContext) => (
						<ListTableManager
							isTableControls={ true }
							refreshList={ this.state.userList }
							tag={ 'user' }
							tableColumns={ this.usersAdminService.buildColumnHeader() }
							serviceList={ this.apiUserAdminService.list.bind(this.apiUserAdminService) }
							filters={ this.usersAdminService.buildFilters() }
							onRowClick={ (event: any, item: UserInterface) => {
								event.stopPropagation();

								offcanvaContext?.content(
									null,
									<GlobalContextProvider>
										<UserAdmin
											user={ item }
											getComponentState={ () => this.usersAdminService.refresh() }
										/>
									</GlobalContextProvider>
								);

								offcanvaContext?.isOpen(true);
							}}
						/>
					)}
				</OffcanvaContext.Consumer>
			</>
		);
	}

	private createUserModalRender(event: any): void
	{
		event.stopPropagation();

		this.createUserModal.content(
			'Créer un utilisateur',
			<CreateUserForm
				key={ this.state.errorMessage }
				isAdmin={ true }
				onFormSubmit={ this.usersAdminService.create }
				errorMessage={ this.state.errorMessage! }
				allowedUserRole={ true }
			/>
		);
	}
}

export default withGlobalContext(UsersAdmin);