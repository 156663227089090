// utils/fileUtils.ts
import LocalStorageService from '@/Service/Common/LocalStorageService';

export async function openPdfFile(baseApiUrl: string, legalNoticeId: number, file: any, isExtern: boolean): Promise<void>
{
  if (isExtern) {
    window.open(file.file, '_blank');
  } else {
    const token = LocalStorageService.getToken();
    if (!token) {
      console.error('No authentication token found.');
      return;
    }

    const url = `${baseApiUrl}/app/legal-notice-files/${legalNoticeId}/${file.type}?token=${encodeURIComponent(token)}`;
    window.open(url, '_blank');
  }
}