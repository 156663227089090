import { CSSProperties } from 'react';
import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';
import { boxShadows } from '@/Modules/App/Style/Variables/Variables.styles';

export const TableControlsStyles = {
	container: {
		width: '100%',
		backgroundColor: themeColors.background,
		minHeight: 40,
		paddingBottom: 12,
		height: 'auto',
		display: 'flex',
		alignItems: 'center',
		gap: 30,
	} as CSSProperties,

	research: {
		width: 400
	} as CSSProperties,

	filters: {
		display: 'flex',
		alignItems: 'center',
		gap: 10
	} as CSSProperties,

	filterTitle: {
		fontWeight: 600,
		marginTop: 0,
		marginBottom: 10
	} as CSSProperties,

	dateFilters: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10
	} as CSSProperties,

	isFilterOpen: (isOpen: boolean) => ({
		display: (isOpen) ? 'block' : 'none',
		position: 'absolute',
		zIndex: 10000,
		marginTop: 5,
		minWidth: '400px',
		...boxShadows.main
	}) as CSSProperties
} as const;