export function stripHtmlTags(html: string): string
{
  if (!html) return "";

  // Replace block-level tags with a space
  let text = html.replace(/<\/?(p|div|br|h[1-6]|li|ul|ol|table|tr|td|th)[^>]*>/gi, " ");
  // Remove all other HTML tags
  text = text.replace(/<\/?[^>]+(>|$)/g, "");
  // Replace non-breaking spaces with regular spaces
  text = text.replace(/\u00A0/g, " ");
  // Replace line breaks with a single space
  text = text.replace(/(\r\n|\n|\r)/gm, " ");
  // Normalize multiple spaces into a single space
  text = text.replace(/\s+/g, " ");
  // Trim leading and trailing spaces
  text = text.trim();

  return text;
}
