import React, { ReactElement } from 'react';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';
import SirenInputComponent from '@/Modules/App/Components/Atom/Form/SirenInputComponent';
import ConsumerFormComponent from '@/Modules/LegalNotice/Components/Form/Admin/Consumer/ConsumerFormComponent';
import ConsumerAddressFormComponent
	from '@/Modules/LegalNotice/Components/Form/Admin/Consumer/ConsumerAddressFormComponent';
import { handleChangeInput } from '@/Utils/InputFormUtils';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

interface ComponentProps
{
	isDisplayBlock: boolean,
	consumer: ConsumerDataInterface | null,
	category: FormBuilderCategoryInterface | null,
	onFormsComplete: (isComplete: boolean, formData: any) => void
}

interface ComponentState
{
	formData: {
		siren: string | null,
		name: string | null,
		capital: string | null,
		legalStatus: string | null,
		rcs: string | null,
		extSellsyId: number | null,
		address: AddressInterface
	}
}

export default class BlockConsumerComponent extends React.Component<ComponentProps, ComponentState>
{
	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		if (this.props.consumer) {
			this.checkFormsCompletion();
		}
	}

	render(): ReactElement
	{
		const shouldDisplaySiren = Boolean(
			this.props.category?.categoryType &&
			!LegalNoticeTypeEnum.filterWithoutSiren.some(
				category => category.value === this.props.category?.categoryType
			)
		);

		return (
			<>
				{ this.props.isDisplayBlock &&
          <SectionCollapsed title={ 'Socièté (client final)'} isCollapsed={ false } highlightColor={ colors.gray100 }>
						{ shouldDisplaySiren && this.props.category?.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value &&
							<div style={{ marginBottom: 20 }}>
								<SirenInputComponent
									sirenData={ this.initStateFromSirenResponse.bind(this) }
									sirenValue={ this.state.formData.siren || '' }
									isInseeApi={ false }
								/>
							</div>
						}

            <ConsumerFormComponent
              formData={ this.state.formData }
              category={ this.props.category }
							isSirenNeeded={ shouldDisplaySiren }
              onChange={ (event: any) => handleChangeInput(event, this.setState.bind(this)) }
            />

            <ConsumerAddressFormComponent
              isTitleNeeded={ true }
              formData={ this.state.formData }
              onChange={ (event: any) => handleChangeInput(event, this.setState.bind(this)) }
              isConsumerFormComplete={ this.isConsumerFormComplete() }
            />
          </SectionCollapsed>
				}
			</>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState): void
	{
		if (prevState.formData !== this.state.formData) {
			this.checkFormsCompletion();
		}
	}

	private initState(): ComponentState
	{
		return {
			formData: {
				siren: this.props.consumer?.siren ?? '',
				name: this.props.consumer?.name ?? '',
				capital: this.props.consumer?.capital ?? '',
				legalStatus: this.props.consumer?.legalStatus ?? '',
				rcs: this.props.consumer?.rcs ?? '',
				extSellsyId: this.props.consumer?.extSellsyId ?? null,
				address: {
					name: this.props.consumer?.address.name ?? '',
					street: this.props.consumer?.address.street ?? '',
					number: this.props.consumer?.address.number ?? null,
					additionalData: this.props.consumer?.address.additionalData ?? '',
					zipCode: this.props.consumer?.address.zipCode ?? '',
					city: this.props.consumer?.address.city ?? '',
					country: 'FR',
					phone: null,
					extSellsyId: this.props.consumer?.address.extSellsyId ?? null,
					isBillingAddress: true
				}
			}
		};
	}

	private initStateFromSirenResponse(sirenResponse: any): void
	{
		this.setState({
			formData: {
				siren: sirenResponse.siren || '',
				name: sirenResponse.name || '',
				capital: sirenResponse.capital || '',
				legalStatus: sirenResponse.legalStatus || '',
				rcs: this.state.formData.rcs,
				extSellsyId: this.state.formData.extSellsyId,
				address: {
					name: null,
					street: sirenResponse.address.street || '',
					number: sirenResponse.address.number || '',
					phone: null,
					additionalData: sirenResponse.address.additionalData || '',
					zipCode: sirenResponse.address.zipCode || '',
					city: sirenResponse.address.city || '',
					country: sirenResponse.address.country || '',
					extSellsyId: this.props.consumer?.address.extSellsyId ?? null,
					isBillingAddress: true
				}
			}
		});
	}

	private isConsumerFormComplete(): boolean
	{
		const { formData } = this.state;
		const { siren, name, capital, legalStatus, rcs } = formData;
		const { category } = this.props;

		// Category `CONSTITUTION`
		if (category?.categoryType === LegalNoticeTypeEnum.CONSTITUTION.value) {
			return Boolean(name) && Boolean(capital) && Boolean(legalStatus) && Boolean(rcs);
		}

		const isSirenNeeded = Boolean(
			this.props.category?.categoryType &&
			!LegalNoticeTypeEnum.filterWithoutSiren.some(
				category => category.value === this.props.category?.categoryType
			)
		);

		// Other Categories
		if (isSirenNeeded) {
			return Boolean(siren) && Boolean(name) && Boolean(capital) && Boolean(legalStatus) && Boolean(rcs);
		}

		// If no siren required
		return Boolean(name);
	}

	private isConsumerFormAddressComplete(): boolean
	{
		const { formData } = this.state;
		const { address } = formData;

		return (
			Boolean(address.street) &&
			Boolean(address.zipCode) &&
			Boolean(address.city)
		);
	}

	private checkFormsCompletion(): void
	{
		const isComplete = this.isConsumerFormComplete() && this.isConsumerFormAddressComplete();
		this.props.onFormsComplete(isComplete, this.state);
	}

	//</editor-fold>
}
