import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';

export class LegalNoticeHelper {

	/**
	 * Prepare form data
	 */
	static prepareLegalNoticeForEdit(
		legalNotice: LegalNoticeInterface,
		legalNoticeUpdatedData: Partial<LegalNoticeInterface>,
		nbCharacters?: number,
	): any
	{
		return {
			departmentId: legalNotice.publishDepartment?.id,
			newspaperId: legalNotice.newspaper?.id,
			clientId: legalNotice.client?.id,
			collaboratorId: legalNotice.collaborator?.id,
			categoryId: legalNotice.formBuilderCategory?.id,
			legalNotice: {
				id: legalNotice.id,
				tag: legalNotice.tag,
				title: (legalNoticeUpdatedData.title) ? legalNoticeUpdatedData.title :legalNotice.title,
				content: (legalNoticeUpdatedData.content) ? legalNoticeUpdatedData.content : legalNotice.content,
				signature: (legalNoticeUpdatedData.signature) ? legalNoticeUpdatedData.signature : legalNotice.signature,
				isForcePublishDate: false,
				logo: (legalNoticeUpdatedData.logo) ? legalNoticeUpdatedData.logo : legalNotice.logo,
				publishDate: (legalNoticeUpdatedData.publishDate) ? legalNoticeUpdatedData.publishDate : legalNotice.publishDate,
				price: (legalNoticeUpdatedData.price) ? legalNoticeUpdatedData.price : legalNotice.price,
				numberOfCopies: (legalNoticeUpdatedData.numberOfCopies) ? legalNoticeUpdatedData.numberOfCopies : legalNotice.numberOfCopies,
				reference: (legalNoticeUpdatedData.reference) ? legalNoticeUpdatedData.reference : legalNotice.reference,
				nbCharacters: nbCharacters,
				status: (legalNoticeUpdatedData.status) ? legalNoticeUpdatedData.status.value : legalNotice.status,
				paymentStatus: (legalNoticeUpdatedData.paymentStatus) ? legalNoticeUpdatedData.paymentStatus : legalNotice.paymentStatus,
				quoteStatus: (legalNoticeUpdatedData.quoteStatus) ? legalNoticeUpdatedData.quoteStatus : legalNotice.quoteStatus,
				discount: (legalNoticeUpdatedData.discount) ? legalNoticeUpdatedData.discount : legalNotice.discount,
				configBillingPreference: (legalNoticeUpdatedData.configBillingPreference) ? legalNoticeUpdatedData.configBillingPreference : legalNotice.configBillingPreference,
				option: (legalNoticeUpdatedData.option) ? legalNoticeUpdatedData.option : legalNotice.option,
				extSupplierUid: (legalNoticeUpdatedData.extSupplierUid) ? legalNoticeUpdatedData.extSupplierUid : legalNotice.extSupplierUid,
			},
			billingAddress: legalNotice.billingAddress,
			sendTo: legalNotice.sendTo,
			consumer: legalNotice.consumer,
			status: legalNotice.status,
		};
	}
}