import React, { ReactElement } from 'react';
import { AppConfigAdminProps, AppConfigAdminState } from '@/Modules/Config/Admin/AppConfigAdmin.interface';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import { AppConfigAdminStyles } from '@/Modules/Config/Admin/AppConfigAdmin.styles';
import { AppConfigAdminService } from '@/Modules/Config/Admin/AppConfigAdmin.service';
import Card from '@/Modules/App/Components/Atom/Card/Card';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import { SupplierInterface } from '@/Modules/Supplier/Interface/SupplierInterface';
import { Alert } from 'react-bootstrap';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import Select from '@/Modules/App/Components/Library/Select/Select';
import { OptionFilterInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';

export default class AppConfigAdmin extends React.Component<AppConfigAdminProps, AppConfigAdminState>
{
  private appConfigAdminService: AppConfigAdminService = new AppConfigAdminService();

  constructor(props: any)
  {
    super(props);

    // Config Service
    this.appConfigAdminService.setProps(this.props);
    this.appConfigAdminService.subscribeState(this);

    // State
    this.state = this.appConfigAdminService.getState();

  }

  //<editor-fold desc="Effects methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    await this.appConfigAdminService.init();
  }

  componentWillUnmount(): void
  {
    this.appConfigAdminService.unsubscribe();
  }

  //</editor-fold>

  render(): ReactElement
  {
    if (this.state.isLoading) {
      return <LoaderFullPage />;
    }

    return (
      <>
        <div style={ AppConfigAdminStyles.container }>
          <Title type={ 'h4' } style={{ marginBottom: 0 }}>Gestion des Fournisseurs</Title>
          <Card
            title={ '' }
            text={ '' }
            renderContent={() => this.supplierSettingsRender() }
          />

          { this.state.isEdit &&
            <>
              <div style={ AppConfigAdminStyles.cardContainer }>
                <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                  <Title type={ 'h6' } style={ { marginTop: 0 } }>Changer de Fournisseurs</Title>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <Button
                      label={ 'Annuler' }
                      variant={ 'warning' }
                      onClick={ (event: any) => {
                        event.preventDefault();
                        event.stopPropagation();

                        this.setState({ isEdit: false });
                      }}
                    />

                    <Button
                      label={ 'Sauvegarder' }
                      variant={ 'primary' }
                      onClick={ (event: any) => {
                        event.preventDefault();
                        event.stopPropagation();

                        this.appConfigAdminService.edit(this.state);
                      }}
                      disabled={ Boolean(!this.state.selectedSupplier) }
                    />
                  </div>
                </div>

                <Select
                  label={ 'Choisir le nouveau fournisser' }
                  textHelp={ 'ATTENTION: Le changement prendra effet sur les nouvelles annonces légales' }
                  text={ 'Choisir un nouveau fournisseur' }
                  listOptions={
                    this.state.supplierList.map((supplier: SupplierInterface) => ({
                      label: `${ supplier.name }`,
                      tag: supplier.tag,
                      id: supplier.id
                    })) as OptionFilterInterface[]
                  }
                  isRequired={ false }
                  onSelect={ (supplier: SupplierInterface) => {
                    this.appConfigAdminService.onSelectedSupplier(supplier)
                  }}
                />
              </div>
            </>
          }
        </div>
      </>
    );
  }

  private supplierSettingsRender(): ReactElement
  {
    if (!this.props.company) {
      return (<>Il y a un probléme sur l'entreprise !</>);
    }

    const supplier: SupplierInterface = this.props.company.supplier;

    return (
      <>
        <Title type={ 'h6' } style={{ marginTop: 0 }}>Fournisseurs courrant</Title>
        <div style={ { display: 'flex', justifyContent: 'space-between' } }>
          <div style={{ display: 'flex', gap: 20 }}>
            <div>{ (supplier) ? supplier.name : 'Aucun Fournisseur en cours' }</div>
            { supplier &&
              <Tag value={ supplier.status } enumName={ 'ActiveStatusEnum' } />
            }
          </div>

          { supplier &&
            <Alert variant={'info'} style={{ marginBottom: 0, padding: 3 }}>
              { (supplier.isConnected)
                ? 'Le Fournisseur posséde un connecteur et il est opérationnel'
                : 'Le Fournisseur ne posséde de pas connecteur'
              }
            </Alert>
          }

        </div>
        <div style={{ marginTop: 20 }}>
          <Button
            label={ (supplier) ? 'Voulez-vous changer de fournisseur ?' : 'Merci de choisir un fournisseur' }
            iconName={ 'LuTruck' }
            onClick={ (event: any) => {
              event.preventDefault();
              event.stopPropagation();

              this.setState({ isEdit: true });
            }}
          />
        </div>
      </>
    );
  }
}