import { CSSProperties } from 'react';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';

export const SelectMultipleStyles = {
	container: {
		minHeight: 32,
		width: '100%',
		...borderStyles,
		borderRadius: 6,
		padding: 3,
		cursor: 'text'
	} as CSSProperties,
} as const;