import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	AssociateSellsyContactFormData,
	AssociateSellsyContactProps,
	AssociateSellsyContactState
} from './AssociateSellsyContact.interface';
import { ContactSellsyInterface } from '@/Modules/Client/Interface/ContactSellsyInterface';
import { SearchSellsyInterface } from '@/Modules/Client/Interface/SearchSellsyInterface';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import LocalStorageService from '@/Service/Common/LocalStorageService';

const initState: AssociateSellsyContactState = {
	isLoading: true,
	sellsyData: {} as SearchSellsyInterface,
	isSellsyDataLoading: true,
	collaboratorExistingExtSellsyIds: [] as string[],
	contactSellsySelected: null,
};

export class AssociateSellsyContactService extends BaseComponentService<AssociateSellsyContactProps, AssociateSellsyContactState>
{
	private sellsyClientService: ApiAdminSellsyService = new ApiAdminSellsyService();
	private debounceTimeout: NodeJS.Timeout | null = null;

	constructor()
	{
		super({} as AssociateSellsyContactProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		try {
			await this.refresh(this.props);
		} catch (error) {
			console.error('Error during initialization in AssociateSellsyContactService:', error);
		} finally {
			this.setState({ isLoading: false });
		}
	}

	async refresh(props: AssociateSellsyContactProps): Promise<void>
	{
		try {

			if (props.authCompanyContext && props.client) {
				// Filter ext sellsy ids
				const existingExtSellsyIds: string[] = this.getExistingCollaboratorExtSellsyIds(props);

				// Get data sellsy contact
				const listSellsyContact = await this.sellsyClientService.listCompanyContact(
					LocalStorageService.getAuthCompanyId(),
					parseInt(props.client.extSellsyId as string)
				);

				this.setState({
					sellsyData: listSellsyContact,
					isSellsyDataLoading: false,
					collaboratorExistingExtSellsyIds: existingExtSellsyIds,
				});
			}
		} catch (error) {
			console.error('Error in refresh method in AssociateSellsyContactService:', error);
		} finally {
			this.setState({ isLoading: false });
		}
	}

	/**
	 * Filters Sellsy contacts to exclude those already associated with collaborators' extSellsyId.
	 * @returns {ContactSellsyInterface[]} - A filtered list of Sellsy contacts.
	 */
	filterSellsyContacts(): ContactSellsyInterface[]
	{
		// Ensure that sellsyData.data is an array and each item has an 'id' property
		if (Array.isArray(this.state.sellsyData.data) && this.state.sellsyData.data.every(item => 'id' in item)) {
			// Convert collaborator.extSellsyId to a string array for easier comparison
			const existingExtSellsyIds = this.props.client.collaborators
				.map((collaborator: any) => collaborator.extSellsyId?.toString())
				.filter((id: string | undefined) => id !== undefined);

			// Filter out contacts whose ID is already in existingExtSellsyIds
			return (this.state.sellsyData.data as ContactSellsyInterface[]).filter(
				(contact: ContactSellsyInterface) => !existingExtSellsyIds.includes(contact.id.toString())
			);
		}

		// Return an empty list if sellsyData.data is not in the correct format
		return [];
	}


	/**
	 * Handle Selected Sellsy Contact
	 * @param contact
	 * @param event
	 */
	handleContactSellsySelect(contact: ContactSellsyInterface, event: React.MouseEvent<any>): any
	{
		this.setState({
			contactSellsySelected: contact
		}, () => this.props.getComponentState(this.state, event));
	}

	/**
	 * Handle Cancel Selected Contact
	 * @return void
	 */
	handleCancel(): void
	{
		this.setState({
			contactSellsySelected: null,
		}, () => this.props.getComponentState(this.state));
	}

	async handleCreateNewSellsyContact(formData: AssociateSellsyContactFormData): Promise<void>
	{
		this.setState({ isLoading: true });

		const newContact = await this.sellsyClientService.createContact(
			LocalStorageService.getAuthCompanyId(),
			{
				civility: (formData.user.civility === 'M')
					? 'mr'
					: 'mrs'
				,
				first_name: formData.user.firstname,
				last_name: formData.user.lastname,
				email: (formData.collaboratorMail === '')
					? formData.user.email
					: formData.collaboratorMail
				,
				phone_number: formData.user.phone,
				position: formData.role,
				note: 'New Collaborator from Publisur.dev'
			}
		);

		if (this.props.client && newContact) {

			const linkContact = await this.sellsyClientService.linkContactToCompany(
				LocalStorageService.getAuthCompanyId(),
				parseInt(this.props.client.extSellsyId),
				newContact.id,
				false
			);

			if (linkContact.httpStatus === 201) {
				this.setState({
					contactSellsySelected: newContact
				}, () =>
				{
					this.setState({ isLoading: false });
					this.props.getComponentState(this.state);
				});

				// Set Flash message
				this.props.flashMessageContext?.flashMessage(
					'SELLSY - Contact créé avec succès',
					`Votre nouveau contact a bien été créé et associé à Sellsy`,
					'success'
				);
			}
		}
	}

	private getExistingCollaboratorExtSellsyIds(props: AssociateSellsyContactProps): string[]
	{
		if (props.client && props.client.collaborators) {
			return props.client.collaborators
				.map((collaborator: any) => collaborator.extSellsyId)
				.filter((id: string | null) => id !== null)
				.map((id: string | null) => id!.toString());
		}

		return [];
	}

	isEmailInFilteredContacts(props: AssociateSellsyContactProps): boolean
	{
		const formData: AssociateSellsyContactFormData = props.formData;
		const filteredContacts: ContactSellsyInterface[] = this.filterSellsyContacts();
		const emails: (string | undefined)[] = filteredContacts.map(contact => contact.email?.toLowerCase());

		const userEmail: string = (formData.user.email) ? formData.user.email.toLowerCase() : formData.collaboratorMail.toLowerCase();
		const collaboratorEmail: string = formData.collaboratorMail.toLowerCase();

		if (this.props.formData.collaboratorMail) {
			return emails.includes(collaboratorEmail);
		} else {
			return emails.includes(userEmail);
		}
	}
}