import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	CollaboratorShowProps,
	CollaboratorShowState
} from '@/Modules/Collaborator/Admin/Show/CollaboratorShow.interface';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import { ApiAdminCollaboratorService } from '@/Service/Admin/ApiAdminCollaboratorService';
import {
	AssociateSellsyContactState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyContact/AssociateSellsyContact.interface';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';

const initState: CollaboratorShowState = {
	isLoading: true,
	isEditMode: false,
	formData: {
		email: '',
		status: null,
		role: undefined,
		extSellsyId: null,
	}
};

export class CollaboratorShowService extends BaseComponentService<CollaboratorShowProps, CollaboratorShowState>
{
	private apiAdminCollaboratorService: ApiAdminCollaboratorService = new ApiAdminCollaboratorService();

	constructor()
	{
		super({} as CollaboratorShowProps, initState);

		// Bind
		this.handleEditSellsyId = this.handleEditSellsyId.bind(this);
	}

	init(): void
	{
		this.prepareFormData(this.props.collaborator);
	}

	/**
	 * Submit form data to update Collaborator
	 * @param currentState
	 * @return void
	 * @public
	 */
	async onUpdate(currentState: CollaboratorShowState): Promise<void>
	{
		this.props.modalContext?.isLoadingOverlay(true);

		try {
			await this.apiAdminCollaboratorService.edit(currentState.formData, this.props.client?.id!, this.props.collaborator.id);
			this.props.getComponentState(currentState);

			this.prepareFormData(currentState.formData);
			this.props.modalContext?.isOpen(false);

			// FlashMessage
			this.props.flashMessageContext?.flashMessage(
				'Mise à jour',
				'Votre collaborateur a été mis à jour avec succès',
					'success'
			);

		} catch (error) {
			console.error('Error occurred on update Collaborator');
			// FlashMessage
			this.props.flashMessageContext?.flashMessage(
				'Erreur',
				'Une erreur s’est produite lors de la mise à jour de votre collaborateur',
				'error'
			);
		}
	}

	/**
	 * Handle Status collaborator
	 * @param status
	 * @param currentState
	 * @public
	 */
	async handleStatus(status: string, currentState: CollaboratorShowState): Promise<void>
	{
		try {
			// Init var
			const newStatus: string = status === ActiveStatusEnum.ACTIVE.value ? ActiveStatusEnum.INACTIVE.value : ActiveStatusEnum.ACTIVE.value;
			if (newStatus === ActiveStatusEnum.INACTIVE.value) {
				await this.apiAdminCollaboratorService.delete(this.props.client?.id!, this.props.collaborator?.id!);
			} else {
				await this.apiAdminCollaboratorService.edit({ status: newStatus }, this.props.client?.id!, this.props.collaborator?.id!);
			}

			this.setState(prevState => ({
				formData: {
					...prevState.formData,
					status: ActiveStatusEnum.findByValue(newStatus) || null
				}
			}), () => this.props.getComponentState(currentState));

			// FlashMessage
			this.props.flashMessageContext?.flashMessage(
				'Mise à jour',
				`Votre collaborateur ${ this.props.collaborator.user.lastname } ${ this.props.collaborator.user.firstname } a été mis à jour avec succès`,
					'success'
			);

		} catch (e) {
			console.error(`Error occurred on ${this.constructor.name}`, e);
		}
	}

	/**
	 * Handle updated Role
	 * @param role
	 */
	handleRole(role: CollaboratorRoleEnum): void
	{
		const updateRole = role.value;
		this.setState({
			isEditMode: true,
			formData: {
				...this.state.formData,
				role: updateRole
			}
		});
	}

	/**
	 * Handle Edit Sellsy Id
	 * @param state
	 */
	handleEditSellsyId(state: AssociateSellsyContactState): void
	{
		this.setState({
			isEditMode: true,
			formData: {
				...this.state.formData,
				extSellsyId: state.contactSellsySelected?.id as number,
			}
		});
	}

	/**
	 * Prepare data to update collaborator
	 * @private
	 */
	private prepareFormData(formData: any): void
	{
		this.setState({
			formData: {
				email: formData.email,
				status: formData.status,
				role: formData.role,
				extSellsyId: formData.extSellsyId
			}
		}, () =>
		{
			this.props.modalContext?.isLoadingOverlay(false);
		});
	}
}