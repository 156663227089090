import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  ClientLegalNoticeStatsProps, ClientLegalNoticeStatsState
} from '@/Modules/Client/Components/ClientLegalNoticeStats/ClientLegalNoticeStats.interface';
import { ApiClientLegalNoticeStatsService } from '@/Service/Api/ApiClientLegalNoticeStatsService';
import { TableHeadColumnsInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';

const initState: ClientLegalNoticeStatsState = {
  list: {} as PaginateInterface,
  isLoading: true
}

export class ClientLegalNoticeStatsService extends BaseComponentService<ClientLegalNoticeStatsProps, ClientLegalNoticeStatsState>
{
  private apiAdminClientLegalNoticeStats: ApiClientLegalNoticeStatsService = new ApiClientLegalNoticeStatsService();

  constructor()
  {
    super({} as ClientLegalNoticeStatsProps, initState);
  }

  async init(): Promise<any>
  {
    await this.formatList();
  }

  async formatList(): Promise<void>
  {
    const getList = await this.list();
    this.setState({
      list: {
        items: (getList) ? getList : [],
        itemsPerPage: 10,
        totalCount: getList ? getList.length : 0,
        totalPages: 1,
      }
    }, () => { this.setState({ isLoading: false }) });
  }

  async list(): Promise<any>
  {
    try {
      return await this.apiAdminClientLegalNoticeStats.list(this.props.client.id);
    } catch (error) {
      console.error('[CLIENT-LEGAL-NOTICE-STATS] error on get List', error);
      return [];
    }
  }

  async onCreateStat(): Promise<void>
  {
    this.setState({ isLoading: true });
    this.props.modalContext?.isLoadingFull(true);

    setTimeout(async () => {
      this.props.modalContext?.isOpen(false);
      await this.init();
      this.props.flashMessageContext?.flashMessage(
        'Mise à jour réussie',
        'Nouvelles statistique ajoutée',
      );
    }, 1000);
  }

  async onEditStat(): Promise<void>
  {
    this.setState({ isLoading: true });
    this.props.modalContext?.isLoadingFull(true);

    setTimeout(async () => {
      this.props.modalContext?.isOpen(false);
      await this.init();
      this.props.flashMessageContext?.flashMessage(
        'Mise à jour réussie',
        'Cette statistique a été modifiée',
      );
    }, 1000);
  }

  buildColumnHeader(): TableHeadColumnsInterface[]
  {
    return [
      {
        title: 'Année',
        tag: 'year',
        columnWidth: 100,
        textAlign: 'left',
        fontWeight: 600,
        keyType: 'string',
        keyTag: 'year'
      },
      {
        title: 'Interne',
        tag: 'intern',
        columnWidth: 100,
        textAlign: 'left',
        fontWeight: 600,
        keyType: 'string',
        keyTag: 'intern'
      },
      {
        title: 'Externe',
        tag: 'extern',
        columnWidth: 100,
        textAlign: 'left',
        fontWeight: 600,
        keyType: 'string',
        keyTag: 'extern'
      },
    ];
  }
}