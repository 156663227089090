import React, { ReactElement } from 'react';
import LoaderFullPage from '@/Modules/App/Components/Loader/LoaderFullPage/LoaderFullPage';
import LoaderSwitchContext from '@/Modules/App/Components/Loader/LoaderSwitchContext/LoaderSwitchContext';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { GlobalContextInterface } from '@/Context/Global/Global.interface';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import {
	ExtractedLegalNoticeDataInterface,
	ViewState,
	ViewStateFormData
} from '@/Modules/LegalNotice/Admin/Create/CreateLegalNoticeView';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { BilledToTypeEnum } from '@/Modules/Client/Enum/BilledToTypeEnum';
import { CreateLegalNoticeStyle } from '@/Modules/LegalNotice/Style/CreateLegalNoticeStyle';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';
import { SendToSelections } from '@/Modules/LegalNotice/Components/Form/LegalNoticeSendToFormComponent';
import { ContentState, convertFromRaw } from 'draft-js';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import LegalNoticeFileTypeEnum from '@/Enum/LegalNoticeFileTypeEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import { stripHtmlTags } from '@/Utils/StripHtmlTags';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { LegalNoticeService } from '@/Service/LegalNoticeService';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import { ApiCollaboratorService } from '@/Service/Api/ApiCollaboratorService';
import { ApiLegalNoticeService } from '@/Service/Api/ApiLegalNoticeService';
import { ApiAppService } from '@/Service/Api/ApiAppService';
import { ApiLegalNoticeFileService } from '@/Service/Api/ApiLegalNoticeFileService';
import BlockConfigComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockConfigComponent';
import BlockCategoryComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockCategoryComponent';
import { UserService } from '@/Service/UserService';
import BlockFreeWriting from '@/Modules/LegalNotice/Components/Form/Block/BlockFreeWriting/BlockFreeWriting';
import {
	BlockFreeWritingState
} from '@/Modules/LegalNotice/Components/Form/Block/BlockFreeWriting/BlockFreeWriting.interface';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import LegalNoticePriceFormComponent from '@/Modules/LegalNotice/Components/Form/LegalNoticePriceFormComponent';
import HeaderOptionContentComponent from '@/Modules/LegalNotice/Components/Form/Content/HeaderOptionContentComponent';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import LegalNoticeRender from '@/Modules/LegalNotice/Components/Render/LegalNoticeRender';
import BlockSendToComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockSendToComponent';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';
import Swal from 'sweetalert2';
import FormBuilderStyle from '@/Modules/App/Style/Components/FormBuilderStyle';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import BlockConsumerComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockConsumerComponent';
import { LegalNoticeUserModal } from '@/Modules/LegalNotice/Components/LegalNoticeUserModal';

interface CreateLegalNoticeFreeWritingProps extends GlobalContextInterface
{
}

interface CreateLegalNoticeFreeWritingState
{
	isDataLoading: boolean,
	isCreateLoading: boolean,
	errorMessage: string | null,
	departmentList: DepartmentInterface[],
	newspaperList: NewspaperInterface[],
	formBuilderCategoryList: FormBuilderCategoryInterface[],
	isConsumerFormComplete: boolean
	formData: ViewStateFormData,
}

class CreateLegalNoticeFreeWriting extends React.Component<CreateLegalNoticeFreeWritingProps, CreateLegalNoticeFreeWritingState>
{
	private timeoutId: NodeJS.Timeout | null = null;

	// Properties
	clientService: ApiClientService;
	collaboratorService: ApiCollaboratorService;
	legalNoticeService: LegalNoticeService;
	apiLegalNoticeService: ApiLegalNoticeService;
	appService: ApiAppService;
	legalNoticePriceService: LegalNoticePriceService;
	apiLegalNoticeFileService: ApiLegalNoticeFileService;
	apiPublicService: ApiPublicService;

	constructor(props: CreateLegalNoticeFreeWritingProps)
	{
		super(props);

		// State
		this.state = this.initState();

		// Services
		this.legalNoticeService = new LegalNoticeService();
		this.clientService = new ApiClientService();
		this.collaboratorService = new ApiCollaboratorService();
		this.appService = new ApiAppService();
		this.legalNoticePriceService = new LegalNoticePriceService(false);
		this.apiLegalNoticeService = new ApiLegalNoticeService();
		this.apiLegalNoticeFileService = new ApiLegalNoticeFileService();
		this.apiPublicService = new ApiPublicService();
	}

	render(): ReactElement
	{
		const {  formData, isDataLoading, isCreateLoading } = this.state;

		if (isDataLoading) {
			return <LoaderFullPage/>;
		}

		if (isCreateLoading) {
			return <LoaderSwitchContext textLoading={ 'Creation de votre annonce légale en cours...' }/>;
		}

		return (
			<>
				<HeroSection
					title={ 'Créer une annonce légale - Rédaction libre' }
					icon={ null }
					button={ {
						label: 'Retour',
						variant: 'secondary',
						iconName: 'LuArrowUpLeft',
						onClick: () => this.props.navigation('/admin/legal-notices')
					} }
				/>

				<div style={ CreateLegalNoticeStyle.sideContainerStyle() }>
					{/* LEFT SIDE */ }
					<div style={ { gridColumn: '1 / 2', paddingBottom: '50px' } }>
						{ this.leftSiteRender(formData) }
					</div>
					{/* RIGHT SIDE */ }
					<div style={ { gridColumn: '2 / 3' } }>
						<div style={ {
							backgroundColor: colors.gray100,
							borderRadius: 8,
							padding: 20,
							display: 'flex',
							flexDirection: 'column',
							gap: 20,
							position: 'sticky',
							top: 50,
						} }>
							{ this.legalNoticeRender() }
						</div>
					</div>
				</div>
			</>
		);
	}

	//<editor-fold desc="State / Components methods" defaultstate="collapsed">

	private initState(): CreateLegalNoticeFreeWritingState
	{
		return {
			errorMessage: null,
			departmentList: [],
			newspaperList: [],
			formBuilderCategoryList: [],
			isConsumerFormComplete: false,
			formData: {
				selectedClient: null,
				selectedCollaborator: null,
				selectedDepartment: null,
				selectedNewspaper: null,
				selectedPrimaryCategory: null,
				selectedSecondaryCategory: null,
				newspapers: [],
				consumer: null,
				billingAddress: null,
				sellsyConsumerAddress: null,
				changeOf: {
					name: null,
					address: null
				},
				headerCharacterCount: 0,
				sendTo: {
					certificate: [],
					credit: [],
					invoice: [],
					receipt: []
				},
				legalNotice: {
					id: null,
					tag: null,
					title: '',
					content: '',
					signature: '',
					logo: '',
					publishDate: null,
					isForcePublishDate: false,
					discount: 0,
					configBillingPreference: {
						billing: null,
						creditNote: null
					},
					numberOfCopies: 0,
					reference: null,
					status: null,
					paymentStatus: null,
					quoteStatus: null,
					option: {
						publishType: "WEB",
						billingType: BilledToTypeEnum.ORDER_GIVER,
						isHeader: false,
						isLogo: false,
						isBodacc: false
					},
					extSupplierUid: null
				}
			},
			isDataLoading: true,
			isCreateLoading: false,
		};
	}

	//</editor-fold>

	private leftSiteRender(formData: ViewStateFormData): ReactElement
	{
		return (
			<>
				<BlockConfigComponent
					isDisplayBlock={ Boolean(formData.selectedClient && formData.selectedCollaborator) }
					authContext={ this.props.authContext! }
					departmentList={ this.state.departmentList }
					newspaperList={ this.state.newspaperList }
					newspaperListRequest={ this.newspaperListRequest.bind(this) }
					selectedNewspaperType={ formData.legalNotice.option.publishType as NewspaperTypeEnum }
					onSelectedNewspaperType={ this.onSelectedNewspaperType.bind(this) }
					selectedDepartment={ formData.selectedDepartment }
					onSelectedDepartment={ this.onSelectedDepartment.bind(this) }
					selectedNewspaper={ formData.selectedNewspaper }
					onSelectedNewspaper={ this.onSelectedNewspaper.bind(this) }
					publishDate={ formData.legalNotice.publishDate }
					isForcePublishDate={ false }
					onChangePublishDate={ this.onChangePublishDate.bind(this) }
					onForcePublishDate={ () => null }
					numberOfCopies={ formData.legalNotice.numberOfCopies }
					onChangeNumberOfCopies={ this.onChangeNumberOfCopies.bind(this) }
					reference={ formData.legalNotice.reference }
					onChangeReference={ this.onChangeReference.bind(this) }
					isInputRefNeeded={ false }
					isAdmin={ false }
				/>

				<BlockCategoryComponent
					isDisplayBlock={ Boolean(formData.selectedCollaborator
						&& formData.selectedNewspaper
						&& formData.legalNotice.publishDate
					) }
					FormBuilderCategoryList={ this.state.formBuilderCategoryList }
					selectedPrimaryCategory={ formData.selectedPrimaryCategory }
					onSelectedPrimaryCategory={ this.onSelectedPrimaryCategory.bind(this) }
					selectedSecondaryCategory={ formData.selectedSecondaryCategory }
					onSelectedSecondaryCategory={ this.onSelectedSecondaryCategory.bind(this) }
					onReset={ this.onResetBlockCategory.bind(this) }
				/>

				<BlockConsumerComponent
					key={ `${ this.state.formData.selectedPrimaryCategory?.id }` }
					consumer={ this.state.formData.consumer }
					isDisplayBlock={ Boolean(formData.selectedNewspaper && formData.selectedPrimaryCategory && formData.selectedSecondaryCategory) }
					category={ this.state.formData.selectedPrimaryCategory }
					onFormsComplete={ this.onConsumerFormComplete.bind(this) }
				/>

				{ (this.state.formData.selectedSecondaryCategory) &&
          <BlockFreeWriting
            getState={ (state: BlockFreeWritingState) => this.setFormDataLegalNotice({
							content: state.content
						}) }
          />
				}

				{
					!LegalNoticeTypeEnum.filterWithoutSiren
						.map(category => category.label)
						.includes(this.state.formData?.selectedPrimaryCategory?.label!)
					&& this.state.formData.selectedSecondaryCategory
					&& this.billingAddressRender()
				}

				{ this.state.formData.selectedSecondaryCategory &&
          <BlockSendToComponent
            isDisplayBlock={ Boolean(formData.selectedNewspaper) }
            selectedClient={ this.state.formData.selectedClient }
            onSelectionMail={ this.onSendToSelected.bind(this) }
            sendTo={ this.state.formData.sendTo }
          />
				}
			</>
		);
	}


	//<editor-fold desc="Block Client methods" defaultstate="collapsed">

	private async onSelectedClient(client: ClientInterface): Promise<void>
	{
		// Check required client options
		if (!client.options?.billedTo || !client.options?.discountPreference) {
			this.setState({
				errorMessage: 'Le client sélectionné ne dispose pas de toutes les options nécessaires pour créer une annonce légale.',
			});
			return;
		}

		// Reset error message if options are valid
		this.setState({
			errorMessage: null
		});

		// Get newspaperByDepartment
		if (client.options && client.options.department) {
			this.newspaperListRequest(
				client.options.department,
				NewspaperTypeEnum.findByValue(client.options.newspaperType)
			);
		}

		this.setFormData({
			selectedClient: client,
			selectedCollaborator: null,
			selectedDepartment: client.options?.department || this.state.formData.selectedDepartment,
			selectedNewspaper: client.options?.newspaper || this.state.formData.selectedNewspaper,
		}, () =>
		{
			this.handleSingleCollaborator();
		});

		// Set publication type if available
		if (client.options?.newspaperType) {
			this.setFormDataLegalNoticeOption({ publishType: client.options?.newspaperType });
		}

		// Merge and set sendTo
		const mergedSendTo = this.mergeSendTo(client.options?.sendTo, this.state.formData.sendTo);
		this.setFormData({ sendTo: mergedSendTo });


		// Set option for legalNotice
		this.setFormDataLegalNoticeOption({
			publishType: client.options?.newspaperType || this.state.formData.legalNotice.option.publishType
		});
	}

	private handleSingleCollaborator(): void
	{
		// Selected Collaborator if list collaborator is length 1
		if (this.state.formData.selectedClient
			&& this.state.formData.selectedClient.collaborators
			&& this.state.formData.selectedClient.collaborators.length === 1) {
			this.setFormData({ selectedCollaborator: this.state.formData.selectedClient?.collaborators[0] });
		}
	}

	private async onSelectedCollaborator(collaborator: CollaboratorInterface): Promise<void>
	{
		this.setFormData({ selectedCollaborator: collaborator });
	}

	private resetFormData(): void
	{
		this.setFormData({
			selectedPrimaryCategory: null,
			selectedSecondaryCategory: null,
			selectedCollaborator: null,
			consumer: null,
			billingAddress: null,
			sendTo: []
		});
		this.setState({ isConsumerFormComplete: false });
		this.onResetBlockCategory();
	}

	//</editor-fold>

	//<editor-fold desc="Block Config methods" defaultstate="collapsed">

	private async onSelectedNewspaperType(newspaperType: NewspaperTypeEnum): Promise<void>
	{
		this.setFormDataLegalNoticeOption({ publishType: newspaperType });
		this.setFormData({ selectedNewspaper: null });
		this.setFormDataLegalNotice({ numberOfCopies: 0 });

		if (this.state.formData.selectedDepartment) {
			this.newspaperListRequest(this.state.formData.selectedDepartment, newspaperType);
		}
	}

	private async onSelectedDepartment(department: DepartmentInterface): Promise<void>
	{
		const newspaperByDepartment: NewspaperInterface[] = await this.legalNoticeService.newspaperList(
			this.props.authContext?.user?.company.id!,
			department,
			this.state.formData.legalNotice.option.publishType
		);

		this.setFormData({ selectedDepartment: department, selectedNewspaper: null });
		this.setStateCustom({ newspaperList: newspaperByDepartment });
	}

	private onSelectedNewspaper(newspaper: NewspaperInterface): void
	{
		this.setFormData({ selectedNewspaper: newspaper });
	}

	private onChangePublishDate(date: Date | null): void
	{
		this.setFormDataLegalNotice({ publishDate: date });
	}

	private onForcePublishDate(date: Date | null, isForcePublishDate: boolean): void
	{
		this.setFormDataLegalNotice({
			publishDate: date,
			isForcePublishDate
		});
	}

	private onChangeNumberOfCopies(numberOfCopies: number): void
	{
		this.setFormDataLegalNotice({ numberOfCopies });
	}

	private onChangeReference(reference: string): void
	{
		this.setFormDataLegalNotice({ reference });
	}

	//</editor-fold>

	//<editor-fold desc="Block Category methods" defaultstate="collapsed">

	private onSelectedPrimaryCategory(category: FormBuilderCategoryInterface): void
	{
		this.setFormData({ selectedPrimaryCategory: category, selectedSecondaryCategory: null });
		this.setFormDataLegalNoticeOption({ isBodacc: false });
	}

	private onSelectedSecondaryCategory(category: FormBuilderCategoryInterface): void
	{
		this.setFormData({ selectedSecondaryCategory: category });
	}

	private onResetBlockCategory(): void
	{
		this.setFormData({
			selectedPrimaryCategory: null,
			selectedSecondaryCategory: null,
			consumer: null,
			billingAddress: null,
		});
		this.setState({ isConsumerFormComplete: false });
	}

	//</editor-fold>

	//<editor-fold desc="Block Siren methods" defaultstate="collapsed">

	private initStateFromSirenResponse(sirenResponse: any): void
	{
		const consumerData = {
			siren: sirenResponse.siren || '',
			name: sirenResponse.name || '',
			capital: sirenResponse.capital || '',
			legalStatus: sirenResponse.legalStatus || '',
			rcs: this.state.formData.consumer?.rcs || '',
			extSellsyId: null,
			address: {
				name: null,
				street: sirenResponse.address.street || '',
				number: sirenResponse.address.number || '',
				phone: null,
				additionalData: sirenResponse.address.additionalData || '',
				zipCode: sirenResponse.address.zipCode || '',
				city: sirenResponse.address.city || '',
				country: sirenResponse.address.country || '',
				extSellsyId: null,
				isBillingAddress: true
			}
		};

		this.setFormData({ consumer: consumerData });
	}

	private resetFormDataModifySiren(): void
	{
		if (this.state.formData.legalNotice.content.length > 0 && this.state.formData.legalNotice.content.length > 0) {
			Swal.fire({
				title: 'Êtes-vous sûr?',
				text: 'Si vous modifiez le SIREN, le formulaire sera réinitialisé',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Modifier',
				cancelButtonText: 'Annuler'
			}).then((result: any) =>
			{
				if (result.isConfirmed) {
					this.setState({
						isDataLoading: true,
					});
					this.setFormData({
						selectedSecondaryCategory: this.state.formData.selectedSecondaryCategory,
						consumer: null,
						billingAddress: null,
						headerCharacterCount: 0,
						sendTo: {},
						legalNotice: {
							...this.state.formData.legalNotice,
							id: null,
							tag: null,
							title: '',
							content: '',
							signature: '',
							logo: '',
							publishDate: null,
							numberOfCopies: 0,
							status: null,
							quoteStatus: null,
							paymentStatus: null,
						},
					});
				}
			});
		}
	}

	//</editor-fold>

	//<editor-fold desc="Block Bodacc methods" defaultstate="collapsed">

	private onSelectedIsBodacc(event: any): void
	{
		this.setFormDataLegalNoticeOption({ isBodacc: event.target.checked });
	}

	//</editor-fold>

	//<editor-fold desc="Block Consumer methods" defaultstate="collapsed">

	/**
	 * Set state when the form is full
	 *
	 * @param isComplete
	 * @param data
	 * @private
	 */
	private onConsumerFormComplete(isComplete: boolean, data: any): void
	{
		this.setState({ isConsumerFormComplete: isComplete });
		this.setFormData({ consumer: data.formData });
	}

	private updateConsumerData(clientSellsyId: number): void
	{
		this.setFormDataConsumer({ extSellsyId: clientSellsyId });
	}

	//</editor-fold>

	//<editor-fold desc="Block Billing Address methods" defaultstate="collapsed">

	private billingAddressRender(): ReactElement
	{
		return (
			<>
				<SectionCollapsed title="Préférence de facturation" isCollapsed={ false } highlightColor={ colors.gray100 }>
					<div style={ FormBuilderStyle.section().sectionViewInputsContainer }>
						<div style={ { display: 'flex', gap: 10 } }>
							<Checkbox
								label={ '' }
								style={ { width: 'auto' } }
								name={ 'invoice-final-client' }
								options={ { rowRightCheckbox: true } }
								onCheckedChange={ (event: any) => this.onSelectedBillingType(event) }
								isChecked={ Boolean(this.state.formData.legalNotice.option.billingType === BilledToTypeEnum.FINAL_CUSTOMER) }
							/>
							<div style={ FontStyles.textHelp }> Je souhaite que la facture soit établie au nom du client</div>
						</div>
					</div>
				</SectionCollapsed>
			</>
		);
	}

	private onSelectedBillingType(event: React.ChangeEvent<HTMLInputElement>): void
	{
		if (event.target.checked) {
			this.setFormData({ billingAddress: this.prepareBillingAddress(BilledToTypeEnum.FINAL_CUSTOMER.value) });
			this.setFormDataLegalNoticeOption({ billingType: BilledToTypeEnum.findByValue(BilledToTypeEnum.FINAL_CUSTOMER.value) });
			this.setFormDataLegalNotice({
				configBillingPreference: {
					creditNote: 'CLIENT',
					billing: 'CONSUMER',
				},
			});
		} else {
			this.setFormDataLegalNotice({
				configBillingPreference: {
					creditNote: 'CLIENT',
					billing: 'CLIENT',
				},
			});
			this.setFormData({ billingAddress: this.prepareBillingAddress(BilledToTypeEnum.ORDER_GIVER.value) });
			this.setFormDataLegalNoticeOption({ billingType: BilledToTypeEnum.findByValue(BilledToTypeEnum.ORDER_GIVER.value) });
		}
	}

	private prepareBillingAddress(billingTypeStr: string): AddressInterface
	{
		let address = {} as AddressInterface;
		if (billingTypeStr === BilledToTypeEnum.ORDER_GIVER.value) {
			address = this.state.formData?.selectedClient?.address!;
			if (address) {
				address.name = this.state.formData.selectedClient?.name || '';
				address.phone = this.state.formData.selectedClient?.phone;
				address.isBillingAddress = true;
			}
		} else {
			address = this.state.formData.consumer?.address!;
			if (address) {
				address.name = this.state.formData.consumer?.name || '';
				address.isBillingAddress = true;
			}
		}

		return address as AddressInterface;
	}

	//</editor-fold>

	//<editor-fold desc="Block Config billing methods" defaultstate="collapsed">

	private onChangeDiscount(event: any): void
	{
		this.setFormDataLegalNotice({ discount: parseInt(event.target.value) });
	}

	private onSelectedAffiliationBilling(configPreference: string): void
	{
		const selectedBilling = configPreference || 'CLIENT';

		this.setFormData({
			billingAddress:
				selectedBilling === 'CLIENT'
					? this.state.formData.selectedClient?.address
					: this.state.formData.consumer?.address
		});

		setTimeout(() =>
		{
			this.setFormDataLegalNotice({
				configBillingPreference: {
					...this.state.formData.legalNotice.configBillingPreference,
					billing: selectedBilling
				},
			});
		}, 0);
	}

	private onSelectedAffiliationCreditNote(configPreference: string): void
	{
		const selectedBilling = configPreference || 'CLIENT';
		this.setFormDataLegalNotice({
			configBillingPreference: {
				...this.state.formData.legalNotice.configBillingPreference,
				creditNote: selectedBilling
			}
		});
	}

	//</editor-fold>

	//<editor-fold desc="Block SendTo methods" defaultstate="collapsed">

	private onSendToSelected(selections: SendToSelections): void
	{
		this.setFormData({ sendTo: selections });
	}

	//</editor-fold>

	//<editor-fold desc="Block Content methods" defaultstate="collapsed">

	private legalNoticeRender(): ReactElement
	{
		return (
			<div style={ {
				backgroundColor: colors.gray100,
				borderRadius: 8,
				padding: 20,
				display: 'flex',
				flexDirection: 'column',
				gap: 20,
				position: 'sticky',
				top: 50,
			} }>

				{/* PRICE */ }
				{ this.state.formData.selectedDepartment
					&& this.state.formData.selectedSecondaryCategory
					&& this.state.formData.selectedNewspaper
					&&
          <LegalNoticePriceFormComponent
            legalNotice={ this.state.formData.legalNotice }
            selectedDepartment={ this.state.formData.selectedDepartment }
            selectedCategory={ this.state.formData.selectedSecondaryCategory }
            selectedNewspaper={ this.state.formData.selectedNewspaper }
            validateLegalNotice={ this.validateLegalNotice.bind(this) }
            isFormComplete={ this.isFormComplete() }
            isHeaderCharacterCount={ this.state.formData.headerCharacterCount }
            isCompactRender={ true }
            isAdmin={ false }
          />
				}

				{ /* OPTIONS */ }
				<HeaderOptionContentComponent
					modalContext={ this.props.modalContext! }
					flashMessageContext={ this.props.flashMessageContext! }
					isConsumerFormFilled={
						!LegalNoticeTypeEnum.filterWithoutSiren
							.map(category => category.label)
							.includes(this.state.formData.selectedPrimaryCategory?.label!)
							? this.state.isConsumerFormComplete
							: true
					}
					isHeaderOptionDisplay={
						!LegalNoticeTypeEnum.filterWithoutSiren
							.map(category => category.label)
							.includes(this.state.formData.selectedPrimaryCategory?.label!)
					}
					isLogoOptionDisplay={ true }
					selectedClient={ this.state.formData.selectedClient }
					isHeader={ this.state.formData.legalNotice.option.isHeader }
					isLogo={ this.state.formData.legalNotice.option.isLogo }
					onSelectedLogo={ this.handleSelectedLogo.bind(this) }
					onCheckIsHeader={ this.onCheckContentHeaderIsHeader.bind(this) }
					onCheckIsLogo={ this.onCheckContentHeaderIsLogo.bind(this) }
				/>

				{/* TITLE */ }
				<Input
					type={ 'text' }
					label="Titre de l'annonce"
					width={ '100%' }
					name="legalNoticeData.title"
					value={ this.state.formData.legalNotice.title || '' }
					onChange={ (event: any) => this.onChangeLegalNoticeTitle(event.target.value) }
				/>

				{/* LEGAL NOTICE */ }
				{ this.legalNoticeContentRender() }

				<div style={ { display: 'flex', justifyContent: 'flex-end', marginRight: 20 } }>
					<Button
						variant={ 'primary' }
						label={ 'Valider l\'annonce' }
						disabled={ Boolean(!this.state.formData.selectedSecondaryCategory) }
						onClick={ (event: any) => this.validateLegalNotice(event) }
					/>
				</div>
			</div>
		);
	}

	private legalNoticeContentRender(): ReactElement
	{
		return (
			<div style={ { ...CreateLegalNoticeStyle.contentContainerStyle(), overflowY: 'scroll' } }>
				<div style={ { ...FontStyle.h4(), textAlign: 'center', marginBottom: 10 } }>Annonce légale</div>
				<LegalNoticeRender
					key={ this.state.formData.legalNotice.content.toString() }
					legalNotice={ this.state.formData.legalNotice }
					consumer={ this.state.formData.consumer }
					isShow={ true }
				/>
			</div>
		);
	}

	private onCheckContentHeaderIsHeader(event: any): void
	{
		// Update the isHeader state
		this.setFormDataLegalNoticeOption({ isHeader: event.target.checked });

		// If the checkbox is checked, remove the headerCharacterCount
		if (!event.target.checked) {
			this.setFormData({ headerCharacterCount: 0 });
		} else {
			// If unchecked, calculate and set the headerCharacterCount
			this.setFormData({ headerCharacterCount: this.legalNoticePriceService.countTempHeaderContent(this.state.formData.consumer) });
		}
	}

	private handleSelectedLogo(selectedLogo: any): void
	{
		this.setFormDataLegalNotice({
			logo: (selectedLogo.logoUrl) ? selectedLogo.logoUrl : ''
		});
	}

	private onCheckContentHeaderIsLogo(isChecked: boolean): void
	{
		if (!isChecked) {
			this.setFormDataLegalNotice({
				logo: ""
			})
		}

		this.setFormDataLegalNoticeOption({ isLogo: isChecked });
	}

	private onChangeLegalNoticeTitle(title: string): void
	{
		this.setFormDataLegalNotice({ title });
	}

	private onChangeLegalNoticeContent(content: string): void
	{
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		// Configure un nouveau timeout
		this.timeoutId = setTimeout(() => {
			try {
				const contentState = convertFromRaw(JSON.parse(content));
				const htmlContent = this.formatHtmlLegalNoticeContent(contentState);

				this.setFormDataLegalNotice({ content: htmlContent });
			} catch (error) {
				console.error('Invalid content format:', error);
			}
		}, 500);
	}

	private formatHtmlLegalNoticeContent(content: ContentState): string
	{
		const blocks = content.getBlocksAsArray();
		let html = '';

		blocks.forEach((block: any) =>
		{
			let blockHTML = '';
			let lastIndex = 0;
			let text = block.getText();

			block.findStyleRanges(
				(character: any) => character.getStyle().has('BOLD'),
				(start: number, end: number) =>
				{
					blockHTML += text.slice(lastIndex, start);
					blockHTML += `<b>${ text.slice(start, end) }</b>`;
					lastIndex = end;
				}
			);

			blockHTML += text.slice(lastIndex);

			switch (block.getType()) {
				case 'header-one':
					html += `<h1>${ blockHTML }</h1>`;
					break;
				case 'header-two':
					html += `<h2>${ blockHTML }</h2>`;
					break;
				case 'unstyled':
				default:
					html += `<p>${ blockHTML }</p>`;
					break;
			}
		});

		return html;
	}

	private onChangeLegalNoticeSignature(signature: string): void
	{
		this.setFormDataLegalNotice({ signature });
	}

	private isHeaderCharacterCount(count: number): void
	{
		this.setFormData({ headerCharacterCount: count });
	}

	//</editor-fold>

	//<editor-fold desc="State / Components methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.setStartingFormDataProcess();
	}

	private async setStartingFormDataProcess(): Promise<void>
	{
		try {
			// Set Department and Category List
			const departments: DepartmentInterface[] = await this.departmentListRequest();
			const formBuilderCategoryList = await this.formBuilderCategoryListRequest();

			this.setState({
				departmentList: departments,
				formBuilderCategoryList: formBuilderCategoryList
			});

			// Set formData with Auth Client
			if (this.props.authClientContext && this.props.authContext) {
				const authData = this.setClientAndCollaborator();

				// Set Client options
				if (authData) {
					const selectedDepartment: DepartmentInterface | null = this.setFormDataWithClientOptions(authData.selectedClient);
					const collaboratorEmail: string = authData.selectedCollaborator.email;

					this.onSendToSelected({
						invoice: [collaboratorEmail],
						credit: [collaboratorEmail],
						receipt: [collaboratorEmail],
						certificate: [collaboratorEmail],
					});

					// Set NewspaperList with Selected department from options
					if (selectedDepartment) {
						this.newspaperListRequest(selectedDepartment, this.state.formData.legalNotice.option.publishType);
					}
				}
			}
		} catch (error) {
			console.error('Error occurre on setStartingFormDataProcess');
		} finally {
			this.setState({ isDataLoading: false }, () =>
			{
				this.setFormDataLegalNotice({
					configBillingPreference: {
						creditNote: 'CLIENT',
						billing: 'CLIENT',
					},
				});
				this.setFormData({ billingAddress: this.prepareBillingAddress(BilledToTypeEnum.ORDER_GIVER.value)! });
				this.setFormDataLegalNoticeOption({ billingType: BilledToTypeEnum.findByValue(BilledToTypeEnum.ORDER_GIVER.value) });
			});
		}
	}

	private async formBuilderCategoryListRequest(): Promise<any>
	{
		return await this.appService.categoryList();
	}

	private async departmentListRequest(): Promise<DepartmentInterface[]>
	{
		return await this.legalNoticeService.departmentList();
	}

	private setClientAndCollaborator(): {
		selectedClient: ClientInterface,
		selectedCollaborator: CollaboratorInterface
	} | null
	{
		const selectedClient: ClientInterface = this.props.authClientContext?.authClient!;
		const selectedCollaborator: CollaboratorInterface | null = UserService.getCollaboratorFromUser(this.props.authContext?.user!, selectedClient.id)!;

		if (selectedClient && selectedCollaborator) {
			this.setState((prevState: CreateLegalNoticeFreeWritingState) => ({
				formData: {
					...prevState.formData,
					selectedClient,
					selectedCollaborator,
				},
			}));

			return { selectedClient, selectedCollaborator };
		} else {
			return null;
		}
	}

	private setFormDataWithClientOptions(selectedClient: ClientInterface | null): DepartmentInterface | null
	{
		if (selectedClient?.options && selectedClient.options.department) {

			this.setFormData({
				selectedDepartment: selectedClient.options.department,
				selectedNewspaper: selectedClient.options.newspaper,
				legalNotice: {
					...this.state.formData.legalNotice,
					discount: selectedClient.options.discountGranted,
				}
			});

			return selectedClient.options.department;
		} else {
			return null;
		}
	}

	private async formBuilderParentCategory(categoryId: number): Promise<FormBuilderCategoryInterface>
	{
		return await this.legalNoticeService.parentCategory(categoryId);
	}

	private async newspaperListRequest(department: DepartmentInterface, newspaperType: NewspaperTypeEnum | string): Promise<void>
	{
		if (this.props.authContext?.user) {
			const companyId: number = this.props.authContext?.user?.company?.id;

			if (!companyId) {
				console.error('Company ID is undefined:', this.props.authContext?.user?.company);
				return;
			}

			const newspaperByDepartment = await this.legalNoticeService.newspaperList(
				companyId,
				department,
				newspaperType
			);

			this.setStateCustom({ newspaperList: newspaperByDepartment });

			if (!this.state.formData.selectedNewspaper) {
				this.setFormData({ selectedNewspaper: null });
			}
		} else {
			console.error('AuthContextUser missing');
		}
	}

	private setFormData(update: Partial<ViewState['formData']>, callback?: () => void): void
	{
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				...update
			}
		}), callback);
	}

	private setStateCustom(update: Partial<ViewState>, callback?: () => void): void
	{
		this.setState((prevState) => ({
			...prevState,
			...update
		}), callback);
	}

	private setFormDataLegalNotice(
		update: Partial<ViewState['formData']['legalNotice']>,
		callback?: () => void
	): void
	{
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				legalNotice: {
					...prevState.formData.legalNotice,
					...update
				}
			}
		}), callback);
	}

	private setFormDataLegalNoticeOption(
		update: Partial<ViewState['formData']['legalNotice']['option']>,
		callback?: () => void
	): void
	{
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				legalNotice: {
					...prevState.formData.legalNotice,
					option: {
						...prevState.formData.legalNotice.option,
						...update
					}
				}
			}
		}), callback);
	}

	private setFormDataConsumer(update: Partial<ViewState['formData']['consumer']>, callback?: () => void): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				consumer: {
					...prevState.formData.consumer,
					...update
				}
			}
		}), callback);
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private validateLegalNotice(event: any): void
	{
		event.stopPropagation();

		const formData = this.state.formData;

		this.props.modalContext?.content(
			`Récapitulatif : ${ formData.selectedPrimaryCategory?.label } - ${ formData.selectedSecondaryCategory?.label }`,
			<LegalNoticeUserModal
				modalContext={ this.props.modalContext }
				legalNotice={ formData.legalNotice }
				selectedClient={ formData.selectedClient as ClientInterface }
				selectedDepartment={ formData.selectedDepartment as DepartmentInterface }
				selectedNewspaper={ formData.selectedNewspaper as NewspaperInterface }
				consumer={ formData.consumer as ConsumerDataInterface }
				billingAddress={ formData.billingAddress as AddressInterface }
				sendTo={ formData.sendTo }
				selectedPrimaryCategory={ formData.selectedPrimaryCategory as FormBuilderCategoryInterface }
				selectedSecondaryCategory={ formData.selectedSecondaryCategory as FormBuilderCategoryInterface }
				onCreate={ this.onCreate.bind(this) }
			/>
		);
	}

	private isFormComplete(): boolean
	{
		const { formData } = this.state;
		const {
			legalNotice,
			selectedClient,
			selectedCollaborator,
			selectedDepartment,
			selectedNewspaper,
			selectedPrimaryCategory,
			selectedSecondaryCategory,
			billingAddress,
			consumer,
			sendTo
		} = formData;

		// Add checks for all required fields
		const isLegalNoticeComplete = legalNotice.content && legalNotice.content !== '<p></p>' && legalNotice.publishDate;
		const isClientComplete = selectedClient !== null;
		const isCollaboratorComplete = selectedCollaborator !== null;
		const isDepartmentComplete = selectedDepartment !== null;
		const isNewspaperComplete = selectedNewspaper !== null;
		const isCategoryComplete = selectedPrimaryCategory !== null && selectedSecondaryCategory !== null;
		const isBillingAddressComplete = billingAddress !== null;
		const isSendToComplete = (sendTo) ? Object.keys(sendTo).length > 0 : [];
		const isConsumerComplete = consumer !== null;

		return Boolean(
			isLegalNoticeComplete
			&& isClientComplete
			&& isCollaboratorComplete
			&& isDepartmentComplete
			&& isNewspaperComplete
			&& isCategoryComplete
			&& isConsumerComplete
			&& isBillingAddressComplete
			&& isSendToComplete
		);
	}

	private async onCreate(type: string): Promise<void>
	{
		// Int vars
		let progressInterval;

		//loading
		this.setState({ isCreateLoading: true });

		try {

			// Call API
			const legalNoticeData = await this.prepareFormData(type);
			const response: any = await this.apiLegalNoticeService.create(legalNoticeData);

			if (response.errorMessage) {
				throw new Error(response.errorMessage);
			}

			// Keep the ProgressBar
			await response;
			const legalNoticeId = response.id || this.state.formData.legalNotice.id;

			clearInterval(progressInterval);
			this.handleFileServiceCalls(type, legalNoticeId, legalNoticeData);


		} catch (error) {
			clearInterval(progressInterval);
			this.setState({ isCreateLoading: false });
			this.props.flashMessageContext?.flashMessage(
				'Erreur',
				`Une erreur est survenue lors de la modification création  de l'annonce légale.`,
				'error'
			);
		} finally {

			// Loading
			this.setState({ isCreateLoading: false });

			// Flash message
			this.props.flashMessageContext?.flashMessage(
				'Mise à jour réussie',
				`L'annonce légale a bien été créée avec succès`,
				'success'
			);

			// Navigate
			this.props.navigation('/legal-notices?reload=true');
		}
	}


	// New method to handle file service calls
	private async handleFileServiceCalls(type: string, legalNoticeId: number, legalNoticeData: any): Promise<void>
	{
		if (type === PublishStateEnum.QUOTE.value) {
			this.apiLegalNoticeFileService.createEstimateFile(legalNoticeId, legalNoticeData).catch(console.error);
		} else if (type === PublishStateEnum.PLANNED.value) {

			this.apiLegalNoticeFileService.createBillingFile(legalNoticeId, legalNoticeData).catch(console.error)
				.then(() =>
				{
					if (legalNoticeData.legalNotice.discount > 0) {
						return this.apiLegalNoticeFileService.createCreditNoteFile(legalNoticeId, legalNoticeData);
					}
				})
				.catch(console.error)
			;
			this.apiLegalNoticeFileService.createCertificateFile(legalNoticeId, legalNoticeData)
				.then(() =>
				{
					this.props.notificationContext?.setLegalNoticeFilePendingResponse({
						legalNoticeId: legalNoticeId,
						legalNoticeFile: LegalNoticeFileTypeEnum.CERTIFICATE.value,
						isLoading: false,
					});

					// Send Notification
					this.props.notificationContext?.notification(
						'Nouveau document',
						`Annonce légale (${ legalNoticeData.legalNotice.tag }) - Votre <span style="font-weight: 500">${ LegalNoticeFileTypeEnum.CERTIFICATE.label }</span> pour <span style="font-weight: 500">${ legalNoticeData.billingAddress.name }</span> a bien été créé`,
						'success',
						`/admin/legal-notices/${ legalNoticeId }`
					);

				})
				.catch(console.error);
		}
	}

	private async prepareFormData(type: string): Promise<any>
	{
		const formData = this.state.formData;

		return {
			departmentId: formData.selectedDepartment?.id,
			newspaperId: formData.selectedNewspaper?.id,
			clientId: formData.selectedClient?.id,
			collaboratorId: formData.selectedCollaborator?.id,
			categoryId: formData.selectedSecondaryCategory?.id,
			legalNotice: {
				...formData.legalNotice,
				price: await this.calculatedPrice(),
				nbCharacters: await this.nbCharacters(),
				status: PublishStateEnum.findByValue(type)?.value,
				paymentStatus: (formData.legalNotice.paymentStatus) ? formData.legalNotice.paymentStatus?.value : PaymentStateEnum.PENDING.value,
				quoteStatus: (type === PublishStateEnum.QUOTE.value) ? QuoteStatusEnum.PENDING.value : null,
				discount: formData.legalNotice.discount,
				configBillingPreference: {
					billing: (formData.legalNotice.configBillingPreference.billing) ? formData.legalNotice.configBillingPreference.billing : 'CLIENT',
					creditNote: (formData.legalNotice.configBillingPreference.creditNote) ? formData.legalNotice.configBillingPreference.creditNote : 'CLIENT'
				},
				option: {
					...formData.legalNotice.option,
					publishType: typeof formData.legalNotice.option.publishType === 'string'
						? formData.legalNotice.option.publishType
						: formData.legalNotice.option.publishType.value,
					billingType: formData.legalNotice.option.billingType.value,
					numberOfCopies: formData.legalNotice.numberOfCopies,
				}
			},
			billingAddress: formData.billingAddress,
			sendTo: formData.sendTo,
			consumer: formData.consumer,
			status: PublishStateEnum.findByValue(type)?.value
		};
	}

	private async calculatedPrice(): Promise<number>
	{
		const VAT_RATE = 0.2;
		const PRINT_VAT_RATE = 0.021;

		const isFixedPrice: boolean = this.legalNoticePriceService.isFixedPrice(
			this.state.formData.selectedDepartment,
			this.state.formData.selectedSecondaryCategory
		);

		// Calculate the annex price
		const annexPrice = parseFloat(
			this.legalNoticePriceService.getAnnexPrice(
				this.state.formData.selectedDepartment as DepartmentInterface,
				this.state.formData.selectedSecondaryCategory as FormBuilderCategoryInterface,
				isFixedPrice
			).toFixed(2)
		);

		const getPrintPrice = await this.handlePrintPrice();
		const getShippingCostPrice = await this.legalNoticePriceService.getShippingCostPrice(
			this.state.formData.legalNotice.numberOfCopies
		);
		const getExternPrice = await this.legalNoticePriceService.getExternPrice();
		const getBodaccPrice = await this.legalNoticePriceService.getBodacPrice();

		// Calculate number of characters
		const nbCharacters = isFixedPrice
			? 1
			: (
				stripHtmlTags(this.state.formData.legalNotice.content).length +
				stripHtmlTags(this.state.formData.legalNotice.title).length +
				stripHtmlTags(this.state.formData.legalNotice.signature).length +
				(this.state.formData.headerCharacterCount || 0)
			);

		const legalNoticePrice = annexPrice * nbCharacters;

		const printPrice = (this.state.formData.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value && this.state.formData.legalNotice.numberOfCopies !== 0)
			? getPrintPrice * this.state.formData.legalNotice.numberOfCopies
			: 0;

		const shippingCostPrice = (this.state.formData.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value && this.state.formData.legalNotice.numberOfCopies !== 0)
			? getShippingCostPrice
			: 0;

		const bodaccPrice = this.state.formData.legalNotice.option.isBodacc
			? getBodaccPrice
			: 0;

		const externPrice = (
			(this.state.formData.selectedNewspaper?.editorial && this.state.formData.selectedNewspaper.editorial !== 'heraultjuridique') &&
			(this.state.formData.selectedNewspaper?.name && this.state.formData.selectedNewspaper.name !== 'L\'Echo du Languedoc')
		) ? getExternPrice
			: 0;

		// Calculate VAT-inclusive prices
		const legalNoticePriceIncVat = parseFloat((legalNoticePrice * (1 + VAT_RATE)).toFixed(2));
		const printPriceIncVat = parseFloat((printPrice * (1 + PRINT_VAT_RATE)).toFixed(2));
		const shippingCostPriceIncVat = parseFloat((shippingCostPrice * (1 + VAT_RATE)).toFixed(2));
		const bodaccPriceIncVat = parseFloat((bodaccPrice * (1 + VAT_RATE)).toFixed(2));
		const externPriceIncVat = parseFloat((externPrice * (1 + VAT_RATE)).toFixed(2));

		// Total calculation
		return parseFloat((legalNoticePriceIncVat + printPriceIncVat + shippingCostPriceIncVat + bodaccPriceIncVat + externPriceIncVat).toFixed(2));
	}

	private async nbCharacters(): Promise<number>
	{
		const isFixedPrice: boolean = this.legalNoticePriceService.isFixedPrice(
			this.state.formData.selectedDepartment,
			this.state.formData.selectedSecondaryCategory
		);

		let totalCharacters =
			stripHtmlTags(this.state.formData.legalNotice.content).length +
			stripHtmlTags(this.state.formData.legalNotice.title).length +
			stripHtmlTags(this.state.formData.legalNotice.signature).length;

		if (this.state.formData.legalNotice.option.isHeader) {
			totalCharacters += this.state.formData.headerCharacterCount || 0;
		} else {
			totalCharacters -= this.state.formData.headerCharacterCount || 0;
		}

		return isFixedPrice ? 1 : totalCharacters;
	}


	private mergeSendTo(clientSendTo: any, currentSendTo: any): any
	{
		const mergeArrays = (arr1: string[], arr2: string[]) => Array.from(new Set([...arr1, ...arr2]));

		return {
			certificate: mergeArrays(clientSendTo?.certificate || [], currentSendTo?.certificate || []),
			credit: mergeArrays(clientSendTo?.credit || [], currentSendTo?.credit || []),
			invoice: mergeArrays(clientSendTo?.invoice || [], currentSendTo?.invoice || []),
			receipt: mergeArrays(clientSendTo?.receipt || [], currentSendTo?.receipt || [])
		};
	}

	private async handlePrintPrice(): Promise<number>
	{
		if (this.state.formData.selectedNewspaper?.editorial && this.state.formData.selectedNewspaper.editorial !== 'heraultjuridique') {
			const getNewspaper = await this.apiPublicService.getNewspaper(
				this.state.formData.selectedNewspaper.id,
			);

			return getNewspaper.receiptPrice;
		} else {
			return await this.legalNoticePriceService.getPrintPrice();
		}
	}

	/**
	 * NOT USE FOR NOW ! KEEP IT PLZ
	 *
	 * @param legalNoticeContent
	 * @private
	 */
	private extractDataFromPasteLegalNotice(
		legalNoticeContent: string
	): ExtractedLegalNoticeDataInterface
	{
		let extractedData: ExtractedLegalNoticeDataInterface = {
			capital: null,
			rcs: null,
			address: {
				street: null,
				number: null,
				city: null,
			},
		};

		// Extract capital
		const capitalRegex: RegExp = /capital[^0-9]*([\d., ]+)/i;
		const capitalMatch: RegExpMatchArray | null = legalNoticeContent.match(capitalRegex);
		if (capitalMatch) {
			extractedData.capital = capitalMatch[1];
		}

		// Extract RCS
		const rcsRegex: RegExp = /RCS\s+([A-Z]+(?:-|\s)?[A-Z]*)/i;
		const rcsMatch: RegExpMatchArray | null = legalNoticeContent.match(rcsRegex);
		if (rcsMatch) {
			extractedData.rcs = rcsMatch[1];
		}

		// Extract address
		const addressRegex: RegExp = /(siège\s+(social\s+)?(?:au\s+)?[:-]?\s*([^,]+?),\s*([\d\s]+)[,]?\s*(\d{5})\s+([A-ZÀ-Ÿ]+(?:\s+[A-ZÀ-Ÿ]+)*))/i;
		const addressMatch: RegExpMatchArray | null = legalNoticeContent.match(addressRegex);
		if (addressMatch) {
			extractedData.address = {
				street: addressMatch[3] ? addressMatch[3].trim() : null,
				number: addressMatch[4] ? addressMatch[4].trim() : null,
				city: addressMatch[6] ? addressMatch[6].trim() : null,
			};
		}

		return extractedData;
	}

	//</editor-fold>
}

export default withGlobalContext(CreateLegalNoticeFreeWriting);