import React, { ReactElement } from 'react';
import Input from '@/Modules/App/Components/Library/Input/Input';
import SelectComponentOld from '@/Modules/App/Components/Atom/Form/Select/SelectComponentOld';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ClientDiscountPreferenceEnum } from '@/Modules/Client/Enum/ClientDiscountPreferenceEnum';
import { Alert } from 'react-bootstrap';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { LuInfo } from 'react-icons/lu';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

interface ComponentProps
{
	isDisplayBlock: boolean,
	discount: number,
	client: ClientInterface,
	onChangeDiscount: (event: React.ChangeEvent<HTMLInputElement>) => void,
	selectedCategory: string | null | undefined,
	selectedAffiliationBilling: string | null,
	selectedAffiliationCreditNote: string | null,
	onSelectedAffiliationBilling: (event: any) => void
	onSelectedAffiliationCreditNote: (event: any) => void
}

export default class BlockConfigBillingComponent extends React.Component<ComponentProps, any>
{
	render(): ReactElement
	{
		const isCategoryFiltered = this.props.selectedCategory
			? LegalNoticeTypeEnum.filterWithoutSiren.some(category => category.value === this.props.selectedCategory)
			: false
		;

		const defaultValue = { label: 'Donneur d\'ordre', value: 'CLIENT' };
		const isDiscountPreferenceIsCreditReduced = this.props.client.options.discountPreference.toString() === ClientDiscountPreferenceEnum.CREDIT_DEDUCTED.value;

		return (
			<>
				{ this.props.isDisplayBlock &&
          <SectionCollapsed isCollapsed={ false } title={ 'Préférence de facturation' }
                            highlightColor={ colors.gray100 }>
            <>
              <div style={ { display: 'flex', gap: '10px' } }>
                <Input
                  label={ 'Pourcentage de remise (%)' }
                  type={ 'number' }
                  name={ 'customer-discount' }
                  value={ this.props.discount }
                  containerDivWidth={ '350px' }
                  onChange={ (event: any) => this.props.onChangeDiscount(event) }
                />

                <SelectComponentOld
                  style={ { position: 'relative', width: '200px' } }
                  label={ 'Facture au : ' }
                  listOptions={ [{ label: 'Donneur d\'ordre', value: 'CLIENT' }, {
										label: 'Client final',
										value: 'CONSUMER'
									}] }
                  onSelectedOption={ (event: any) => this.props.onSelectedAffiliationBilling(event.value) }
                  selectedValue={ (isCategoryFiltered || isDiscountPreferenceIsCreditReduced) ? defaultValue : this.handlePropsValue(this.props.selectedAffiliationBilling) }
                  renderOptionLabel={ (option) => `${ option.label }` }
                  disabled={ isCategoryFiltered || isDiscountPreferenceIsCreditReduced }
                />

                <SelectComponentOld
                  style={ { position: 'relative', width: '200px' } }
                  label={ 'Avoir au : ' }
                  listOptions={ [{ label: 'Donneur d\'ordre', value: 'CLIENT' }, {
										label: 'Client final',
										value: 'CONSUMER'
									}] }
                  onSelectedOption={ (event: any) => this.props.onSelectedAffiliationCreditNote(event.value) }
                  selectedValue={ defaultValue }
                  renderOptionLabel={ (option) => `${ option.label }` }
                  disabled={ isCategoryFiltered || isDiscountPreferenceIsCreditReduced }
                />
              </div>

							{ isDiscountPreferenceIsCreditReduced &&
                <Alert
                  key={ 'info' }
                  variant={ 'warning' }
                  style={ {
										marginTop: 20,
										padding: 10,
										borderRadius: 10,
										backgroundColor: CssVariableEnum['--color-yellow-100'],
									} }
                >
                  <div style={ {
										display: 'grid',
										gridTemplateColumns: '20px auto',
										gap: 5,
										alignItems: 'flex-start'
									} }>
                    <LuInfo style={ {
											fontSize: 18,
											color: CssVariableEnum['--color-yellow-500'],
											textAlign: 'center'
										} }/>
                    <p style={ { fontSize: 12, fontWeight: 500, color: CssVariableEnum['--color-yellow-600'] } }>
                      Note : Le donneur d'ordre a comme option de préférence de remise sur : <b>Avoir déduit</b>
                      . Le sélecteur est bloqué sur Donneur d'ordre
                    </p>
                  </div>
                </Alert>
							}
            </>
          </SectionCollapsed>
				}
			</>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private handlePropsValue(selectedValue: string | null): { label: string, value: string } | null
	{
		if (!selectedValue) {
			return { label: 'Donneur d\'ordre', value: 'CLIENT' };
		}

		return selectedValue === 'CLIENT'
			? { label: 'Donneur d\'ordre', value: 'CLIENT' }
			: { label: 'Client final', value: 'CONSUMER' }
			;
	}

	//</editor-fold>
}