import BaseService from '@/Service/Common/BaseService';
import {
	NewspaperClosureManagementInterface
} from '@/Modules/NewspaperManagement/Common/NewspaperClosureManagement.interface';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';

export class ApiAdminNewspaperClosureManagementService extends BaseService
{

	baseUrl: string = `${ BaseService.API_URL }/v1/admin/newspaper/management`;

	/**
	 * This method lists newspapers management items by fetching real legal notices data.
	 * @param params - Optional parameters for filtering.
	 * @param isInternalNewspaper
	 * @return A Promise resolving to the fake newspaper data.
	 */
	async list(isInternalNewspaper: boolean, params?: string): Promise<PaginateInterface | undefined>
	{
		try {
			const response: Response = await fetch(
				`${this.baseUrl}/${ isInternalNewspaper }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response Data
			const data: PaginateInterface = await response.json();
			if (data && data.items) {
				data.items = data.items.map(item => {
					return {
						...item,
						count: item.legalNotices ? item.legalNotices.length : 0
					};
				});
			}

			return data;

		} catch (error) {
			console.error('Error occurred on fetch NewspaperClosureManagement List');
		}
	}

	async show(newspaperClosureManagementId: number): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${this.baseUrl}/${ newspaperClosureManagementId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response Data
			return await response.json();

		} catch (error) {
			console.error('Error occurred on fetch NewspaperClosureManagement List');
		}
	}

	async edit(newspaperClosureManagementId: number, data: Partial<NewspaperClosureManagementInterface>, isInternalNewspaper: boolean = false): Promise<any>
	{
		try {
			const response: Response = await fetch(
				`${this.baseUrl}/${ newspaperClosureManagementId }/${ isInternalNewspaper }`,
				{
					method: HttpVerbEnum.PATCH,
					headers: this.buildHeaders(),
					body: JSON.stringify(data),
				}
			);

			// Response Data
			return await response.json();

		} catch (error) {
			console.error('Error occurred on fetch NewspaperClosureManagement List');
		}
	}

	async generateRoutingPdf(
		companyId: number,
		newspaperClosureManagementId: number,
	): Promise<any>
	{
		try {
			return await fetch(
				`${this.baseUrl}/${ companyId }/${ newspaperClosureManagementId }/file/create`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildPdfHeaders(),
				}
			);

		} catch (error) {
			console.error('Error occurred on fetch NewspaperClosureManagement List');
		}
	}
}

