import React, { ReactElement } from 'react';
import FormBuilderCategoriesComponent from '@/Modules/FormBuilder/Components/FormBuilderCategoriesComponent';
import FormBuilderConstructorView from '@/Modules/FormBuilder/Admin/View/FormBuilderConstructorView';
import FormBuilderSectionView from '@/Modules/FormBuilder/Admin/View/FormBuilderSectionView';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { ApiAdminFormBuilderCategoryService } from '@/Service/Admin/ApiAdminFormBuilderCategoryService';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { ModalContextType } from '@/Provider/ModalProvider';

interface ViewProps
{
	flashMessageContext: FlashMessageContextType,
	modalContext: ModalContextType,
}

interface ViewState
{
	activeTabKey: string,
	secondaryCategories: FormBuilderCategoryInterface[],
	primaryCategory: FormBuilderCategoryInterface[],
	selectedPrimaryTabId: number,
	selectedSecondaryTabId: number,
}

export default class FormBuilderView extends React.Component
	<ViewProps, ViewState>
{
	formBuilderCategoryService: ApiAdminFormBuilderCategoryService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Service
		this.formBuilderCategoryService = new ApiAdminFormBuilderCategoryService();

		// Bind
		this.saveStateToLocalStorage = this.saveStateToLocalStorage.bind(this);
		this.handleSelectTab = this.handleSelectTab.bind(this);
		this.refreshCategories = this.refreshCategories.bind(this);

		// Document title
		document.title = 'Publisur - ADMIN - FormBuilder';
	}

	render(): ReactElement
	{
		return (
			<>
				<HeroSection
					title="Configuration formulaires annonces légales"
					icon={ null }
				/>
				{ this.tabsMenuHtmlContent() }
				{ (this.state.selectedPrimaryTabId === 1) &&
					<FormBuilderCategoriesComponent
						flashMessageContext={ this.props.flashMessageContext }
					/>
				}
				{ (this.state.selectedPrimaryTabId === 2) &&
					<FormBuilderSectionView
						flashMessageContext={ this.props.flashMessageContext }
						modalContext={ this.props.modalContext }
					/>
				}
				{
					(this.state.selectedPrimaryTabId === 3) &&
					<FormBuilderConstructorView
						key={ Math.floor(Date.now() / 1000).toString() }
						onRefresh={ this.refreshCategories }
					/>
				}
			</>
		);
	}

	//<editor-fold desc="Create (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		window.addEventListener('beforeunload', this.saveStateToLocalStorage);
		await this.refreshCategories();
	}

	componentWillUnmount(): void
	{
		window.removeEventListener('beforeunload', this.saveStateToLocalStorage);
		this.saveStateToLocalStorage();
	}

	private initState(): ViewState
	{
		return {
			activeTabKey: localStorage.getItem('form-builder-tab-active') || 'form-builder-category',
			secondaryCategories: [],
			primaryCategory: [],
			selectedPrimaryTabId: 1,
			selectedSecondaryTabId: 1,
		};
	}

	//</editor-fold>

	//<editor-fold desc="Handle Tabs" defaultstate="collapsed">

	private handlePrimaryTabChange(tabId: number): void
	{
		this.setState({
			selectedPrimaryTabId: tabId,
			selectedSecondaryTabId: tabId === 1 ? 1 : 0
		});
	}

	private handleSecondaryTabChange(tabId: number): void
	{
		if (this.state.selectedPrimaryTabId === 1) {
			this.setState({ selectedSecondaryTabId: tabId });
		}
	}

	private tabsMenuHtmlContent(): ReactElement
	{
		return (
			<div style={ {
				width: '100%',
				position: 'relative'
			} }>
				<div style={ {
					display: 'inline-flex',
					backgroundColor: CssVariableEnum['--color-grey-200'],
					borderRadius: 12,
					padding: '5px',
				} }>
					{ this.buildTabs('PRIMARY', 1, 'Categories') }
					{ this.buildTabs('PRIMARY', 2, 'Section') }
					{ this.buildTabs('PRIMARY', 3, 'Formulaire') }
				</div>
			</div>
		);
	}

	private buildTabs(type: string = 'PRIMARY' || 'SECONDARY', tabId: number, title: string): ReactElement
	{
		return (
			<div
				style={ {
					display: 'flex',
					alignItems: 'center',
					backgroundColor: ((type === 'PRIMARY') ? this.state.selectedPrimaryTabId === tabId : this.state.selectedSecondaryTabId === tabId) ? CssVariableEnum['--color-white'] : 'transparent',
					color: ((type === 'PRIMARY') ? this.state.selectedPrimaryTabId === tabId : this.state.selectedSecondaryTabId === tabId) ? CssVariableEnum['--color-grey-900'] : CssVariableEnum['--color-grey-400'],
					border: ((type === 'PRIMARY') ? this.state.selectedPrimaryTabId === tabId : this.state.selectedSecondaryTabId === tabId) ? `1px solid ${ CssVariableEnum['--color-grey-300'] }` : 'none',
					padding: '3px 6px',
					cursor: 'pointer',
					borderRadius: 8
				} }
				onClick={ () => (type === 'PRIMARY')
					? this.handlePrimaryTabChange(tabId)
					: this.handleSecondaryTabChange(tabId)
				}>
				{ title }
			</div>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Handle Tabs end" defaultstate="collapsed">

	private saveStateToLocalStorage(): void
	{
		localStorage.setItem('form-builder-tab-active', this.state.activeTabKey);
	}

	private async refreshCategories(): Promise<any>
	{
		try {
			return await this.formBuilderCategoryService.list();

		} catch (error) {
			console.error('Error fetching categories:', error);
		}
	}

	private handleSelectTab(key: string | null): void
	{
		this.setState({ activeTabKey: key as string });
	}

	//</editor-fold>

}

