import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ClientDetailsProps, ClientDetailsState } from './ClientDetails.interface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';

const initState: ClientDetailsState = {
	formData: {} as ClientInterface,
	isLoading: true,
};

export class ClientDetailsService extends BaseComponentService<ClientDetailsProps, ClientDetailsState>
{
	private apiAdminClientService = new ApiAdminClientService();

	constructor()
	{
		super({} as ClientDetailsProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.setState({ formData: this.props.client },
			() => this.setState({ isLoading: false })
		);
	}

	async handleEdit(currentState: ClientDetailsState): Promise<void>
	{
		this.setState({ isLoading: true });
		try {

			const updateClient = await this.apiAdminClientService.edit(currentState.formData, currentState.formData.id);

			if (updateClient.errorMessage) {
				console.error('Something wrong with update Client', updateClient.errorMessage);
				return;
			}

			this.setState({
				formData: currentState.formData,
			}, () => this.props.getComponentState!(currentState));

			this.setState({ isLoading: false });

		} catch (error) {
			console.error('Something wrong with update Client', error);
			this.setState({ isLoading: false });
		}
	}

	/**
	 * Handle edit status
	 * @param currentState
	 */
	handleStatus(currentState: ClientDetailsState): void
	{
		const newStatus: ActiveStatusEnum = (String(currentState.formData.status) === ActiveStatusEnum.ACTIVE.value) ? ActiveStatusEnum.INACTIVE : ActiveStatusEnum.ACTIVE;

		this.setState({
			formData: {
				...this.state.formData,
				status: newStatus.value,
			}
		}, () => this.handleEdit(this.state));
	}
}