import { ReactElement } from 'react';

export default class FilterDateEnum
{
	value: string;
	label: string;


	constructor(value: string, label: string )
	{
		this.value = value;
		this.label = label;
	}

	static ON = new FilterDateEnum(
		'ON',
		'En date du...',
	);

	static BETWEEN = new FilterDateEnum(
		'BETWEEN',
		'Entre...',
	);

	static AFTER = new FilterDateEnum(
		'AFTER',
		'Après la date du ...',
	);

	static BEFORE = new FilterDateEnum(
		'BEFORE',
		'Avant la date du ...',
	);

	static options: FilterDateEnum[] = [
		FilterDateEnum.ON,
		FilterDateEnum.BETWEEN,
		FilterDateEnum.AFTER,
		FilterDateEnum.BEFORE
	];

	static findByValue(value: string): FilterDateEnum | undefined
	{
		return this.options.find(option => option.value === value);
	}

}