import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';

export const TagStyles = {
	container: {} as CSSProperties,

	closedTag: {
		padding: '3px 8px',
		backgroundColor: colors.blueRibbon50,
		color: colors.blueRibbon900,
		borderRadius: 3,
		display: 'inline-flex',
		alignItems: 'center',
		gap: 5,
	} as CSSProperties,
} as const;