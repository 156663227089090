import React, { Component, createRef, CSSProperties, ReactElement } from 'react';
import { SelectMultipleProps, SelectMultipleState } from './SelectMultiple.interface';
import { MultiEmailInputService } from './MultiEmailInput.service';
import { borderStyles } from '@/Modules/App/Style/Variables/Variables.styles';
import { SelectMultipleStyles } from '@/Modules/App/Components/Library/SelectMultiple/SelectMultiple.styles';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { LuX } from 'react-icons/lu';
import ToggleOpenClosed from '@/Modules/App/Components/Library/ToggleOpenClosed/ToggleOpenClosed';
import { TagStyles } from '@/Modules/App/Components/Library/Tag/Tag.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';

class MultiEmailInput extends Component<SelectMultipleProps, SelectMultipleState>
{
	private selectMultipleService = new MultiEmailInputService();
	private inputRef = createRef<HTMLInputElement>();

	constructor(props: SelectMultipleProps)
	{
		super(props);

		// Config service
		this.selectMultipleService.setProps(this.props);
		this.selectMultipleService.subscribeState(this);

		// State
		this.state = this.selectMultipleService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.selectMultipleService.init();
	}

	componentWillUnmount(): void
	{
		this.selectMultipleService.unsubscribe();
	}

	//</editor-fold>

	handleContainerClick = (open: () => void): void =>
	{
		open();
		setTimeout(() =>
		{
			if (this.inputRef.current) {
				this.inputRef.current.focus();
			}
		}, 0);
	};

	render(): ReactElement
	{
		return (
			<div style={ { width: '100%' } }>
				<ToggleOpenClosed isClickOutside={ true }>
					{ ({ isOpen, menuRef, menuPosition, setIsOpen, open }) => (
						<div
							style={ { width: '100%' }}
							onClick={ () => this.handleContainerClick(open) }
						>
							<div style={ SelectMultipleStyles.container }>
								<div style={ { display: 'flex', gap: 5, flexWrap: 'wrap' } }>
									{ this.state.tags.map((tag: string, index: number) => (
										<div key={ tag + index } style={ TagStyles.closedTag }>
											{ tag }
											<div style={ { cursor: 'pointer' } } onClick={ (event: any) => this.selectMultipleService.removeTag(tag, event) }>
												<LuX color={ colors.gray400 }/>
											</div>
										</div>
									)) }
								</div>
								{ isOpen && (
									<div ref={ menuRef } style={{ position: 'relative' }}>
										<Input
											inputRef={ this.inputRef }
											type="text"
											css={ { border: 'none' } }
											name="email-multiple"
											onKeyDown={ (event: React.KeyboardEvent<HTMLInputElement>) =>
												this.selectMultipleService.addNewTag(event, this.state.newTag)
											}
											onFocus={ () => null }
											value={ this.state.newTag || '' }
											onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
												this.selectMultipleService.onChangeNewTag(event)
											}
											autocomplete={ 'off' }
										/>

										{ this.suggestionMenu(menuPosition, menuRef) }
									</div>
								) }
							</div>
						</div>
					) }
				</ToggleOpenClosed>
			</div>
		);
	}

	private suggestionMenu(menuPosition: CSSProperties, menuRef: any): ReactElement
	{
		return (
			<div ref={ menuRef }
					 style={ {
						 position: 'absolute',
						 zIndex: 10,
						 backgroundColor: colors.white,
						 width: '100%',
						 ...borderStyles,
						 borderRadius: 6,
						 padding: 10,
						 ...menuPosition
					 } }>
				<div style={ { display: 'flex', flexDirection: 'column', gap: 10 } }>
					{ this.state.errorMessage &&
            <div style={ {
							fontSize: 12,
							fontStyle: 'italic',
							color: colors.error600
						} }>{ this.state.errorMessage }</div>
					}
					{ this.state.suggestions.length > 0 &&
            <div>
              <div style={ { ...FontStyles.textHelp, marginBottom: 4 } }>
                Saisissez une adresse mail pour envoyer les documents
              </div>
              <div>
								{ this.state.suggestions.map((suggestion, index) => (
									<Hovered ref={ suggestion }>
										{ ({ isHovered, onMouseEnter, onMouseLeave, ref }) => (
											<div
												ref={ ref }
												key={ index }
												style={ {
													padding: '3px 6px',
													cursor: 'pointer',
													backgroundColor: (isHovered) ? colors.gray100 : '',
													borderRadius: 4
												} }
												onClick={ (event: any) => this.selectMultipleService.addNewTag(suggestion, event) }
												onMouseEnter={ onMouseEnter }
												onMouseLeave={ onMouseLeave }
											>
												{ suggestion }
											</div>
										) }
									</Hovered>
								)) }
              </div>
            </div>
					}

					<div>
						<div style={ { ...FontStyles.textHelp, marginBottom: 4 } }>
							Emails collaborateurs suggéré
						</div>
						{ (this.state.suggestEmails.length) ? (
							<div>
								{ this.state.suggestEmails.map((email, index) => (
									<Hovered>
										{ ({ isHovered, onMouseEnter, onMouseLeave, ref }) => (
											<div
												ref={ ref }
												key={ index }
												style={ {
													padding: '3px 6px',
													cursor: 'pointer',
													backgroundColor: (isHovered) ? colors.gray100 : '',
													borderRadius: 4
												} }
												onClick={ (event: any) => this.selectMultipleService.addNewTag(email, event) }
												onMouseEnter={ onMouseEnter }
												onMouseLeave={ onMouseLeave }
											>
												{ email }
											</div>
										) }
									</Hovered>
								)) }
							</div>
						) : (
							<div style={ { fontSize: 12, fontWeight: 500, color: colors.gray400 } }>
								Tous les mails ont été ajoutés
							</div>
						)
						}
					</div>
				</div>
			</div>
		);
	}
}

export default MultiEmailInput;