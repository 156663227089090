import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { DataTableManagerProps, DataTableManagerState } from './ListTableManager.interface';
import { OrderByInterface } from '@/Modules/App/Components/Library/Table/Table.interface';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';

const initState: DataTableManagerState = {
	filterParams: {
		page: 1,
		itemsPerPage: 10,
		search: null,
		filters: null,
		orderBy: null,
		sort: null,
	},
	list: {} as PaginateInterface,
	isLoading: false,
	isLoadingFullPage: true,
	refreshKey: 0,
};

export class ListTableManagerService extends BaseComponentService<DataTableManagerProps, DataTableManagerState>
{
	constructor()
	{
		super({} as DataTableManagerProps, initState);

		// Bind methods
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleChangeTerm = this.handleChangeTerm.bind(this);
		this.handleClearFilters = this.handleClearFilters.bind(this);
		this.handleOrderBy = this.handleOrderBy.bind(this);
		this.fetchData = this.fetchData.bind(this);
	}

	/**
	 * Initialize the service by loading filterParams from localStorage.
	 */
	async init(): Promise<void>
	{
		const filterParams = this.loadFromLocalStorage();
		filterParams.search = "";

		this.setState(
			{
				filterParams: filterParams,
				isLoadingFullPage: true,
			},
			async () =>
			{
				if(this.props.serviceList) {
					await this.fetchData();
				} else if ( this.props.refreshList) {
					this.setState({ list: this.props.refreshList });
				}

				this.setState({ isLoadingFullPage: false });
			}
		);
	}

	handlePageChange(newPage: number): void
	{
		this.setState(
			(prevState) =>
			{
				const updatedFilterParams = {
					...prevState.filterParams,
					page: newPage,
				};
				this.saveToLocalStorage(updatedFilterParams);
				return { filterParams: updatedFilterParams };
			},
			this.fetchData
		);
	}

	handleItemsPerPageChange(newItemsPerPage: number): void
	{
		this.setState(
			(prevState) =>
			{
				const updatedFilterParams = {
					...prevState.filterParams,
					itemsPerPage: newItemsPerPage,
					page: 1,
				};
				this.saveToLocalStorage(updatedFilterParams);
				return { filterParams: updatedFilterParams };
			},
			this.fetchData
		);
	}

	handleChangeTerm(term: string): void {
		this.setState(
			(prevState) => ({
				filterParams: {
					...prevState.filterParams,
					search: term,
					page: 1,
				},
			}),
			() => {
				// Sauvegarder uniquement les autres paramètres, sans la recherche
				const { search, ...otherParams } = this.state.filterParams;
				this.saveToLocalStorage(otherParams);
				this.fetchData();
			}
		);
	}

	handleFilterChange(filterKey: string, value: string | null): void
	{
		this.setState(
			(prevState) =>
			{
				const updatedFilterParams = {
					...prevState.filterParams,
					[filterKey]: value,
				};
				this.saveToLocalStorage(updatedFilterParams);
				return { filterParams: updatedFilterParams };
			},
			this.fetchData
		);
	}

	handleApplyFilter(filterKey: string, value: string | null): void
	{
		this.setState(
			(prevState) =>
			{
				const updatedFilters = [
					...(prevState.filterParams.filters || []).filter(
						(filter: any) => !filter.hasOwnProperty(filterKey)
					),
					{ [filterKey]: value },
				];

				const updatedFilterParams = {
					...prevState.filterParams,
					filters: updatedFilters,
					page: 1,
				};
				this.saveToLocalStorage(updatedFilterParams);
				return { filterParams: updatedFilterParams };
			},
			this.fetchData
		);
	}

	handleClearFilters(): void {
		this.setState(
			(prevState) => {
				const clearedFilterParams = {
					...prevState.filterParams,
					filters: [],
					page: 1,
					orderBy: null,
					sort: null,
					search: '',
				};

				this.saveToLocalStorage(clearedFilterParams);

				return { filterParams: clearedFilterParams };
			},
			this.fetchData
		);
	}


	handleOrderBy(orderBy: OrderByInterface): void
	{
		this.setState(
			(prevState) =>
			{
				const isSameColumn = prevState.filterParams.orderBy === orderBy.tag;

				const newDirection: 'asc' | 'desc' =
					isSameColumn && prevState.filterParams.sort === 'asc'
						? 'desc'
						: 'asc';

				const updatedFilterParams = {
					...prevState.filterParams,
					orderBy: orderBy.tag,
					sort: newDirection,
					page: 1,
				};
				this.saveToLocalStorage(updatedFilterParams);
				return { filterParams: updatedFilterParams };
			},
			this.fetchData
		);
	}

	async fetchData(): Promise<void>
	{
		this.setState({ isLoading: true });
		try {
			const params = paramsToString({
				...this.state.filterParams,
				orderBy: this.state.filterParams.orderBy,
				sort: this.state.filterParams.sort,
			});

			const updatedList = await this.props.serviceList!(params);
			console.log(updatedList);
			this.setState(
				{ list: updatedList },
				() =>
					this.setState({
						refreshKey: this.state.refreshKey + 1,
						isLoading: false,
					})
			);
		} catch (error) {
			console.log('Failed to fetch data: ', error);
			this.setState({ isLoading: false });
		}
	}

	/**
	 * Get the key for localStorage based on the tag.
	 */
	private getLocalStorageKey(): string
	{
		return `filters_${ this.props.tag }`;
	}

	/**
	 * Save filterParams to localStorage.
	 * @param filterParams - The filter parameters to save.
	 */
	private saveToLocalStorage(filterParams: DataTableManagerState['filterParams']): void {
		const { search, ...otherParams } = filterParams;
		const paramsToSave = this.state.filterParams.search === null ? otherParams : filterParams;
		localStorage.setItem(this.getLocalStorageKey(), JSON.stringify(paramsToSave));
	}


	/**
	 * Load filterParams from localStorage.
	 * @returns The filter parameters from localStorage or the default state.
	 */
	private loadFromLocalStorage(): DataTableManagerState['filterParams']
	{
		const savedFilterParams = localStorage.getItem(this.getLocalStorageKey());
		return savedFilterParams ? JSON.parse(savedFilterParams) : this.state.filterParams;
	}
}