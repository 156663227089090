import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { SellsyCompanyProps, SellsyCompanyState } from './SellsyCompany.interface';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import { CompanySellsyInterface } from '@/Modules/Client/Interface/CompanySellsyInterface';
import {
	AssociateSellsyCompanyState
} from '@/Modules/App/Components/Sellsy/AssociateSellsyCompany/AssociateSellsyCompany.interface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ModalContextType } from '@/Provider/ModalProvider';

const initState: SellsyCompanyState = {
	isLoading: true,
	sellsyDetails: {} as CompanySellsyInterface,
	client: {} as ClientInterface,
};

export class SellsyCompanyService extends BaseComponentService<SellsyCompanyProps, SellsyCompanyState>
{
	apiAdminSellsyService: ApiAdminSellsyService = new ApiAdminSellsyService();
	apiAdminClientService: ApiAdminClientService = new ApiAdminClientService();

	constructor()
	{
		super({} as SellsyCompanyProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		await this.getSellsyCompany(Number(this.props.client.extSellsyId));
	}

	async handleAssociateSellsy(state: AssociateSellsyCompanyState, modalContext: ModalContextType): Promise<void>
	{
		try {
			modalContext.isLoadingOverlay(true);

			const associateCompany = await this.apiAdminClientService.edit(
				{ extSellsyId: state.selectedCompanySellsy?.object?.id, },
				this.props.client.id
			);

			if (associateCompany.errorMessage) {
				modalContext.errorMessage('Une erreur est survenue lors de l\'association du client, veuillez réessayer');
				modalContext.isLoadingOverlay(false);
				return;
			}

			await this.getSellsyCompany(associateCompany.extSellsyId);

			this.props.flashMessageContext?.flashMessage(
				'Associé avec succès',
				'Ce donneur d’ordre a bien été associé dans Sellsy',
				'success'
			);

			modalContext.isLoadingOverlay(false);
			modalContext.isOpen(false);

		} catch (error) {
			console.error(`Error occurred in ${ this.constructor.name }: AssociateSellsy`, error);
			this.setState({ isLoading: false });
		}
	}

	/**
	 * @param extSellsyId
	 * @private
	 */
	private async getSellsyCompany(extSellsyId: number): Promise<void>
	{
		try {

			const response = await this.apiAdminSellsyService.getCompany(this.props.client.company.id, extSellsyId);

			if (response.errorMessage) {
				this.setState({ isLoading: false });
				return;
			}

			this.setState({
				sellsyDetails: response,
			}, () => this.setState({ isLoading: false }));

		} catch (error) {
			console.error(`Error occurred in ${ this.constructor.name }: getCompanySellsy`, error);
			this.setState({ isLoading: false });
		}
	}

}