import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiAdminAppService extends BaseService {
  adminAppUrl: string = `${ BaseService.API_URL }/v1/admin/app`;

  async counters(): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/counters',
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async chartLegalNoticeExternIntern(): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/legal-notice/chart-extern-intern',
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async exportClosureManagementXml(newspaperClosureManagementId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.adminAppUrl + '/export-xml/' + newspaperClosureManagementId,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildZipHeaders(),
        }
      );

      // Response data
      return await response;
    } catch (error: any) {
      console.error(error.message);
    }
  }
}