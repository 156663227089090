import React, { ReactElement } from 'react';
import {
	CollaboratorShowProps,
	CollaboratorShowState
} from '@/Modules/Collaborator/Admin/Show/CollaboratorShow.interface';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { CollaboratorShowStyles } from '@/Modules/Collaborator/Admin/Show/CollaboratorShow.styles';
import { CollaboratorShowService } from '@/Modules/Collaborator/Admin/Show/CollaboratorShow.service';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { handleChangeInput } from '@/Utils/InputFormUtils';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import Tag from '@/Modules/App/Components/Library/Tag/Tag';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';
import AssociateSellsyContact from '@/Modules/App/Components/Sellsy/AssociateSellsyContact/AssociateSellsyContact';
import { GlobalContextProvider } from '@/Provider/Globals/Global.provider';
import {
	AssociateSellsyContactStyles
} from '@/Modules/App/Components/Sellsy/AssociateSellsyContact/AssociateSellsyContact.styles';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import Select from '@/Modules/App/Components/Library/Select/Select';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import { Tooltip } from 'react-tooltip';

class CollaboratorShow extends React.Component<CollaboratorShowProps, CollaboratorShowState>
{
	private collaboratorShowService: CollaboratorShowService = new CollaboratorShowService();

	constructor(props: CollaboratorShowProps)
	{
		super(props);

		// Config service
		this.collaboratorShowService.setProps(this.props);
		this.collaboratorShowService.subscribeState(this);

		// State
		this.state = this.collaboratorShowService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		this.collaboratorShowService.init();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { collaborator, client } = this.props;
		const { formData, isEditMode } = this.state;

		return (
			<>
				<div style={ CollaboratorShowStyles.modalContainer }>
					<div style={ { display: 'flex', justifyContent: 'space-between' } }>
						<div style={ { display: 'flex', gap: 10, alignItems: 'center' } }>
							<span style={ FontStyles.h2 }> { collaborator.user.lastname } { collaborator.user.firstname } </span>
							<Tag value={ formData.status?.toString() ?? '' } enumName={ 'ActiveStatusEnum' }/>
						</div>

						{ !isEditMode ? (
							<>
								<a id={ 'cant-edit-owner' }>
									<Button
										label={ 'Editer' }
										variant={ 'secondary' }
										disabled={ formData?.role?.toString() === CollaboratorRoleEnum.ROLE_OWNER.value }
										onClick={ (event: React.MouseEvent) =>
										{
											event.stopPropagation();
											event.preventDefault();
											this.setState({ isEditMode: true });
										} }
									/>
									<Tooltip
										anchorSelect="#cant-edit-owner"
										content={ 'Impossible de modifier un collaborateur propriétaire' }
									/>
								</a>
							</>

						) : (
							<div style={ { display: 'flex', gap: 10 } }>
								<div style={ { display: 'flex', gap: 10 } }>
									<Button
										label={ (formData.status?.toString() === ActiveStatusEnum.ACTIVE.value) ? 'Rendre Inactif' : 'Rendre Actif' }
										variant={ 'secondary' }
										onClick={ (event: any) =>
										{
											event.stopPropagation();
											event.preventDefault();

											this.collaboratorShowService.handleStatus(formData.status?.toString() ?? '', this.state);
										} }
									/>
								</div>
							</div>
						) }
					</div>

					<div style={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 5 }}>
						<div style={ FontStyles.textHelp }>Email : <span
							style={ { textDecoration: 'underline' } }>{ formData.email }</span></div>
						<div style={ FontStyles.textHelp }> Téléphone : { collaborator.user.phone } </div>
					</div>
					{ isEditMode && (
						<>
							<div style={ { marginTop: 20, padding: 10, border: `1px solid ${ colors.gray300 }`, borderRadius: 5 } }>
								<div>
									<Input
										type={ 'text' }
										label={ 'Email du collaborateur' }
										textHelp={ 'Veuillez vous assurer de saisir une adresse e-mail valide afin que ce collaborateur puisse recevoir toutes les communications.' }
										name={ 'email' }
										width={ '100%' }
										value={ formData.email || '' }
										onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
									/>
								</div>
							</div>
							<div style={ { marginTop: 20, padding: 10, border: `1px solid ${ colors.gray300 }`, borderRadius: 5 } }>
								<div>
									<Select
										label={ 'Rôle du collaborateur' }
										listOptions={ CollaboratorRoleEnum.roleOptions.filter(option => option.value !== 'ROLE_OWNER') }
										value={ this.props.collaborator.role }
										onSelect={ (role: CollaboratorRoleEnum) => this.collaboratorShowService.handleRole(role) }
										isRequired={ true }
									/>
								</div>
							</div>
						</>
					) }

					<div style={ CollaboratorShowStyles.sellsyContainer }>
						{ collaborator.extSellsyId && !isEditMode
							? (
								<div>
									<div style={ AssociateSellsyContactStyles.container }>
										<span
											style={ { color: colors.purple500, fontWeight: 500 } }> Ce contact est bien lié à Sellsy
										</span>
									</div>
								</div>
							) : (
								<GlobalContextProvider>
									<AssociateSellsyContact
										client={ client! }
										getComponentState={ (state) => this.collaboratorShowService.handleEditSellsyId(state) }
										formData={ {
											user: collaborator.user,
											role: this.state.formData?.role!,
											collaboratorMail: this.state.formData?.email!,
										} }
									/>
								</GlobalContextProvider>
							) }
					</div>
				</div>

				{ isEditMode && (
					<div style={ { ...ModalStyles.bottomActions, gap: 5 } }>
						<Button
							label={ 'Annuler' }
							variant={ 'secondary' }
							onClick={ (event: React.MouseEvent) =>
							{
								event.stopPropagation();
								event.preventDefault();
								this.setState({ isEditMode: false });
							} }
						/>
						<Button
							label={ 'Sauvegarder' }
							variant={ 'primary' }
							onClick={ (event: React.MouseEvent) =>
							{
								event.stopPropagation();
								event.preventDefault();
								this.collaboratorShowService.onUpdate(this.state);
							} }
						/>
					</div>
				) }
			</>
		);
	}
}

export default withGlobalContext(CollaboratorShow);
