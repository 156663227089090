import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
  ClientLegalNoticeStatsFormProps, ClientLegalNoticeStatsFormState
} from '@/Modules/Client/Components/ClientLegalNoticeStats/Form/ClientLegalNoticeStatsForm.interface';
import { ApiClientLegalNoticeStatsService } from '@/Service/Api/ApiClientLegalNoticeStatsService';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import {
  ClientLegalNoticeStatsInterface
} from '@/Modules/Client/Components/ClientLegalNoticeStats/ClientLegalNoticeStats.interface';

const initState: ClientLegalNoticeStatsFormState = {
  formData: {
    id: 0,
    year: new Date().getFullYear(),
    intern: null,
    extern: null,
    client: {} as ClientInterface
  }
}

export class ClientLegalNoticeStatsFormService extends BaseComponentService<ClientLegalNoticeStatsFormProps, ClientLegalNoticeStatsFormState>
{
  private apiAdminClientLegalNoticeStats: ApiClientLegalNoticeStatsService = new ApiClientLegalNoticeStatsService();

  constructor()
  {
    super({} as ClientLegalNoticeStatsFormProps, initState);

    // Bind
    this.onCreate = this.onCreate.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  async init(): Promise<void> {
    const { clientLegalNoticeStat, client } = this.props;

    if (clientLegalNoticeStat) {
      this.setState({
        formData: {
          id: clientLegalNoticeStat.id,
          year: clientLegalNoticeStat.year,
          intern: clientLegalNoticeStat.intern,
          extern: clientLegalNoticeStat.extern,
          client: client
        }
      });
    }
  }

  async onCreate(state: ClientLegalNoticeStatsFormState): Promise<any>
  {
    await this.apiAdminClientLegalNoticeStats.create(this.props.client.id, state.formData);
    this.props.onCreate();
  }

  async onEdit(legalNoticeClientState: ClientLegalNoticeStatsInterface, state: ClientLegalNoticeStatsFormState): Promise<any>
  {
    await this.apiAdminClientLegalNoticeStats.edit(legalNoticeClientState.id as number, state.formData);
    this.props.onEdit();
  }
}