export function formatDateUtils(dateString: Date) {
  const date = new Date(dateString);
  return date.toLocaleDateString('fr-FR',
    { year: 'numeric', month: 'short', day: 'numeric' }
  );
}

export function dateHeader(): string
{
  const date = new Date();

  return date.toLocaleDateString('fr-FR', {
    weekday: 'long',
    day: 'numeric',
    month: 'long'
  });
}

export function dateToStr(date: Date): string
{
  const dateFormat = new Date(date);
  let month = '' + (dateFormat.getMonth() + 1);
  let day = '' + dateFormat.getDate();
  const year = dateFormat.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export function dateFormat(dateParam: string | Date): string
{
  let date;

  if (typeof dateParam === 'string') {
    date = new Date(dateParam);
  } else {
    date = dateParam;
  }

  return date.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
}

export function formatDateToDateTimeString(dateParam: string | Date | null): string {
  if (!dateParam) return '';

  let date;
  if (typeof dateParam === 'string') {
    date = new Date(dateParam);
  } else {
    date = dateParam;
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois commence à 0, donc +1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


export function formatDateToFR(dateParam: string | Date): string {
  let date;

  if (typeof dateParam === 'string') {
    date = new Date(dateParam);
  } else {
    date = dateParam;
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function dateFormatDatePicker(dateParams: string | null): string
{
  const newDate = dateParams ? new Date(dateParams) : new Date();
  const offset = newDate.getTimezoneOffset();

  const offsetHours = Math.abs(offset / 60);
  const offsetMinutes = Math.abs(offset % 60);
  const timezone = (offset > 0 ? '-' : '+') +
    (offsetHours.toString().padStart(2, '0')) +
    (offsetMinutes.toString().padStart(2, '0'));

  return newDate.toDateString() + ' ' +
    newDate.toLocaleTimeString('fr-FR') + ' GMT' +
    timezone;
}

export function dateFormatFull(dateParam: string | Date): string
{
  let date;

  if (typeof dateParam === 'string') {
    date = new Date(dateParam);
  } else {
    date = dateParam;
  }

  return date.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
}

export function formatDateTimeToInputValue(dateParam: string | Date | null): string | null {
  if (!dateParam) return null;

  let date;
  if (typeof dateParam === 'string') {
    date = new Date(dateParam);
  } else {
    date = dateParam;
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois commence à 0, donc +1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function timeSince(date: number): string {
  const seconds = Math.floor((Date.now() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} ans`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} mois`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return `${interval} jours`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `${interval} heures`;
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return `${interval} minutes`;
  }
  return "à l'instant";
}

export function formatDateForInputDate(dateParam: string | Date | null): string {
  if (!dateParam) return '';

  const date = typeof dateParam === 'string' ? new Date(dateParam) : dateParam;

  // Format ISO 8601 sans l'heure (YYYY-MM-DD)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois commence à 0
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}