import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { BlockFreeWritingProps, BlockFreeWritingState } from './BlockFreeWriting.interface';
import { ContentState, convertFromRaw } from 'draft-js';

const initState: BlockFreeWritingState = {
	content: '',
};

export class BlockFreeWritingService extends BaseComponentService<BlockFreeWritingProps, BlockFreeWritingState>
{
	constructor()
	{
		super({} as BlockFreeWritingProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}

	/**
	 * Handle Onchange free writing block
	 * @param content
	 * @param currentState
	 */
	onChangeLegalNoticeContent(content: string, currentState: BlockFreeWritingState): void
	{
		try {
			const contentState = convertFromRaw(JSON.parse(content));
			const htmlContent = this.formatHtmlLegalNoticeContent(contentState);

			this.setState({ content: htmlContent }, () => this.props.getState(currentState));
		} catch (error) {
			console.error('Invalid content format:', error);
		}
	}

	/**
	 *
	 * @param content
	 * @private
	 */
	private formatHtmlLegalNoticeContent(content: ContentState): string
	{
		const blocks = content.getBlocksAsArray();
		let html = '';

		blocks.forEach((block: any) =>
		{
			let blockHTML = '';
			let lastIndex = 0;
			let text = block.getText();

			block.findStyleRanges(
				(character: any) => character.getStyle().has('BOLD'),
				(start: number, end: number) =>
				{
					blockHTML += text.slice(lastIndex, start);
					blockHTML += `<b>${ text.slice(start, end) }</b>`;
					lastIndex = end;
				}
			);

			blockHTML += text.slice(lastIndex);

			switch (block.getType()) {
				case 'header-one':
					html += `<h1>${ blockHTML }</h1>`;
					break;
				case 'header-two':
					html += `<h2>${ blockHTML }</h2>`;
					break;
				case 'unstyled':
				default:
					html += `<p>${ blockHTML }</p>`;
					break;
			}
		});

		return html;
	}
}