import React, { Component, ReactElement } from 'react';
import { LegalNoticeHandleEstimateProps, LegalNoticeHandleEstimateState } from './LegalNoticeHandleEstimate.interface';
import { LegalNoticeHandleEstimateService } from './LegalNoticeHandleEstimate.service';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { ModalStyles } from '@/Provider/Modal/Modal.styles';
import Button from '@/Modules/App/Components/Library/Button/Button.';

class LegalNoticeHandleEstimate extends Component<LegalNoticeHandleEstimateProps, LegalNoticeHandleEstimateState>
{
	private legalNoticeHandleEstimateService = new LegalNoticeHandleEstimateService();

	constructor(props: LegalNoticeHandleEstimateProps)
	{
		super(props);

		// Config service
		this.legalNoticeHandleEstimateService.setProps(this.props);
		this.legalNoticeHandleEstimateService.subscribeState(this);

		// State
		this.state = this.legalNoticeHandleEstimateService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticeHandleEstimateService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticeHandleEstimateService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				<div style={ { padding: 20, width: 500, display: 'flex', flexDirection: 'column', gap: 10, } }>
					<div style={ FontStyles.textHelp }>
						Êtes-vous sûr de vouloir accepter ce devis ? En validant cette action, une facture
						sera automatiquement générée et un email de confirmation sera envoyé au client. Vous pouvez
						également définir la date de publication ci-dessous avant de finaliser l'opération.
					</div>
					<Input
						key={ this.state.publishDate }
						type="date"
						label={ 'Date de publication' }
						style={{ width: 150 }}
						name={ 'publish-date' }
						value={ this.state.publishDate || '' }
						onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
							this.legalNoticeHandleEstimateService.onChangePublishDate(event)
						}
					/>
				</div>
				<div style={ { ...ModalStyles.bottomActions, gap: 5, marginTop: 20 }}>
					<Button
						label={'Refuser et Annuler'}
						variant={ 'dark' }
						onClick={ () => this.legalNoticeHandleEstimateService.onRefusedQuote() }
					/>
					<Button
						label={'Accepter et Planifier'}
						variant={ 'primary' }
						onClick={ () => this.legalNoticeHandleEstimateService.onValidateQuote() }
					/>
				</div>
			</>
		);
	}
}

export default LegalNoticeHandleEstimate;