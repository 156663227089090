import React, { Component, ReactElement } from 'react';
import { SidebarMenuProps } from '@/Modules/App/Components/Menu/SidebarMenu/SidebarMenu.interface';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { SidebarMenuStyles } from './SidebarMenu.styles';
import NavigationMenu from '@/Modules/App/Components/Menu/Navigation/NavigationMenu';
import AppConfigAdmin from '@/Modules/Config/Admin/AppConfigAdmin';
import MenuItem from '@/Modules/App/Components/Menu/MenuItem';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import { UserService } from '@/Modules/App/Services/User/User.service';

class SidebarMenu extends Component<SidebarMenuProps, { isEchoLanguedoc: boolean }>
{
	private userService: UserService = new UserService();

	constructor(props: SidebarMenuProps)
	{
		super(props);

		// State
		this.state = {
			isEchoLanguedoc: false,
		};
	}

	async componentDidMount(): Promise<void>
	{
		await this.isEchoLanguedoc();
	}

	async componentDidUpdate(prevProps: SidebarMenuProps): Promise<void> {
		// Check if companyContexts has changed
		if (prevProps.authCompanyContext?.authCompany?.id !== this.props.authCompanyContext?.authCompany?.id) {
			await this.isEchoLanguedoc();
		}
	}

	render(): ReactElement
	{
		return (
			<div style={ { ...SidebarMenuStyles.container, display: 'flex', flexDirection: 'column', height: '100%' } }>
				{/* Navigation Menu */ }
				<NavigationMenu/>

				{/* Spacer */ }
				<div style={ { flexGrow: 1 } }></div>

				{/* Footer Menu */ }
				<div style={ { marginBottom: 15 } }>
					{/* HJE logo */ }
					<div style={ { display: 'flex', justifyContent: 'center' } }>
						<img
							style={ { width: (this.state.isEchoLanguedoc) ? 170 : 220, marginBottom: 20 } }
							src={ (this.state.isEchoLanguedoc) ? '/img/logo-Echo-du-Languedoc.png' : '/img/hje+ht.jpg' }
							alt="hje-logo"
						/>
					</div>
					{/*	Config menu */ }
					{ UserService.isSuperAdmin() &&
            <>
              <MenuItem
                key={ 0 }
                label={ 'Paramètres' }
                type={ 'menuItem' }
                urlPath={ '' }
                iconName={ 'LuSettings' }
                authorization={ UserRoleEnum.ROLE_SUPER_ADMIN }
                onClick={ (event: any) =>
								{
									event.preventDefault();
									event.stopPropagation();

									this.props.modalContext?.isOpen(true);
									this.props.modalContext?.content(
										'Paramètres',
										<>
											<div style={ { width: '800px' } }>
												{ this.props.authCompanyContext?.authCompany &&
                          <AppConfigAdmin
                            company={ this.props.authCompanyContext?.authCompany }
                            onEdit={ () =>
														{
															this.props.modalContext?.isLoadingFull(true);
															setTimeout(async () =>
															{
																this.props.flashMessageContext?.flashMessage(
																	'Changement de fournisseur',
																	'Le Fournisseur a bien été changé avec succès ! '
																);
																this.props.modalContext?.isLoadingFull(false);
																this.props.modalContext?.isOpen(false);
																this.props.authCompanyContext?.refreshCompany(this.props.authCompanyContext?.authCompany.id, true);
																this.props.navigation('/admin');
															}, 1000);
														} }
                          />
												}
											</div>
										</>
									);
								} }
              />
            </>
					}
				</div>
			</div>
		);
	}

	private async isEchoLanguedoc(): Promise<void>
	{
		const user = await this.userService.getAuthUser();
		const isEchoLanguedoc = user?.company?.id! === 4;

		// Check companyContexts if any "isCurrent: true" has an id other than 1 or 4
		const hasValidCurrentContext = user?.companyContexts?.some(
			(context) => context.isCurrent === true && context.id === 4
		);

		// Combine both conditions: If company is authorized and no invalid current context
		const isAuthorized = (isEchoLanguedoc || hasValidCurrentContext!);

		this.setState({ isEchoLanguedoc: isAuthorized });
	}
}

export default withGlobalContext(SidebarMenu);