import React, { Component, ReactElement } from 'react';
import { ClientLogoProps, ClientLogoState } from './ClientLogo.interface';
import { ClientLogoService } from './ClientLogo.service';
import Button from '@/Modules/App/Components/Library/Button/Button.';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

class ClientLogo extends Component<ClientLogoProps, ClientLogoState>
{
	private clientLogoService = new ClientLogoService();

	constructor(props: ClientLogoProps)
	{
		super(props);

		// Config service
		this.clientLogoService.setProps(this.props);
		this.clientLogoService.subscribeState(this);

		// State
		this.state = this.clientLogoService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.clientLogoService.init();
	}

	componentWillUnmount(): void
	{
		this.clientLogoService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { logo } = this.props;

		return (
			<>
				<div style={ {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					marginBottom: 25
				} }>
					<div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
						<img
							style={ { width: 288, marginTop: '10px', padding: '10px', border: '1px solid black', marginBottom: 20 } }
							src={ logo.logoUrl }
							alt={ 'logo-' + logo.name }
						/>
					</div>
					<div style={{ display: 'flex', gap: 10 }}>
						<Button
							label={ 'Télécharger' }
							variant={ 'primary' }
							iconName={ 'LuFileDown' }
							onClick={ () => this.clientLogoService.downloadImage(logo)
							}/>
						<Button
							label={ 'Supprimer' }
							variant={ 'error' }
							iconName={ 'LuTrash2' }
							onClick={ () => this.clientLogoService.delete()
							}/>
					</div>
				</div>
			</>
		);
	}
}

export default withGlobalContext(ClientLogo);