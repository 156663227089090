import React, { ReactElement } from 'react';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import { NavigateFunction } from 'react-router-dom';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { Alert } from 'react-bootstrap';
import { LuArrowUpRightSquare, LuEye, LuFileOutput } from 'react-icons/lu';
import { PaymentInterface } from '@/Modules/Payment/Interface/PaymentInterface';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import CardStyle from '@/Modules/App/Style/Components/CardStyle';
import { stringToCapitalize } from '@/Utils/StringToCapitalizeUtils';
import ButtonOld from '@/Modules/App/Components/Atom/Button/ButtonOld';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import { CreateLegalNoticeStyle } from '@/Modules/LegalNotice/Style/CreateLegalNoticeStyle';
import { LegalNoticeRenderStyle } from '@/Modules/LegalNotice/Style/LegalNoticeRenderStyle';
import LegalNoticeRender from '@/Modules/LegalNotice/Components/Render/LegalNoticeRender';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { ModalContextType } from '@/Provider/ModalProvider';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';

interface ViewProps
{
	navigate: NavigateFunction,
	location: Location,
	flashMessageContext: FlashMessageContextType,
	modalContext: ModalContextType,
}

interface ViewState
{
	legalNotice: LegalNoticeInterface | null,
	payment: PaymentInterface | null,
	paymentLink: string | null,
	isRenderOpen: boolean,
	errorMessage: string | null,
}

export default class PaymentView extends React.Component<ViewProps, ViewState>
{
	publicService: ApiPublicService;
	navigate: NavigateFunction;

	constructor(props: any)
	{
		super(props);

		// Services
		this.publicService = new ApiPublicService();

		// Bind
		this.handleError = this.handleError.bind(this);
		this.modalLegalNoticeRender = this.modalLegalNoticeRender.bind(this);

		// State
		this.state = this.initState();

		// Navigate
		this.navigate = props.navigation;

		// Document title
		document.title = 'Publisur - Validation de votre paiement';
	}

	render(): ReactElement
	{
		return (
			<>
				{ this.state.errorMessage
					? this.errorMessageRender()
					: <>
						<div style={ {
							height: '100%',
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column'
						} }>
							<div style={ {
								...FontStyle.h1(),
								marginTop: 0,
								height: 70,
								display: 'flex',
								alignItems: 'start',
								justifyContent: 'center'
							} }>
								Récaputilatif de votre commande
							</div>
							<div style={ { width: '100%', display: 'flex', justifyContent: 'center' } }>
								<div style={ CreateLegalNoticeStyle.sideContainerStyle() }>
									{/* LEFT SIDE */ }
									<div style={ { display: 'flex', flexGrow: 1 } }>
										{ this.orderDetailRender() }
									</div>
									{/* RIGHT SIDE */ }
									<div>
										{ this.legalNoticeDetailRender() }
									</div>
								</div>
							</div>
						</div>
					</>
				}
			</>
		);
	}

	//<editor-fold desc="Render method" efaultstate="collapsed">

	private errorMessageRender(): ReactElement
	{
		return (
			<>
				<Alert variant={ 'danger' } style={ { textAlign: 'center' } }>
					{ this.state.errorMessage }
					<br/>
					Vous allez être redirigé vers l'accueil
				</Alert>
			</>
		);
	}

	private orderDetailRender(): ReactElement
	{
		const legalNoticeData: any = this.state.legalNotice;
		if (!legalNoticeData) {
			return <LoaderFullPageComponent/>;
		}

		return (
			<>
				<div style={ { display: 'flex', flexDirection: 'column', gap: 10, width: '100%' } }>
					<div style={ { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 } }>
						<div style={ { ...CardStyle.cardContainer() } }>
							<div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Type de l'annonce</div>
							{ this.lineDetails('Catégorie principale :', stringToCapitalize(legalNoticeData.formBuilderCategory.parent.label)) }
							{ this.lineDetails('Sous-catégorie :', legalNoticeData.formBuilderCategory.label) }
						</div>
						<div style={ { ...CardStyle.cardContainer() } }>
							<div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Configuration de l'annonce</div>
							{ this.lineDetails('Type de journal :', stringToCapitalize(legalNoticeData.option.publishType)) }
							{ this.lineDetails('Département :', legalNoticeData.publishDepartment.name) }
							{ this.lineDetails('Journal :', stringToCapitalize(legalNoticeData.newspaper.name)) }
						</div>
					</div>

					<div style={ { ...CardStyle.cardContainer(), flexGrow: 1 } }>
						<div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Société</div>
						{ this.lineDetails('Dénomination :', stringToCapitalize(legalNoticeData.consumer?.name)) }
						{ this.lineDetails('SIREN :', legalNoticeData.consumer?.siren) }
						{ this.lineDetails('RCS :', legalNoticeData.consumer?.rcs) }
						{ this.lineDetails('Forme juridique :', legalNoticeData.consumer?.legalStatus) }
						{ this.lineDetails('Capital :', `${ legalNoticeData.consumer?.capital } €`) }
						{ this.lineDetails('Numéro :', (legalNoticeData.consumer?.address.number) ? legalNoticeData.consumer?.address.number : '') }
						{ this.lineDetails('Rue :', stringToCapitalize(legalNoticeData.consumer?.address.street)) }
						{ this.lineDetails('Code Postal :', legalNoticeData.consumer?.address.zipCode) }
						{ this.lineDetails('Ville :', stringToCapitalize(legalNoticeData.consumer?.address.city)) }
					</div>

					<div style={ { ...CardStyle.cardContainer() } }>
						<div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Adresse de Facturation</div>

						{ this.lineDetails('Rue', `${ legalNoticeData.billingAddress?.number } ${ legalNoticeData.billingAddress?.street }`) }
						{ this.lineDetails('Code Postal : ', legalNoticeData.billingAddress?.zipCode) }
						{ this.lineDetails('Ville: ', legalNoticeData.billingAddress?.city) }
					</div>
				</div>
			</>
		);
	}

	private legalNoticeDetailRender(): ReactElement
	{
		const legalNoticeData: any = this.state.legalNotice;
		if (!legalNoticeData) {
			return <LoaderFullPageComponent/>;
		}
		return (
			<div style={ { display: 'grid', gridTemplateRows: 'auto 100px', height: '100%', gap: 10 } }>
				<div style={ { ...CardStyle.cardContainer(), display: 'flex', flexDirection: 'column' } }>
					<div>
						<div style={ {
							fontSize: 24,
							fontWeight: 600,
							color: CssVariableEnum['--color-blue-500'],
							marginTop: 0,
							lineHeight: 1,
						} }>
							Prix
						</div>
						<div
							style={ { fontSize: 14, fontWeight: 500, color: CssVariableEnum['--color-grey-600'], marginBottom: 15 } }>
							Paiement: <span
							style={ { textDecoration: 'underline' } }>{ PaymentStateEnum.findByValue(legalNoticeData.paymentStatus)?.label }</span>
						</div>
					</div>
					<div style={ { display: 'flex', flexGrow: 1 } }></div>
					<div>
						<div style={ {
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							paddingTop: 15,
							borderTop: `1px solid ${ CssVariableEnum['--color-grey-200'] }`
						} }>
							<div style={ { fontSize: 22, fontWeight: 500, color: CssVariableEnum['--color-grey-900'] } }>Total</div>
							<div style={ { fontSize: 22, fontWeight: 500, color: CssVariableEnum['--color-grey-900'] } }>
								{ parseFloat(legalNoticeData.price).toFixed(2) } €
							</div>
						</div>
						<div style={ { display: 'flex', justifyContent: 'end', alignItems: 'center' } }>
							<div style={ LegalNoticeRenderStyle.renderStaticMessage() }>
								Vous serez redirigé sur la plateforme sécurisée "Axepta" de la BNP-Paribas.
							</div>
							{ (this.state.legalNotice?.paymentStatus === 'PAID')
								? (
									<div style={ FontStyles.textHelp }> Cette annonce a déjà été réglée </div>
								) : (
								<ButtonOld
									iconLeft={ <LuArrowUpRightSquare/> }
									type={ 'success' } style={ { marginTop: 10 } }
									onClick={ () => this.onPaymentLink() }
									disabled={ Boolean(!this.state.paymentLink) }
								>
									Payer
								</ButtonOld>
								)}

						</div>
					</div>
				</div>
				<div style={ { ...CardStyle.cardContainer() } }>
					{ this.legalNoticeDocumentRender() }
				</div>
			</div>
		);
	}

	private legalNoticeDocumentRender(): ReactElement
	{
		const billings = this.state.legalNotice?.legalNoticeFiles.filter((file: any) => file.type === 'INVOICE');

		return (
			<>
				{ billings && billings.length > 0 &&
          <>
						{ billings.map((file: any, index: number) => (
							<React.Fragment key={ index }>
								<div style={ { marginTop: 20, width: '33%', gap: 10 } } key={ file.id }>
									<div
										style={ { width: '100%', marginBottom: '10px' } }
										key={ index }>
										<div style={ { display: 'flex', justifyContent: 'space-between' } }>
											<ButtonOld type={ 'tag-blue' } onClick={ () =>
											{
												this.getFile(file);
											} }>
												<LuFileOutput/> <span style={ { marginLeft: 10 } }>Voir ma facture</span>
											</ButtonOld>

											<ButtonOld
												type={ 'default-dark' }
												label={ 'Aperçu de l\'annonce' }
												iconLeft={ <LuEye/> }
												onClick={ (event: any) => this.modalLegalNoticeRender(event) }
											/>
										</div>
									</div>
								</div>
							</React.Fragment>
						)) }
          </>
				}
			</>
		);
	}

	private async getFile(file: any): Promise<void>
	{
		if (file.extSellsyId) {
			window.open(file.file, '_blank');
		} else {
			await this.publicService.getFile(this.state.legalNotice?.id as number, file.type)
				.then((blob: any) =>
				{
					const url = window.URL.createObjectURL(blob);
					window.open(url, '_blank');
				})
				.catch((error: string) => console.error('Error downloading file:', error))
			;
		}
	}

	private lineDetails(label: string, data: string | number | null, splitColumn: boolean = true): ReactElement
	{
		return (
			<>
				<div style={ { display: 'flex', gap: 10, width: '100%' } }>
					<div style={ { width: '100%', ...FontStyle.littleGrey() } }>
						{ label }
					</div>
					<div style={ { width: '100%', ...FontStyle.normalMedium() } }>
						{ data }
					</div>
				</div>
			</>
		);
	}

	private modalLegalNoticeRender(event: any): void
	{
		event.stopPropagation();

		this.props.modalContext.content(
			'Annonce légale',
			<div style={{ height: 600, overflow: 'scroll'}}>
				<LegalNoticeRender
					legalNotice={ this.state.legalNotice as any }
					consumer={ this.state.legalNotice?.consumer }
					isShow={ false }
				/>
			</div>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Private method" efaultstate="collapsed">

	async componentDidMount(): Promise<any>
	{
		const queryParams = new URLSearchParams(this.props.location.search);
		const getPaymentCode = queryParams.get('code');

		if (getPaymentCode) {
			await this.handleComponentApiCall(getPaymentCode);
		} else {
			this.handleError('Aucun code de paiement.');
		}
	}

	private async handleComponentApiCall(getPaymentCode: string): Promise<any>
	{
		const getPayment = await this.publicService.paymentDetail(getPaymentCode);

		if (getPayment.errorMessage) {
			this.handleError('Paiement introuvable.');
		} else {
			if (getPayment.processed) {
				this.handleError('Le paiement pour cette annonce a déjà été traité.');
			}

			const getLegalNotice = await this.publicService.getLegalNotice(parseInt(getPayment.transId));

			const paymentLink = await this.publicService.generatePaymentLink(
				parseInt(getPayment.transId),
				getLegalNotice.company.id
			);

			// Set State
			this.setState({
				payment: getPayment,
				paymentLink,
				legalNotice: getLegalNotice
			});
		}
	}

	private handleError(message: string): void
	{
		this.setState({ errorMessage: message });

		setTimeout(() =>
		{

			this.props.flashMessageContext.flashMessage(
				'Erreur sur le paiement',
				message,
				'error'
			);

			this.navigate('/auth');
		}, 3000);
	}

	private onPaymentLink(): void
	{
		if (this.state.paymentLink) {
			window.open(this.state.paymentLink, '_blank');
		}
	}

	private initState(): ViewState
	{
		return {
			legalNotice: null,
			payment: null,
			paymentLink: null,
			isRenderOpen: false,
			errorMessage: null
		};
	}

	//</editor-fold>
}
