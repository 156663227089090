import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { ClientAdminProps, ClientAdminState } from './ClientAdmin.interface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ClientDetailsState } from '@/Modules/Client/Components/Blocks/ClientDetails/ClientDetails.interface';

const initState: ClientAdminState = {
	css: {
		contentWidth: 0,
	},
	isLoading: true,
	client: {} as ClientInterface,
	isEdit: false
};

export class ClientAdminService extends BaseComponentService<ClientAdminProps, ClientAdminState>
{
	private apiAdminClientService: ApiAdminClientService = new ApiAdminClientService();

	constructor()
	{
		super({} as ClientAdminProps, initState);

		// Bind
		this.handleEditClient = this.handleEditClient.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		await this.getClient();
	}

	async handleClientOptionsUpdate(): Promise<void>
	{
		this.setState({ isLoading: true });
		this.props.modalContext?.isLoadingFull(true);

		setTimeout(async () => {
			this.props.modalContext?.isOpen(false);
			await this.init();
			this.setState({ isLoading: false });
			this.props.flashMessageContext?.flashMessage(
				'Mise à jour réussie',
				'Les nouvelles options du client ont été enregistrées',
			);
		}, 1000);
	}

	/**
	 * Handle refresh State on edit Client
	 * @param clientDetailsState
	 */
	handleEditClient(clientDetailsState: ClientDetailsState): void
	{
		this.setState({
			client: clientDetailsState.formData
		});
	}

	private async getClient(): Promise<void>
	{
		try {
			const clientId: number = Number(this.getClientIdFromUrl());
			const client: ClientInterface = await this.apiAdminClientService.show(clientId);

			this.setState({ client }, () => this.setState({ isLoading: false }));

		} catch (error) {
			console.error(`Error occurred in ${ this.constructor.name }: getClient`, error);
			this.setState({ isLoading: false });
		}
	}

	private getClientIdFromUrl(): string
	{
		const url: URL = new URL(window.location.href);
		const pathSegments: string[] = url.pathname.split('/');

		return pathSegments[pathSegments.length - 1];
	}
}
