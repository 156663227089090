import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { SelectMultipleProps, SelectMultipleState } from './SelectMultiple.interface';
import React from 'react';

const initState: SelectMultipleState = {
	tags: [],
	newTag: '',
	suggestions: [],
	suggestEmails: [],
	errorMessage: '',
};

export class MultiEmailInputService extends BaseComponentService<SelectMultipleProps, SelectMultipleState>
{
	constructor()
	{
		super({} as SelectMultipleProps, initState);

		// Bind
		this.onChangeNewTag = this.onChangeNewTag.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		this.initPropsTagsEmail();
		this.initPropsSuggestions();
	}

	/**
	 * On Change new tag input
	 * @param event
	 */
	onChangeNewTag(event: React.ChangeEvent<HTMLInputElement>): void {
		const inputValue: string = event.target.value.trim();

		// Update newTag and uniq suggestion if not empty input
		this.setState({
			newTag: inputValue,
			suggestions: inputValue ? this.generateSuggestions(inputValue) : [],
		});
	}

	/**
	 * Initialize emails Tags from props
	 */
	initPropsTagsEmail(): void
	{
		this.setState({
			tags: this.props.initialTags || [],
		});
	}

	/**
	 * Initialize suggestions from props
	 */
	initPropsSuggestions(): void {
		if (this.props.collaboratorList && Array.isArray(this.props.collaboratorList)) {
			const tagsSet = new Set(this.state.tags);
			const suggestionsFromProps = this.props.collaboratorList
				.map((collaborator) => collaborator.email)
				.filter((email) =>
					typeof email === 'string' &&
					email.trim() !== '' &&
					!tagsSet.has(email)
				);

			this.setState({
				suggestEmails: suggestionsFromProps,
			});
		} else {
			this.setState({
				suggestEmails: [],
			});
		}
	}

	/**
	 * Generate suggestions based on the input value
	 * @param inputValue
	 */
	generateSuggestions(inputValue: string): string[] {

		const defaultDomains: string[] = ['@heraultjuridique.com', '@gmail.com', '@yahoo.com'];

		const collaboratorDomains = this.props.collaboratorList
			.map(collaborator => {
				const domain = collaborator.email.split('@')[1];
				return domain ? `@${domain}` : null;
			})
			.filter((domain, index, self) => domain && self.indexOf(domain) === index);

		const finalDomains = collaboratorDomains.length > 0
			? ['@heraultjuridique.com', ...collaboratorDomains]
			: defaultDomains;

		// Generate Suggestions
		return finalDomains.map(domain => `${inputValue}${domain}`);
	}

	/**
	 * Add new tag
	 * @param valueOrEvent
	 * @param event (optional, captured for onChangeTag)
	 */
	addNewTag(valueOrEvent: string | React.KeyboardEvent<HTMLInputElement>, event?: any): void {
		let newTag: string = '';

		if (typeof valueOrEvent === 'string') {
			newTag = valueOrEvent.trim();
		} else if (valueOrEvent.key === 'Enter') {
			const inputElement = valueOrEvent.target as HTMLInputElement;
			newTag = inputElement.value.trim();
			inputElement.value = '';
			event = valueOrEvent;
		}

		// Validate the email
		if (newTag && this.isValidEmail(newTag)) {
			this.setState((prevState) => {
				const updatedSuggestEmail = prevState.suggestEmails.filter(email => email !== newTag);

				return {
					tags: [...prevState.tags, newTag],
					newTag: '',
					suggestions: [],
					suggestEmails: updatedSuggestEmail,
					errorMessage: '',
				};
			}, () => {
				// Call onChangeTag with the event and updated tags
				if (this.props.onChangeTag) {
					this.props.onChangeTag(event, this.state.tags);
				}
			});
		} else if (newTag) {
			// Show an error if the email is invalid
			this.setState({
				errorMessage: `cet email n'est pas valide: ${newTag}`,
			});
			console.warn(`Invalid email: ${newTag}`);
		}
	}

	/**
	 * Remove Tag from array
	 * @param tag
	 */
	removeTag(tag: string, event?: any): void {
		this.setState((prevState: SelectMultipleState) => {
			const updatedSuggestEmail = prevState.suggestEmails.includes(tag)
				? prevState.suggestEmails
				: [...prevState.suggestEmails, tag];

			return {
				tags: prevState.tags.filter((value: string) => value !== tag),
				suggestEmails: updatedSuggestEmail,
			};
		}, () => {
			// Call onChangeTag with the event and updated tags
			if (this.props.onChangeTag) {
				this.props.onChangeTag(event, this.state.tags);
			}
		});
	}

	/**
	 * Validate if a string is a valid email
	 * @param email
	 * @return boolean
	 */
	private isValidEmail(email: string): boolean {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

}